import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';

// eslint-disable-next-line no-shadow
export enum Requests {
	setFCMToken = 'SET_FCM_TOKEN',
	login = 'LOGIN',
	removeFCMToken = 'REMOVE_FCM_TOKEN',
	signup = 'SIGNUP',
	forgotPassword = 'FORGOT_PASSWORD',
	getUser = 'SHOW_USER',
	updateUser = 'UPDATE_USER',
	expiries = 'EXPIRIES',
	uploadDocuments = 'UPLOAD_DOCUMENTS',
	uploadAvatar = 'UPLOAD_AVATAR',
	togglePublicProfile = 'TOGGLE_PUBLIC_PROFILE',
	// refreshAuthToken = 'REFRESH_AUTH_TOKEN',
	getPaginatedPosts = 'GET_PAGINATED_POSTS',
	getLessons = 'GET_LESSONS',
	getLesson = 'GET_LESSON',
	checkin = 'CHECKIN',
	checkout = 'CHECKOUT',
	getTodayWorkout = 'GET_TODAY_WORKOUT',
	getWorkout = 'GET_WORKOUT',
	saveWorkoutResults = 'SAVE_WORKOUT_RESULTS',
	getUserSubscriptions = 'GET_USER_SUBSCRIPTIONS',
	getPaginatedNotifications = 'GET_PAGINATED_NOTIFICATIONS',
	getNewNotifications = 'GET_NEW_NOTIFICATIONS',
	getPRCategories = 'GET_PR_CATEGORIES',
	getPRExercices = 'GET_PR_EXERCICES',
	getPRExercice = 'GET_PR_EXERCICE',
	getPRRecord = 'GET_PR_RECORD',
	createPRRrecord = 'STORE_PR_RRECORD',
	updatePRRecord = 'UPDATE_PR_RECORD',
	getChekins = 'GET_CHEKINS',
	getSubscriptions = 'GET_SUBSCRIPTIONS',
	getSubscription = 'GET_SUBSCRIPTION',
	payProduct = 'PAY_PRODUCT',
	confirmPaymentIntent = 'CONFIRM_PAYMENT_INTENT',
	getTexts = 'GET_TEXTS',
	getGym = 'GET_GYM',
	getGymPreview = 'GET_GYM_PREVIEW',
	translate = 'TRANSLATE',
	getStripePaymentMethods = 'GET_STRIPE_PAYMENT_METHODS',
	removeStripePaymentMethod = 'REMOVE_STRIPE_PAYMENT_METHOD',
	getUserSubscription = 'GET_USER_SUBSCRIPTION',
	downloadInvoiceFromUserSubscription = 'DOWNLOAD_INVOICE_FROM_USER_SUBSCRIPTION',
	getLessonWithWorkoutResult = 'GET_WORKOUT_RESULT_BY_LESSON',
	createWorkoutResult = 'CREATE_WORKOUT_RESULT',
	updateWorkoutResult = 'UPDATE_WORKOUT_RESULT',
	upcomingCheckins = 'NEXT_CHECKINS',
	upcomingCheckin = 'NEXT_CHECKIN',
	activeSubscription = 'ACTIVE_SUBSCRIPTION',
	getPrivacy = 'GET_PRIVACY',
	acceptPrivacy = 'ACCEPT_PRIVACY',
	createAssociation = "CREATE_ASSOCIATION",
	removeAssociation = "REMOVE_ASSOCIATION",
	getPost = 'GET_POST',
	getComments = 'GET_COMMENTS',
	createComment = 'CREATE_COMMENT',
	getProduct = 'GET_PRODUCT',
	joinGym = 'JOIN_GYM'
};

type Request = {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'; // Carefull with put since laravel can't read it
	responseType?: 'text' | 'json' | 'arraybuffer' | 'blob' | undefined;
	external?: boolean;
	observeHeaders?: boolean;
};

type RequestOptions = {
	body?: { [key: string]: any };
	urlParams?: { [key: string]: string };
	queryParams?: { [key: string]: string };
	headers?: { [key: string]: string };
	pauseRequests?: boolean;
	beforeResumeRequests?: (response: HttpResponse<any> | any) => void;
};

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	/**
	 * Requests are allowed to leave
	 */
	private requestsSemaphor = new BehaviorSubject<boolean>(true);

	/**
	 * Conversion table to pass from Requests enum to Request object
	 */
	private readonly conversionTable: { enum: Requests; object: Request }[] = [
		{
			enum: Requests.setFCMToken,
			object: {
				url: '/set-fcm-token',
				method: 'POST'
			}
		},
		{
			enum: Requests.login,
			object: {
				url: '/login',
				method: 'POST'
			}
		},
		{
			enum: Requests.removeFCMToken,
			object: {
				url: '/remove-fcm-token',
				method: 'POST'
			}
		},
		{
			enum: Requests.signup,
			object: {
				url: '/register',
				method: 'POST'
			}
		},
		{
			enum: Requests.forgotPassword,
			object: {
				url: '/forgot-password',
				method: 'POST'
			}
		},
		{
			enum: Requests.getUser,
			object: {
				url: '/user/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.updateUser,
			object: {
				url: '/user',
				method: 'POST'
			}
		},
		{
			enum: Requests.expiries,
			object: {
				url: '/expiries',
				method: 'GET'
			}
		},
		{
			enum: Requests.uploadDocuments,
			object: {
				url: '/user/upload',
				method: 'POST'
			}
		},
		{
			enum: Requests.uploadAvatar,
			object: {
				url: '/user/upload-avatar',
				method: 'POST'
			}
		},
		{
			enum: Requests.togglePublicProfile,
			object: {
				url: '/user/toggle-public',
				method: 'POST'
			}
		},
		{
			enum: Requests.getPaginatedPosts,
			object: {
				url: '/posts/paginated/{page}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPost,
			object: {
				url: '/posts/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getComments,
			object: {
				url: '/posts/{id}/comments',
				method: 'GET'
			}
		},
		{
			enum: Requests.createComment,
			object: {
				url: '/posts/{id}/comments',
				method: 'POST'
			}
		},
		{
			enum: Requests.getLessons,
			object: {
				url: '/lessons/{date}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getLesson,
			object: {
				url: '/lessons/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.checkin,
			object: {
				url: '/lessons/checkin/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.checkout,
			object: {
				url: '/lessons/checkout/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getTodayWorkout,
			object: {
				url: '/workouts/today',
				method: 'GET'
			}
		},
		{
			enum: Requests.getWorkout,
			object: {
				url: '/workouts/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.saveWorkoutResults,
			object: {
				url: '/workouts/results',
				method: 'POST'
			}
		},
		{
			enum: Requests.getUserSubscriptions,
			object: {
				url: '/user-subscriptions',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPaginatedNotifications,
			object: {
				url: '/notifications/paginated/{page}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getNewNotifications,
			object: {
				url: '/notifications/new',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPRCategories,
			object: {
				url: '/personal-records/categories',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPRExercices,
			object: {
				url: '/personal-records/exercices',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPRExercice,
			object: {
				url: '/personal-records/exercices/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPRRecord,
			object: {
				url: '/personal-records/records/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.createPRRrecord,
			object: {
				url: '/personal-records/records',
				method: 'POST'
			}
		},
		{
			enum: Requests.updatePRRecord,
			object: {
				url: '/personal-records/records/{id}',
				method: 'POST'
			}
		},
		{
			enum: Requests.getChekins,
			object: {
				url: '/checkins',
				method: 'GET'
			}
		},
		{
			enum: Requests.getSubscriptions,
			object: {
				url: '/subscriptions',
				method: 'GET'
			}
		},
		{
			enum: Requests.getSubscription,
			object: {
				url: '/subscriptions/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.confirmPaymentIntent,
			object: {
				url: '/stripe/confirm-payment-intent',
				method: 'POST'
			}
		},
		{
			enum: Requests.getTexts,
			object: {
				url: '/texts/{lang}/{slug}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getGym,
			object: {
				url: '/gym',
				method: 'GET'
			}
		},
		{
			enum: Requests.getGymPreview,
			object: {
				url: '/gym/preview',
				method: 'GET'
			}
		},
		{
			enum: Requests.translate,
			object: {
				url: 'https://weblate.camacrea.it/api/translations/cross-in/app/{lang}/file/',
				method: 'GET',
				external: true
			}
		},
		{
			enum: Requests.getStripePaymentMethods,
			object: {
				url: '/stripe/payment-methods',
				method: 'GET',
			}
		},
		{
			enum: Requests.removeStripePaymentMethod,
			object: {
				url: '/stripe/payment-methods/{paymentMethodId}',
				method: 'DELETE',
			}
		},
		{
			enum: Requests.getUserSubscription,
			object: {
				url: '/user-subscriptions/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.downloadInvoiceFromUserSubscription,
			object: {
				url: '/user-subscriptions/{id}/download-invoice',
				method: 'GET'
			},
		},
		{
			enum: Requests.getLessonWithWorkoutResult,
			object: {
				url: '/lessons/workout-results/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.createWorkoutResult,
			object: {
				url: '/workout-results',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateWorkoutResult,
			object: {
				url: '/workout-results/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.upcomingCheckins,
			object: {
				url: '/checkins/upcoming-checkins',
				method: 'GET'
			}
		},
		{
			enum: Requests.upcomingCheckin,
			object: {
				url: '/checkins/upcoming-checkin',
				method: 'GET'
			}
		},
		{
			enum: Requests.activeSubscription,
			object: {
				url: '/user-subscriptions/active',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPrivacy,
			object: {
				url: '/gym-privacy',
				method: 'GET'
			}
		},
		{
			enum: Requests.acceptPrivacy,
			object: {
				url: '/gym-privacy/accept',
				method: 'POST'
			}
		},
		{
			enum: Requests.createAssociation,
			object: {
				url: '/create-association',
				method: 'POST'
			}
		},
		{
			enum: Requests.removeAssociation,
			object: {
				url: '/remove-association',
				method: 'POST'
			}
		},
		{
			enum: Requests.getProduct,
			object: {
				url: '/products/{type}/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.joinGym,
			object: {
				url: '/join-gym',
				method: 'POST'
			}
		},
		{
			enum: Requests.payProduct,
			object: {
				url: '/products/pay',
				method: 'POST'
			}
		},
	];

	constructor(
		private http: HttpClient,
		private loader: LoaderService
	) { }

	/**
	 * Send http request to server
	 */
	public send(request: Requests, options?: RequestOptions): Promise<any> {
		return new Promise(async (resolve: (response: any) => void, reject: (error?: HttpErrorResponse) => void) => {

			/**
			 * Hold the requests if necessary
			 */
			if (this.requestsSemaphor.getValue() === false) {
				await this.requestsSemaphor.pipe(first((value: boolean) => value)).toPromise();
				console.log('Request freed: ' + request);
			}

			/**
			 * If we must pause all requests
			 */
			if (options?.pauseRequests) {

				/**
				 * Pause all requests
				 */
				this.pauseRequests();
			}

			/**
			 * Convert the request from enum to object
			 */
			const requestObj = this.resolveRequest(request);

			/**
			 * Reject if cast unsuccsessfull
			 */
			if (!requestObj) {
				console.error('This request does not exist!');
				reject();
			} else {

				/**
				 * Replace params in the url
				 */
				for (const key in options?.urlParams) {
					if (Object.prototype.hasOwnProperty.call(options?.urlParams, key)) {
						requestObj.url = requestObj.url.replace(`{${key}}`, options?.urlParams[key] || '');
					}
				}

				/**
				 * Append query params to the url
				 */
				if (options?.queryParams) {

					/**
					 * Start the query params
					 */
					requestObj.url += '?';
					for (const key in options?.queryParams) {
						if (Object.prototype.hasOwnProperty.call(options?.queryParams, key)) {
							requestObj.url += `${key}=${options?.queryParams[key]}&`;
						}
					}

					/**
					 * Remove trailing &
					 */
					requestObj.url = requestObj.url.replace(/\&$/, '');
				}

				/**
				 * If url is not external
				 */
				if (!requestObj.external) {
					requestObj.url = this.getBaseUrl() + requestObj.url;
				}

				/**
				 * Add headers if requested
				 */
				const headers = new HttpHeaders(options?.headers || {});

				/**
				 * If the request is a PUT with a FormData instance
				 */
				if (requestObj.method === 'PUT' && options?.body instanceof FormData) {

					/**
					 * * Set the put method in the form data and fire a post instead
					 * * PHP put method can't process form data so we need to do this work around
					 */
					options?.body.append('_method', 'PUT');
					requestObj.method = 'POST';
				}

				/**
				 * HTTP Request
				 */
				this.http.request(requestObj.method as string, requestObj.url, {
					responseType: requestObj.responseType || 'json',
					body: options?.body,
					observe: requestObj.observeHeaders ? 'response' : 'body',
					headers
				})
					.toPromise()
					.then((response: HttpResponse<any> | any) => {

						/**
						 * If there-s anything we must do before resuming the requests do it now
						 */
						if (options?.beforeResumeRequests && options?.pauseRequests) {
							options.beforeResumeRequests(response);
						}

						/**
						 * Resume requests
						 */
						this.resumeRequests();
						resolve(response);
					})
					.catch((error: HttpErrorResponse) => {

						/**
						 * Resume requests just in case
						 */
						this.resumeRequests();
						reject(error);
					});
			}
		});
	}

	/**
	 * Converts the requests string from the enum to a Request object
	 */
	private resolveRequest(request: Requests): Request | undefined {

		/**
		 * Find it in the conveersion table and return the object
		 */
		return Object.assign({}, this.conversionTable.find(item => item.enum === request)?.object);
	}

	/**
	 * Get base url
	 */
	private getBaseUrl(): string {
		return environment.apiUrl;
	}

	/**
	 * Pause all http requests
	 */
	private pauseRequests(): void {
		if (this.requestsSemaphor.getValue() === true) {
			this.loader.show();
			this.requestsSemaphor.next(false);
			console.log('Requests paused');
		}
	}

	/**
	 * Resume all http requests
	 */
	private resumeRequests(): void {
		if (this.requestsSemaphor.getValue() === false) {
			this.loader.hide();
			this.requestsSemaphor.next(true);
			console.log('Requests resumed');
		}
	}
}
