import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnDestroy {

	/**
	 * Id for the select [required]
	 */
	 @Input() id?: string;

	 /**
	  * Generic attributes, normal for a select
	  */
	 @Input() attributes?: {
		 [key: string]: any;
	 };

	 /**
	  * Options for the select
	  */
	 @Input() options?: {
		 [key: string]: string;
	 };

	 /**
	  * Value change emitter
	  */
	 @Output() valueChange = new EventEmitter<string>();

	 /**
	  * Input Reference
	  */
	 @ViewChild('inputRef') inputRef?: ElementRef;

	/**
	 * Control for the select
	 */
	formControl: FormControl = new FormControl();

	/**
	 * Subscription to changes
	 */
	private subscription?: Subscription;

	ngOnInit(): void {

		/**
		 * On change value, inform parent
		 */
		this.subscription = this.formControl.valueChanges.subscribe(
			(next: string) => {
				if (this.inputRef && this.options) {
					this.inputRef.nativeElement.value = this.options[next];
					this.valueChange.emit(next);
				}
			}
		);

		/**
		 * Set default value
		 */
		if (this.attributes?.value) {
			setTimeout(() => {
				this.formControl.setValue(this.attributes?.value);
			}, 10);
		}

	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
