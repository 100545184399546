import {
	HttpErrorResponse, HttpHandler,
	HttpHeaders, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import {
	Injectable, Injector
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	Observable, throwError
} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Errors } from 'src/app/enums/errors.enum';
import { LocalStorageTypes } from '../enums/local-storage-types.enum';
import { Interceptors, InterceptorsService } from '../services/interceptors/interceptors.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { ToastService } from '../services/toast/toast.service';
import { UserService } from '../services/user/user.service';

@Injectable()
export class GymInterceptor implements HttpInterceptor {

	constructor(
		private localStorage: LocalStorageService,
		private toast: ToastService,
		private interceptorsService: InterceptorsService,
		private injector: Injector
	) { }

	/**
	 * Update header HTTP call
	 */
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<any> {

		/**
		 * If interceptor is not enabled, skip it
		 */
		if (!this.interceptorsService.isEnabled(Interceptors.Gym)) {
			return next.handle(request);
		}

		/**
		 * HttpHeader object immutable - copy values
		 */
		const headerSettings: {
			[name: string]: string | string[];
		} = {};

		/**
		 * Set keys
		 */
		for (const key of request.headers.keys()) {
			headerSettings[key] = request.headers.getAll(key) || [];
		}

		/**
		 * Set GymToken if exist
		 */
		const gymCode = this.localStorage.getItem(LocalStorageTypes.GymCode);
		if (!request.url.includes('https://weblate.camacrea.it')) {
			if (gymCode) {
				headerSettings['Gym-Code'] = gymCode;
			}
		}

		/**
		 * Add token code to header
		 */
		const newHeader = new HttpHeaders(headerSettings);
		const req = request.clone({
			headers: newHeader
		});

		/**
		 * Next request
		 */
		return next.handle(req)

			/**
			 * Check if the request has no gym related errors
			 */
			.pipe(
				// No retry
				retry(0),
				catchError((error: HttpErrorResponse) => {
					const user = this.injector.get(UserService);
					const translate = this.injector.get(TranslateService);
					switch (error?.error?.code) {

						/**
						 * Gym code not provided
						 */
						case Errors.LackGymCode:
							this.toast.error(translate.instant('ERRORS.GYM_CODE_UNKNOWN'));

							/**
							 * Logout
							 */
							user.logout();
							break;

						/**
						 * Requested gym not found
						 */
						case Errors.GymNotFound:
							if (gymCode) {
								this.toast.error(translate.instant('ERRORS.GYM_NOT_FOUND'));

								/**
								 * Logout
								 */
								user.logout();
							}
							break;

						/**
						 * Requested gym has not the user in it
						 */
						case Errors.UserNotInGym:
							this.toast.error(translate.instant('ERRORS.UNKNOWN_ERROR'));

							/**
							 * Logout
							 */
							user.logout();
							break;

						/**
						 * If the user has not the right permissions
						 */
						case Errors.LackPermission:
							this.toast.error(translate.instant('ERRORS.USER_WRONG_PERMISSIONS'), 3000, true);

							/**
							 * Logout
							 */
							user.logout();
							break;
					}

					return throwError(error);
				})
			);
	}
}
