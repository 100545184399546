import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-floating-button',
	templateUrl: './floating-button.component.html',
	styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent implements OnInit {

	/**
	 * Type
	 */
	@Input('type') type = 'button';

	/**
	 * Disabled
	 */
	@Input('disabled') disabled = false;

	constructor() { }

	ngOnInit(): void { }

	/**
	 * Prevent spacer click
	 */
	preventClick(event: Event): void {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
	}
}
