<main class="rinnovo">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "GENERAL.NEW"|translate }} {{ "GENERAL.SUBSCRIPTION"|translate }}
	</div>

	<ul class="subscriptions" *ngIf="(subscriptions?.length || 0) > 0">
		<li class="subscription" *ngFor="let subscription of subscriptions" [routerLink]="['/', 'rinnovo', subscription.id]">
			<figure class="background">
				<img [attr.src]="subscription.image_url || 'assets/img/png/abbonamento.png'" alt="" />
			</figure>
			<div class="text-container">
				<h3>
					{{ subscription.name }}
				</h3>
				<h2>
					{{ (subscription.total || 0) | decimal }} {{ gym?.currency?.symbol }}
				</h2>
			</div>
		</li>
	</ul>
</main>
