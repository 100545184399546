import { Injectable } from '@angular/core';
import { AES, enc, lib, mode, pad, SHA256 } from 'crypto-js';
import { HelperService } from '../helper/helper.service';

@Injectable({
	providedIn: 'root'
})
export class CryptingService {

	/**
	 * Inizialization vector for decrypting
	 */
	static readonly iv = enc.Hex.parse('86ea0c62487e021d86ea0c62487e021d');

	constructor(
		private helper: HelperService,
	) {}

	/**
	 * Crypt a string using a key
	 */
	crypt(toCrypt: string, key: string ): string {
		return AES.encrypt(toCrypt, key).toString();
	}

	/**
	 * Decrypt a string using a key
	 */
	decrypt(toDecrypt: string, key: string ): string {
		return AES.decrypt(toDecrypt, key).toString(enc.Utf8);
	}

	/**
	 * New Decrypting
	 */
	newDecrypt(toDecrypt: ArrayBuffer, key: string): ArrayBuffer{

		/**
		 * Create Byte Array [CryptoJs Format]
		 */
		const cryptoByteArray = lib.WordArray.create(toDecrypt as any);

		/**
		 * Make Cipherparams
		 * lib['CipherParams'] Workaraound, cause 'lib.CipherParams' is not declared
		 */
		const cipherParams = lib.CipherParams.create({
			ciphertext: cryptoByteArray
		});

		/**
		 * Convert key to to WordArray
		 */
		const wordArrayKey = SHA256(key);

		/**
		 * Decrypt File
		 */
		const decrypted = AES.decrypt(cipherParams, wordArrayKey, {
			iv: CryptingService.iv,
			mode: mode.CBC,
			padding: pad.Pkcs7
		});

		return this.helper.wordArrayToUint8Array(decrypted);

	}
}
