<section class="multimedia-dialog" [ngClass]="{show: dom.show, zindex: dom.zindex}">
	<div class="content">
		<div *ngIf="types.includes(MultimediaTypes.Gallery)" class="option" (click)="click(MultimediaTypes.Gallery)">
			<figure class="icon">
				<i class="bi bi-card-image"></i>
			</figure>
			<p class="name">
				{{ "GENERAL.GALLERY"|translate }}
			</p>
		</div>
		<div *ngIf="types.includes(MultimediaTypes.Camera)" class="option" (click)="click(MultimediaTypes.Camera)">
			<figure class="icon">
				<i class="bi bi-camera"></i>
			</figure>
			<p class="name">
				{{ 'CAMERA'|translate }}
			</p>
		</div>
		<div *ngIf="types.includes(MultimediaTypes.File)" class="option" (click)="click(MultimediaTypes.File)">
			<figure class="icon">
				<i class="bi bi-folder"></i>
			</figure>
			<p class="name">
				{{ 'FILE'|translate }}
			</p>
		</div>
		<div *ngIf="types.includes(MultimediaTypes.Download)" class="option download" (click)="click(MultimediaTypes.Download)">
			<figure class="icon">
				<i class="bi bi-cloud-arrow-down"></i>
			</figure>
			<p class="name">
				{{ "GENERAL.DOWNLOAD"|translate }}
			</p>
		</div>
	</div>
</section>
