import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ToastService {

	/**
	 * Timeout to clear the toast
	 */
	private timeoutId?: number | null;

	/**
	 * Toast status
	 */
	private status = new BehaviorSubject<{ message: string; type: string; important: boolean } | null>(null);

	constructor() { }

	/**
	 * Show success toast
	 */
	show(message: string, timer = 6000, important = false): void {

		/**
		 * Clear timeout
		 */
		if (this.timeoutId !== null && this.status.value?.important === false) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		/**
		 * Show toast
		 */
		this.status.next({
			message,
			type: 'success',
			important
		});

		/**
		 * Hide on timeout
		 */
		this.timeoutId = window.setTimeout(() => {
			this.timeoutId = null;
			this.status.next(null);
		}, timer ? timer : 6000);
	}

	/**
	 *	SHow error toast
	 */
	error(message: string, timer = 6000, important = false): void {

		/**
		 * Clear timeout
		 */
		if (this.timeoutId !== null && this.status.value?.important === false) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		/**
		 * Show toast
		 */
		this.status.next({
			message,
			type: 'danger',
			important
		});

		/**
		 * Hide on timeout
		 */
		this.timeoutId = window.setTimeout(() => {
			this.timeoutId = null;
			this.status.next(null);
		}, timer ? timer : 6000);
	}

	/**
	 *	Hide toast
	 */
	hide(): void {

		/**
		 * Clear timeout
		 */
		if (this.timeoutId !== null) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		/**
		 * Hide on timeout
		 */
		this.status.next(null);
	}

	/**
	 * Subscribe to new toasts requests
	 */
	subscribe(callback: (result: { message: string; type: string; important: boolean } | null) => void): void {
		this.status.subscribe(
			(next: { message: string; type: string; important: boolean } | null) => {
				callback(next);
			}
		);
	}
}
