import { Injectable } from '@angular/core';
/* eslint-disable no-bitwise */

@Injectable({
	providedIn: 'root'
})
export class HelperService {

	constructor() {}

	/**
	 * Covert wordArray to ArrayBuffer
	 */
	wordArrayToUint8Array(wordArray: any): ArrayBuffer {
		const len = wordArray.words.length;
		const u8Array = new Uint8Array(len << 2);
		let offset = 0;
		let word;
		let i;

		for (i = 0; i < len; i++) {
			word = wordArray.words[i];
			u8Array[offset++] = word >> 24;
			u8Array[offset++] = (word >> 16) & 0xff;
			u8Array[offset++] = (word >> 8) & 0xff;
			u8Array[offset++] = word & 0xff;
		}
		return u8Array;
	}

	/**
	 * Get URL from An ArrayBuffer
	 */
	getURLFromArrayBuffer(arrayBufferData: ArrayBuffer): string {
		const blob = new Blob([arrayBufferData], {type: 'octet/stream'});
		return window.URL.createObjectURL(blob);
	}
}
