import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category, Exercice, Record } from 'src/app/interfaces/record';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class RecordsService {
	constructor(
		private http: HttpService
	) { }

	/**
	 * List of the categories
	 */
	categories(): Promise<Category[]> {
		return new Promise((resolve: (categories: Category[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getPRCategories)
				.then((categories: Category[]) => resolve(categories))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * List of the exercices
	 */
	exercices(category_id: number): Promise<Exercice[]> {
		return new Promise((resolve: (exercices: Exercice[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getPRExercices, {
				queryParams: {
					category_id: category_id.toString()
				}
			})
				.then((exercices: Exercice[]) => resolve(exercices))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get the exercice
	 */
	exercice(id: number): Promise<Exercice> {
		return new Promise((resolve: (exercice: Exercice) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getPRExercice, {
				urlParams: {
					id: id.toString()
				}
			})
				.then((exercice: Exercice) => resolve(exercice))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get the record
	 */
	get(id: number): Promise<Record> {
		return new Promise((resolve: (record: Record) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getPRRecord, {
				urlParams: {
					id: id.toString()
				}
			})
				.then((record: Record) => resolve(record))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Update record
	 */
	update(formData: FormData, id: number): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse | null) => void) => {

			/**
			 * * Set the put method in the form data and fire a post instead
			 * * PHP put method can't process form data so we need to do this work around
			 */
			formData.append('_method', 'PUT');

			this.http.send(Requests.updatePRRecord, {
				urlParams: {
					id: id?.toString()
				},
				body: formData
			})
				.then((success: boolean) => {
					if (success) {
						resolve();
					} else {
						reject(null);
					}
				})
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Store record
	 */
	create(formData: FormData): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse | null) => void) => {
			this.http.send(Requests.createPRRrecord, {
				body: formData
			})
				.then((success: boolean) => {
					if (success) {
						resolve();
					} else {
						reject(null);
					}
				})
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
