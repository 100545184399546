import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Gym } from 'src/app/interfaces/gym';
import { HttpService, Requests } from '../http/http.service';

export enum StoreKeys {
	Gym = 'gym'
};

@Injectable({
	providedIn: 'root'
})
export class StoreService implements OnDestroy {

	private store: { [key: string]: BehaviorSubject<any | null> } = {};

	/**
	 * On service init
	 */
	constructor(
		private http: HttpService
	) {

		/**
		 * Bind the ng destroy to the page unload
		 */
		window.onbeforeunload = () => this.ngOnDestroy();

		/**
		 * Get store snapshot from the last session
		 */
		const storeSnapshot: { [key: string]: any } = JSON.parse(localStorage.getItem('Store') || '{}');

		/**
		 * Set last session vars in the store
		 */
		for (const key in storeSnapshot) {
			if (Object.prototype.hasOwnProperty.call(storeSnapshot, key)) {
				this.set(key as StoreKeys, storeSnapshot[key]);
			}
		}

		console.info('🔥 Recovering the store state from localStorage: ', storeSnapshot);
	}

	/**
	 * On service destroy
	 */
	ngOnDestroy(): void {
		console.info('🔥 Saving the store state into localStorage');

		/**
		 * Create a snapshot of the store
		 */
		const storeSnapshot: { [key: string]: any } = {};
		for (const key in this.store) {
			if (Object.prototype.hasOwnProperty.call(this.store, key)) {
				const value = this.store[key].getValue();
				if (value) {
					storeSnapshot[key] = value;
				}
			}
		}

		/**
		 * Persist in local storage
		 */
		localStorage.setItem('Store', JSON.stringify(storeSnapshot));
	}

	/**
	 * Watch value changes for the store items
	 * @param key
	 * @returns
	 */
	onChanges<T>(key: StoreKeys): Observable<T | null> {

		/**
		 * If we don't have it yet
		 */
		if (!this.store[key]) {

			/**
			 * Init
			 */
			this.store[key] = new BehaviorSubject<T | null>(null);
		}

		/**
		 * Return observable
		 */
		return this.store[key].asObservable();
	}

	/**
	 * Set next value for the store item
	 * @param key
	 * @param value
	 */
	set<T>(key: StoreKeys, value: T): void {

		/**
		 * If we don't have it yet
		 */
		if (!this.store[key]) {

			/**
			 * Init with value
			 */
			this.store[key] = new BehaviorSubject<T | null>(value);
		} else {

			/**
			 * Set value
			 */
			this.store[key].next(value);
		}
	}

	/**
	 * Get the item in the store
	 * @param key
	 */
	get<T>(key: StoreKeys): T | null {

		/**
		 * If the store item is set
		 */
		if (this.store[key]) {

			/**
			 * Get the item
			 */
			return this.store[key].getValue() as T;
		}

		/**
		 * Return null value
		 */
		return null;
	}

	/**
	 * Delete the item in the store
	 * @param key
	 */
	delete(key: StoreKeys): void {

		/**
		 * If the store item is set
		 */
		if (this.store[key]) {

			/**
			 * Delete the item
			 */
			this.set(key, null);
		}
	}

	/**
	 * Refresh the store resource with it's http method and get the new value
	 */
	refresh(key: StoreKeys): Promise<any> {
		return new Promise((resolve: (value: any) => void, reject: (error: HttpErrorResponse | string) => void) => {
			switch (key) {

				// Gym
				case StoreKeys.Gym:
					this.http.send(Requests.getGym)
						.then((gym: Gym) => {
							this.set(StoreKeys.Gym, gym);
							resolve(gym);
						})
						.catch((error: HttpErrorResponse) => reject(error));
					break;

				default:
					reject('No Http method found for this resource');
					break;
			}
		});
	}
}
