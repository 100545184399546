import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
/* eslint-disable @typescript-eslint/dot-notation */

@Injectable({
	providedIn: 'root'
})
export class LoaderService {

	/**
	 * BehaviorSubject Object
	 */
	private status = new Subject<boolean>();

	constructor() { }

	/**
	 * Set Loader Status to Open
	 */
	show(): void {
		this.status.next(true);
	}

	/**
	 * Set Loader Status to Close
	 */
	hide(): void {
		this.status.next(false);
	}

	/**
	 * Subscribe to new requests
	 */
	subscribe(callback: (value: boolean) => void): void {
		this.status.subscribe(
			(next: boolean) => {
				callback(next);
			}
		);
	}
}
