<main class="lessons">
	<div class="row-title">{{ selectedDate?.format('MMMM') }}</div>

	<div class="dates">
		<ul>
			<li *ngFor="let day of week" [ngClass]="{active: day.isSame(selectedDate,'day') }"
				(click)='selectDate(day)'>
				<div class="content">
					<div class="day">{{ day.format('DD') }}</div>
					<div class="month">{{ day.format('ddd').replace('.','') }}</div>
				</div>
				<figure class="down">
					<i class="bi bi-chevron-down"></i>
				</figure>
			</li>
		</ul>
	</div>

	<div class="lessons">
		<ul class="list-group">
			<li *ngFor="let lesson of lessons" [ngClass]="{'active': lesson.checked_in }"
				[routerLink]="['/', 'lesson', lesson.id]" class="list-group-item">
				<div class="item-left">
					<div class="day">{{ selectedDate?.format('DD') }}</div>
					<div class="month">{{ selectedDate?.format('ddd')?.replace('.','') }}</div>
				</div>
				<div class="item-main">
					<div class="activity">{{ lesson.discipline?.name || '-' }}</div>
					<div class="name">{{ (lesson.coach?.name && lesson.coach?.surname) ? (lesson.coach?.name + ' ' +
						lesson.coach?.surname) : '-' }}</div>
					<div class="hour">
						<figure class="icon">
							<i class="bi bi-clock"></i>
						</figure>
						<span>
							{{ lesson.start_time}} - {{ lesson.end_time}}
						</span>
					</div>
				</div>
				<div class="item-right">
					<span class="people">
						{{ lesson.checkins_count }}/{{ ((lesson.checkins_count || 0) > (lesson.limit || 0)) ? lesson.checkins_count : lesson.limit }}
					</span>
					<figure class="arrow">
						<i class="bi bi-chevron-right"></i>
					</figure>
				</div>
			</li>
		</ul>
	</div>
</main>