/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable guard-for-in */
import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import 'jquery';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { Errors } from 'src/app/enums/errors.enum';
import { MultimediaTypes } from 'src/app/enums/multimedia-types.enum';
import { Gym } from 'src/app/interfaces/gym';
import { User } from 'src/app/interfaces/user';
import { AppService } from 'src/app/services/app/app.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MultimediaService } from 'src/app/services/multimedia/multimedia.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserService } from 'src/app/services/user/user.service';
import { DateDialogComponent } from '../../widgets/date-dialog/date-dialog.component';

@Component({
	selector: 'app-my-profile',
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
	@ViewChild('dateDialog') dateDialog?: DateDialogComponent;

	/**
	 * Form abilitato
	 */
	formEnabled = false;

	/**
	 * User
	 */
	user?: User;

	/**
	 * Gym
	 */
	gym?: Gym;

	/**
	 * Translated names
	 */
	translated = {
		gender: null,
		man: null,
		woman: null,
		public: null,
		private: null,
		document_downloaded: null,
		language: null
	};

	/**
	 * Translated messages
	 */
	messages_translated = {
		unknown_error: null,
		validation_errors: null,
		successful_upload: null,
		document_downloaded: null,
		format_not_supported: null,
		download_error: null,
		insert_validity_date: null,
		edit_successful: null,
		profile_privacy_change: null
	};

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		name: null,
		surname: null,
		birthdate: null,
		gender: null,
		tax_code: null,
		height: null,
		weight: null,
		phone: null,
		address: null,
		city: null,
		postal_code: null,
		country: null,
		email: null,
		password: null,
		password_confirmation: null,
		language: this.languageService.getLanguage()
	});

	/**
	 * Languages
	 */
	languages_available = this.languageService.getAvailableLanguages();

	constructor(
		private fb: FormBuilder,
		private userService: UserService,
		public toast: ToastService,
		private multimedia: MultimediaService,
		private loader: LoaderService,
		private store: StoreService,
		public translate: TranslateService,
		private languageService: LanguageService,
		private appService: AppService
	) { }

	ngOnInit(): void {

		/**
		 * Get gym
		 */
		this.store.onChanges<Gym>(StoreKeys.Gym)?.pipe(first((next) => next !== null))
			.subscribe(next => this.gym = next!);

		/**
		 * Get translations for not linear templates
		 */
		this.translate.get([
			'GENERAL.GENDER',
			'GENERAL.MAN',
			'GENERAL.WOMAN',
			'GENERAL.PRIVATE',
			'GENERAL.PUBLIC',
			'GENERAL.DOCUMENT_DOWNLOADED',
			'GENERAL.LANGUAGE'
		]).subscribe((translations) => {
			this.translated.gender = translations['GENERAL.GENDER'];
			this.translated.man = translations['GENERAL.MAN'];
			this.translated.woman = translations['GENERAL.WOMAN'];
			this.translated.public = translations['GENERAL.PUBLIC'];
			this.translated.private = translations['GENERAL.PRIVATE'];
			this.translated.document_downloaded = translations['GENERAL.DOCUMENT_DOWNLOADED'];
			this.translated.language = translations['GENERAL.LANGUAGE'];
		});

		/**
		 *	Get translations for all ts messages
		 */
		this.translate.get([
			'ERRORS.UNKNOWN_ERROR',
			'MY_PROFILE.SUCCESSFUL_UPLOAD',
			'MY_PROFILE.DOCUMENT_DOWNLOADED',
			'MY_PROFILE.FORMAT_NOT_SUPPORTED',
			'MY_PROFILE.DOWNLOAD_ERROR',
			'MY_PROFILE.INSERT_VALIDITY_DATE',
			'MY_PROFILE.EDIT_SUCCESSFUL',
			'MY_PROFILE.PROFILE_PRIVACY_CHANGE',
			'BUY.VALIDATION_ERRORS'
		]).subscribe((translations) => {
			this.messages_translated.unknown_error = translations['ERRORS.UNKNOWN_ERROR'];
			this.messages_translated.validation_errors = translations['BUY.VALIDATION_ERRORS'];
			this.messages_translated.successful_upload = translations['MY_PROFILE.SUCCESSFUL_UPLOAD'];
			this.messages_translated.document_downloaded = translations['MY_PROFILE.DOCUMENT_DOWNLOADED'];
			this.messages_translated.format_not_supported = translations['MY_PROFILE.FORMAT_NOT_SUPPORTED'];
			this.messages_translated.download_error = translations['MY_PROFILE.DOWNLOAD_ERROR'];
			this.messages_translated.insert_validity_date = translations['MY_PROFILE.INSERT_VALIDITY_DATE'];
			this.messages_translated.edit_successful = translations['MY_PROFILE.EDIT_SUCCESSFUL'];
			this.messages_translated.profile_privacy_change = translations['MY_PROFILE.PROFILE_PRIVACY_CHANGE'];
		});

		/**
		 * Get user
		 */
		this.fetchUser();
	};

	/**
	 * Change profile picture
	 */
	updateProfilePicture(): void {

		/**
		 * Call the multimedia get and set as options camera and gallery
		 */
		this.multimedia.show([
			MultimediaTypes.Camera,
			MultimediaTypes.Gallery
		], {
			height: 1024, // Max size a tablet could have
			width: 1024 // Max size a tablet could have
		}).then((result: MultimediaTypes | { uri: string; name: string } | null) => {

			/**
			 * User cancelled
			 */
			if (!(result as any).uri) {
				return;
			}

			/**
			 * Cast uri to blob
			 */
			this.loader.show();
			this.appService.uriToBlob((result as any).uri)
				.then((blob: Blob) => {

					/**
					 * Customize the name
					 */
					const name = 'Avatar.' + (result as any).name.split('.', 2)[1];

					/**
					 * Form data for the file
					 */
					const formData = new FormData();
					formData.set('file', blob, name);

					/**
					 * Upload file
					 */
					this.userService.upload(formData)
						.then((file) => {

							/**
							 * Set profile picture
							 */
							this.user!.avatar_url = file?.url;

							this.toast.show(this.messages_translated.successful_upload || '');
							this.loader.hide();
						})
						.catch((error: HttpErrorResponse) => {
							this.loader.hide();

							/**
							 * Error catch
							 */
							switch (error?.error?.code) {
								case Errors.ExpiredToken: break;
								case Errors.LackGymCode: break;
								case Errors.GymNotFound: break;
								case Errors.UserNotInGym: break;
								default:
									this.toast.error(this.messages_translated.unknown_error || '');
									console.error(error);
									break;
							}
						});
				});
		}).catch(() => { });
	}

	/**
	 * Open the multimedia file chooser
	 *
	 * @param key type of document  (ex. medic_certificate)
	 */
	openMultimedia(key: string): void {
		this.multimedia.show([
			MultimediaTypes.Camera,
			MultimediaTypes.Gallery,
			MultimediaTypes.File,
			MultimediaTypes.Download
		]).then((result: { uri: string; name: string } | MultimediaTypes | null) => {
			switch (result) {

				/**
				 * I fthe user wants to download the existing file
				 */
				case MultimediaTypes.Download:

					/**
					 * Fill the file var with the requested file
					 */
					let file: any = { url: '', name: '' };
					switch (key) {
						case 'medic-certificate':
							file = this.user?.medic_certificate?.file;
							break;

						case 'greenpass':
							file = this.user?.greenpass?.file;
							break;
					}

					if (!file) {
						this.toast.error(this.translate.instant('NESSUN_FILE_PRESENTE_SU_SERVER'));
						break;
					}

					/**
					 * Download file
					 */
					this.appService.download(file);
					break;

				default:

					/**
					 * If we have a file
					 */
					if (result && (result as { uri: string; name: string }).uri && (result as { uri: string; name: string }).name) {

						/**
						 * Then we need to upload it
						 */
						this.upload(key, result as { uri: string; name: string });
					}
					break;
			}
		});
	}

	/**
	 * Upload selected file
	 *
	 * @param key type of document  (ex. medic_certificate)
	 * @param uri URI of the selected file
	 */
	async upload(key: string, file: { uri: string; name: string }): Promise<void> {

		let dates: {
			valid_from: string;
			valid_to: string;
		};

		/**
		 * Operation cancelled
		 */
		if (!file.uri) { return; }

		switch (key) {
			case 'medic-certificate':
			case 'greenpass':
				const valid_to = await this.dateDialog?.show();

				/**
				 * Date not set (action cancelled)
				 */
				if (valid_to === null) {

					/**
					 * Toast error
					 */
					this.toast.error(this.messages_translated.insert_validity_date || '');
					return;
				}

				/**
				 * Set date to send
				 */
				dates = {
					valid_from: moment().format('DD/MM/YYYY'),
					valid_to: moment(valid_to, moment.HTML5_FMT.DATE).format('DD/MM/YYYY')
				};
				break;
		}

		/**
		 * Customize the name
		 */
		let name = '';
		switch (key) {
			case 'medic-certificate':
				name = 'CertificatoMedico';
				break;

			case 'greenpass':
				name = 'Greenpass';
				break;

			default:
				// Unknown file requested...
				return;
		}

		name += ('.' + file.name.split('.', 2)[1]);

		/**
		 * Cast uri to blob
		 */
		this.appService.uriToBlob(file.uri)
			.then((blob: Blob) => {

				/**
				 * Form data for the file
				 */
				const formData = new FormData();
				formData.set('file', blob, name);
				formData.set('key', key);
				formData.set('valid_from', dates.valid_from);
				formData.set('valid_to', dates.valid_to);

				/**
				 * Upload file
				 */
				this.loader.show();
				this.userService.upload(formData)
					.then(fileObj => {

						switch (key) {
							case 'medic-certificate':
								this.user!.medic_certificate = {
									valid_from: moment().format('DD/MM/YYYY'),
									valid_to: dates.valid_to,
									file: fileObj
								};
								break;

							case 'greenpass':
								this.user!.greenpass = {
									valid_from: moment().format('DD/MM/YYYY'),
									valid_to: dates.valid_to,
									file: fileObj
								};
								break;
						}

						this.toast.show(this.messages_translated.successful_upload || '');
						this.loader.hide();
					})
					.catch((error: HttpErrorResponse) => {
						this.loader.hide();

						/**
						 * Error catch
						 */
						switch (error?.error?.code) {
							case Errors.ExpiredToken: break;
							case Errors.LackGymCode: break;
							case Errors.GymNotFound: break;
							case Errors.UserNotInGym: break;
							default:
								this.toast.error(this.messages_translated.unknown_error || '');
								console.error(error);
								break;
						}
					});
			});
	}

	/**
	 * Update the profile
	 */
	update(): void {
		this.loader.show();

		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		/**
		 * Reset errors
		 */
		for (const key in this.form.value) {
			if (this.form.get(key)) {
				this.form.get(key)?.setErrors(null);
			}
		}

		/**
		 * Sign up
		 */
		this.userService.update(formData)
			.then(() => {
				this.loader.hide();
				this.toast.show(this.messages_translated.edit_successful || '');

				/**
				 * Set new language
				 */
				this.languageService.setLanguage(this.form.get('language')?.value);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					case Errors.Validation:
						this.toast.error(this.messages_translated.validation_errors || '');

						/**
						 * Loop through errors
						 */
						for (const key in error.error.validation) {
							for (const index in error.error.validation[key]) {

								/**
								 * Check for special validation (with message)
								 */
								switch (error.error.validation[key][index]) {

									/**
									 * Passwords don't match
									 */
									case 'password-mismatch':
										this.form.get('password_confirmation')?.setErrors({
											'password-mismatch': true
										});
										this.form.get('password')?.setErrors({
											required: true
										});
										break;

									/**
									 * Email already taken
									 */
									case 'email-taken':
										this.form.get('email')?.setErrors({
											'email-taken': true
										});
										break;

									/**
									 * Password's lenght
									 */
									case 'password-length':
										this.form.get('password')?.setErrors({
											'password-length': true
										});
										break;

									default:
										this.form.get(key)?.setErrors({
											required: true
										});
										break;
								}
							}
						}
						break;
					default:
						this.toast.error(this.messages_translated.unknown_error || '');
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Toggle pubblic or private profile
	 */
	togglePublic(): void {
		this.loader.show();
		this.userService.togglePublic()
			.then((publicProfile: boolean) => {
				this.loader.hide();
				this.user!.public = publicProfile;
				this.toast.show(this.messages_translated.profile_privacy_change! + (publicProfile ? this.translated.public : this.translated.private));
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.messages_translated.unknown_error || '');
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Toggles the form enabled
	 */
	toggleEnabler(): void {

		/**
		 * Toggle value
		 */
		this.formEnabled = !this.formEnabled;

		/**
		 * Refresh validation
		 */
		this.triggerValidation(this.form);
	}

	/**
	 * Toggle google account association
	 */
	toggleGoogleAssociation(): void {

		/**
		 * If the account is associated
		 */
		if (this.user?.is_google_associated) {

			/**
			 * Confirm remove association
			 */
			this.appService.confirm('Scollega account', 'Sei sicuro di voler scollgare l\'account di Google (' + this.user.google_email + ') dal tuo account di Cross-In?')
				.then(() => {
					this.removeAssociation('google');
				})
				.catch(() => { });
		} else {
			this.appService.signInWithGoogle()
				.then((idToken: string | null) => {

					/**
					 * If user didn't abort the sign in
					 */
					if (idToken !== null) {
						this.createAssociation(idToken, 'google');
					}
				})
				.catch((error: HttpErrorResponse) => {

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken:
						case Errors.LackGymCode:
						case Errors.UserNotInGym: break;
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							break;
					}
				});
		}
	}

	/**
	 * Toggle apple account association
	 */
	toggleAppleAssociation(): void {

		/**
		 * If the account is associated
		 */
		if (this.user?.is_apple_associated) {

			/**
			 * Confirm remove association
			 */
			this.appService.confirm('Scollega account', 'Sei sicuro di voler scollgare l\'account di Apple (' + this.user.apple_email + ') dal tuo account di Cross-In?')
				.then(() => {
					this.removeAssociation('apple');
				})
				.catch(() => { });
		} else {
			this.appService.signInWithApple()
				.then((idToken: string | null) => {

					/**
					 * If user didn't abort the sign in
					 */
					if (idToken !== null) {
						this.createAssociation(idToken, 'apple');
					}
				})
				.catch((error: HttpErrorResponse) => {

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken:
						case Errors.LackGymCode:
						case Errors.UserNotInGym: break;
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							break;
					}
				});
		}
	}

	/**
	 * Create association http call
	 */
	private createAssociation(idToken: string, provider: string): void {
		this.loader.show();
		this.userService.createAssociation(idToken, provider)
			.then(() => {
				this.loader.hide();

				/**
				 * Reload user data
				 */
				this.fetchUser();
				this.toast.show(this.translate.instant('ACCOUNT_ASSOCIATO_CON_SUCCESSO'));
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * If there's been an error, logout from firebase
				 */
				switch (provider) {
					case 'google':
						if (!this.user!.is_google_associated) {
							this.appService.signOut();
						}
						break;
					case 'apple':
						if (!this.user!.is_apple_associated) {
							this.appService.signOut();
						}
						break;
				}

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.messages_translated.unknown_error || '');
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Remove association http call
	 */
	private removeAssociation(provider: string): void {
		this.loader.show();
		this.userService.removeAssociation(provider)
			.then(() => {
				this.loader.hide();

				/**
				 * Reload user data
				 */
				this.fetchUser();

				/**
				 * Sign out from firebase
				 */
				this.appService.signOut();
				this.toast.show(this.translate.instant('ACCOUNT_RIMOSSO_CON_SUCCESSO'));
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.messages_translated.unknown_error || '');
						console.error(error);
						break;
				}
			});
	}

	/**
	 * The item is valid
	 */
	isValid(item?: { valid_from?: string; valid_to?: string }): boolean {
		if (!item) { return false; }
		return moment().isBetween(moment(item.valid_from, 'DD/MM/YYYY'), moment(item.valid_to, 'DD/MM/YYYY'), 'day', '[]');
	}

	/**
	 * Logout
	 */
	logout(): void {
		this.userService.logout();
	}

	/**
	 * Click input
	 */
	clickInput(id: string): void {
		$('#' + id).trigger('click').trigger('focus');
	}

	/**
	 * Set gender on change
	 *
	 * @param event
	 */
	setGender(event: string): void {
		if (typeof event === 'string') {
			this.form.get('gender')?.setValue(event);
		}
	}

	/**
	 * Set gender on change
	 *
	 * @param event
	 */
	setLanguage(event: string): void {
		if (typeof event === 'string') {
			this.form.get('language')?.setValue(event);
		}
	}

	/**
	 * Get the user's profile
	 */
	private fetchUser(): void {
		this.loader.show();
		this.userService.get()
			.then((user: User) => {

				this.loader.hide();

				/**
				 * Moment
				 */
				user.created_at = moment(user.created_at, 'DD/MM/YYYY');

				/**
				 * Set the user
				 */
				this.user = user;

				/**
				 * Fill the form
				 */
				this.form.patchValue({
					name: user.name,
					surname: user.surname,
					birthdate: user.birthdate ? moment(user.birthdate, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE) : null,
					gender: user.gender,
					tax_code: user.tax_code,
					height: user.height,
					weight: user.weight,
					phone: user.phone,
					address: user.address,
					city: user.city,
					postal_code: user.postal_code,
					country: user.country,
					email: user.email,
				});

			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.messages_translated.unknown_error || '');
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Work around per fare il refresh della validation
	 */
	private triggerValidation(control: AbstractControl): void {
		if (control instanceof FormGroup) {
			const group = (control as FormGroup);

			for (const field in group.controls) {
				const c = group.controls[field];

				this.triggerValidation(c);
			}
		}
		else if (control instanceof FormArray) {
			const group = (control as FormArray);

			for (const field in group.controls) {
				const c = group.controls[field];

				this.triggerValidation(c);
			}
		}

		control.updateValueAndValidity({ onlySelf: false });
	}
}
