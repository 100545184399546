import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from 'src/app/components/widgets/alert/alert.component';
import { DateDialogComponent } from 'src/app/components/widgets/date-dialog/date-dialog.component';
import { FloatingButtonComponent } from 'src/app/components/widgets/floating-button/floating-button.component';
import { SelectComponent } from 'src/app/components/widgets/select/select.component';
import { PipesModule } from '../pipes/pipes.module';

const components = [
	AlertComponent,
	SelectComponent,
	DateDialogComponent,
	FloatingButtonComponent
]

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		TranslateModule,
		RouterModule
	],
	exports: components
})
export class WidgetsModule { }
