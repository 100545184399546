import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Inputmask from 'inputmask';
import { Errors } from 'src/app/enums/errors.enum';
import { Lesson } from 'src/app/interfaces/lesson';
import { LessonsService } from 'src/app/services/lessons/lessons.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkoutResultsService } from 'src/app/services/workout-results/workout-results.service';

@Component({
	selector: 'app-workout-details',
	templateUrl: './workout-details.component.html',
	styleUrls: ['./workout-details.component.scss']
})
export class WorkoutDetailsComponent implements OnInit {

	/**
	 * Time input
	 */
	@ViewChild('timeInput') timeInput?: ElementRef;

	/**
	 * Form group
	 */
	form = this.fb.group({
		rounds: null,
		time: null,
		distance: null,
		reps: null,
		weight: null,
		heartrate: null,
		calories: null,
		load: null,
		rx: null,
		notes: null
	});

	lesson?: Lesson;

	constructor(
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService,
		private route: ActivatedRoute,
		public routeService: RouteService,
		private fb: FormBuilder,
		private lessonService: LessonsService,
		private workoutResultService: WorkoutResultsService,
		private router: Router
	) { }

	ngOnInit(): void {
		const workoutId = this.route.snapshot.paramMap.get('id');
		if (!workoutId || isNaN(workoutId as unknown as number)) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
			this.router.navigate(['/']);
			return;
		}

		/**
		 * Get the results
		 */
		this.loader.show();
		this.lessonService.getWithWorkoutResults(workoutId)
			.then((lesson: Lesson) => {

				/**
				 * Set lesson
				 */
				this.lesson = lesson;

				/**
				 * Patch form
				 */
				if (lesson.workout_result) {
					this.form.patchValue({
						rounds: lesson.workout_result.rounds,
						time: lesson.workout_result.time,
						distance: lesson.workout_result.distance,
						reps: lesson.workout_result.reps,
						weight: lesson.workout_result.weight,
						heartrate: lesson.workout_result.heartrate,
						calories: lesson.workout_result.calories,
						load: lesson.workout_result.load,
						rx: lesson.workout_result.rx,
						notes: lesson.workout_result.notes
					});
				}

				/**
				 * Set time input mask
				 */
				setTimeout(() => {
					Inputmask({ regex: '^[0-9]?[0-9]:[0-5][0-9]:[0-5][0-9]$' }).mask(this.timeInput?.nativeElement);
				}, 0);

				this.loader.hide();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Submit form
	 */
	submit(): void {
		this.loader.show();

		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		/**
		 * Append lesson ID
		 */
		formData.append('lesson_id', this.lesson?.id?.toString() || '');

		/**
		 * If we don't have a workout result
		 */
		if (!this.lesson?.workout_result) {

			/**
			 * Create result
			 */
			this.workoutResultService.create(formData)
				.then(() => {
					this.loader.hide();
					this.toast.show(this.translate.instant('RISULTATO_DEL_WORKOUT_CREATO_CON_SUCCESSO'));

					/**
					 * Go back so we avoid to reload the wod result for the update
					 */
					this.routeService.back();
				})
				.catch((error: HttpErrorResponse) => {
					this.loader.hide();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken: break;
						case Errors.LackGymCode: break;
						case Errors.GymNotFound: break;
						case Errors.UserNotInGym: break;
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							console.error(error);
							break;
					}
				});
		} else {

			/**
			 * Append workout result ID
			 */
			formData.append('id', this.lesson?.workout_result.id?.toString() || '');

			/**
			 * Update result
			 */
			this.workoutResultService.update(formData)
				.then(() => {
					this.loader.hide();
					this.toast.show(this.translate.instant('RISULTATO_DEL_WORKOUT_MODIFICATO_CON_SUCCESSO'));
				})
				.catch((error: HttpErrorResponse) => {
					this.loader.hide();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken: break;
						case Errors.LackGymCode: break;
						case Errors.GymNotFound: break;
						case Errors.UserNotInGym: break;
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							console.error(error);
							break;
					}
				});
		}
	}
}
