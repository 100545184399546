import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit, Component, NgZone, OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Hammer from 'hammerjs';
import * as moment from 'moment';
import { Errors } from 'src/app/enums/errors.enum';
import { Lesson } from 'src/app/interfaces/lesson';
import { LessonsService } from 'src/app/services/lessons/lessons.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-lessons',
	templateUrl: './lessons.component.html',
	styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit, AfterViewInit {

	lessons?: Lesson[];

	selectedDate?: moment.Moment;

	week: moment.Moment[] = [];

	constructor(
		private lessonsService: LessonsService,
		private loader: LoaderService,
		private toast: ToastService,
		private router: Router,
		private route: ActivatedRoute,
		private ngZone: NgZone,
		private translate: TranslateService
	) { }

	ngAfterViewInit(): void {

		/**
		 * On swipe the dates container
		 */
		setTimeout(() => {
			new Hammer($('.dates')[0]).on('swipe', (event: any) => {

				/**
				 * Get the direction and set the corresponding week
				 */
				switch (event.offsetDirection) {
					case Errors.ExpiredToken: // Left
						this.setWeek(this.week[0].add(1, 'week'));
						break;

					case Errors.QueryException: // Right
						this.setWeek(this.week[0].subtract(1, 'week'));
						break;

					default: return;
				}

				/**
				 * Select the first day of the week
				 */
				this.selectDate(this.week[0]);
			});
		}, 100);
	}

	ngOnInit(): void {

		/**
		 * Get the last date selected
		 */
		let startDate = moment();
		const dateParam = this.route.snapshot.paramMap.get('date');
		if (dateParam) {
			startDate = moment(dateParam, 'YYYY-MM-DD');
		}

		/**
		 * Init the week
		 */
		this.setWeek(startDate.clone().startOf('isoWeek'));

		/**
		 * Set date
		 */
		this.selectDate(startDate);
	}

	/**
	 * Select the given day of the week
	 */
	selectDate(day: moment.Moment): void {
		this.loader.show();
		this.selectedDate = day;

		/**
		 * Get lessons
		 */
		this.lessonsService.list(day.format('YYYY-MM-DD'))
			.then((lessons: Lesson[]) => {
				this.ngZone.run(() => {
					this.loader.hide();

					/**
					 * Set the lessons
					 */
					this.lessons = lessons;

					/**
					 * Set it to params so we can go back to it
					 */
					this.router.navigate(['/', 'lessons', day.format('YYYY-MM-DD')]);
				});
			})
			.catch((error: HttpErrorResponse) => {
				this.ngZone.run(() => {
					this.loader.hide();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken: break;
						case Errors.LackGymCode: break;
						case Errors.GymNotFound: break;
						case Errors.UserNotInGym: break;
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							console.error(error);
							break;
					}
				});
			});
	}

	/**
	 * Set week for the requested date
	 */
	setWeek(date: moment.Moment = moment()): void {
		setTimeout(() => {
			this.ngZone.run(() => {
				this.week = [];
				const endOfWeek = date.clone().endOf('isoWeek');
				let day = date.clone().startOf('isoWeek');
				while (day.isBefore(endOfWeek)) {
					this.week.push(day);
					day = day.clone().add(1, 'd');
				}
			});
		}, 0);
	}
}
