<main class="buy">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "BUY.TITLE"|translate }}
	</div>

	<section class="intro" *ngIf="product">
		<figure class="background">
			<img [attr.src]="product?.image_url || 'assets/img/png/abbonamento.png'" alt="" />
		</figure>
		<div class="text-container">
			<h3>
				{{ product?.name }}
			</h3>

			<!-- Membership fee -->
			<p *ngIf="form.get('product_type')?.value === 'membership-fee'">
				{{ 'VALIDO_PER_UN_ANNO_DALLA_DATA_DI_ACQUISTO'|translate }}
			</p>

			<h2>
				{{ (product?.total || 0) | decimal }} {{ gym?.currency?.symbol }}
			</h2>
		</div>
	</section>

	<form ngxStripeCardGroup [formGroup]="form" [elementsOptions]="elementsOptions" (ngSubmit)="submit()">
		<div class="card">
			<h4 class="card-title">{{ "GENERAL.PERSONAL_DATA"|translate }}</h4>
			<div class="card-body">
				<div class="form-row">
					<input type="text" [ngClass]="{'is-invalid': form.get('name')?.errors}" formControlName='name'
						placeholder='{{ "GENERAL.NAME"|translate }}'>
				</div>
				<div class="form-row">
					<input type="text" [ngClass]="{'is-invalid': form.get('surname')?.errors}" formControlName='surname'
						placeholder='{{ "GENERAL.SURNAME"|translate }}'>
				</div>
				<div class="form-row">
					<input type="text" [ngClass]="{'is-invalid': form.get('email')?.errors}" formControlName='email'
						placeholder="Email">
				</div>
				<div class="form-row">
					<input [ngClass]="{'is-invalid': form.get('address')?.errors}" type="text" formControlName='address'
						placeholder='{{ "GENERAL.ADDRESS"|translate }}'>
				</div>
				<div class="form-row">
					<input [ngClass]="{'is-invalid': form.get('city')?.errors}" type="text" formControlName='city'
						placeholder='{{ "GENERAL.CITY"|translate }}'>
				</div>
				<div class="form-row">
					<input [ngClass]="{'is-invalid': form.get('postal_code')?.errors}" type="text"
						formControlName='postal_code' placeholder='{{ "GENERAL.POSTAL_CODE"|translate }}'>
				</div>
				<div class="form-row">
					<input [ngClass]="{'is-invalid': form.get('country')?.errors}" type="text" formControlName='country'
						placeholder='{{ "GENERAL.COUNTRY"|translate }}'>
				</div>
				<div class="form-row">
					<input [ngClass]="{'is-invalid': form.get('tax_code')?.errors}" type="text"
						formControlName='tax_code' placeholder='{{ "MY_PROFILE.TAX_CODE"|translate }}'>
				</div>
			</div>
		</div>

		<div class="card">
			<h4 class="card-title">{{ "BUY.PAYMENT_DATA"|translate }}</h4>
			<div class="card-body">
				<ul class="payment-methods">
					<li class="item" *ngFor="let paymentMethod of stripePaymentMethods">
						<input type="radio" formControlName="payment_method_id" [id]="paymentMethod.id"
							[value]="paymentMethod.id">
						<label class="label" [attr.for]="paymentMethod.id">
							XXXX XXXX XXXX {{ paymentMethod.card?.last4 }}
						</label>
						<i class="bi bi-trash-fill delete" (click)="deletePaymentMethod(paymentMethod.id)"></i>
					</li>
					<li class="new">
						<div class="item-container">
							<input type="radio" formControlName="payment_method_id" [value]="null"
								id="payment-method-new">
							<label class="label" for="payment-method-new">
								{{ 'NUOVA_CARTA'|translate }}
							</label>
						</div>
						<div [hidden]="form.get('payment_method_id')?.value !== null">
							<ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<app-floating-button type="submit">
			{{ "GENERAL.BUY"|translate }}
		</app-floating-button>
	</form>
</main>