import { Injectable } from '@angular/core';

export enum Interceptors {
	Gym, Auth, FCM, Session
}

@Injectable({
	providedIn: 'root'
})
export class InterceptorsService {

	private interceptors: { [key: number]: boolean } = {};

	constructor() {

		/**
		 * Init enums interceptors
		 */
		let key: any;
		for (key in Interceptors) {
			if (Object.prototype.hasOwnProperty.call(Interceptors, key)) {

				/**
				 * Default to false
				 */
				this.interceptors[key] = false;
			}
		}
	}

	isEnabled(interceptor: Interceptors): boolean {
		return this.interceptors[interceptor];
	}

	enable(interceptors: Interceptors[]): void {
		interceptors.forEach(interceptor => {
			this.interceptors[interceptor] = true;
		});
	}

	disable(interceptors: Interceptors[]): void {
		interceptors.forEach(interceptor => {
			this.interceptors[interceptor] = false;
		});
	}
}
