/* eslint-disable guard-for-in */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Errors } from 'src/app/enums/errors.enum';
import { Exercice, Record } from 'src/app/interfaces/record';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RecordsService } from 'src/app/services/records/records.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Inputmask from 'inputmask';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-records-edit',
	templateUrl: './records-edit.component.html',
	styleUrls: ['./records-edit.component.scss']
})
export class RecordsEditComponent implements OnInit {

	/**
	 * Time input
	 */
	@ViewChild('valueInput') valueInput?: ElementRef;

	/**
	 * Requested record
	 */
	record?: Record;

	/**
	 * Record's exercice
	 */
	exercice?: Exercice;

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		date: null,
		value: null,
		exercice_id: null,
		notes: null
	});

	constructor(
		private loader: LoaderService,
		public routeService: RouteService,
		private toast: ToastService,
		private recordsService: RecordsService,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private router: Router,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Get the ID from the url
		 */
		const recordId = this.route.snapshot.paramMap.get('id');

		/**
		 * Get the ID of the exercice from the url
		 */
		const exerciceId = this.route.snapshot.paramMap.get('exercice');

		/**
		 * Set exercice ID to the form
		 */
		this.form.get('exercice_id')?.setValue(exerciceId);

		/**
		 * If we are in update mode
		 */
		if (recordId) {

			/**
			 * Get the exercice
			 */
			this.loader.show();
			this.recordsService.get(parseInt(recordId))
				.then((record: Record) => {

					this.record = record;
					this.exercice = this.record.exercice;

					/**
					 * Fill the form
					 */
					this.form.patchValue({
						value: record.value,
						date: record.date ? moment(record.date, 'YYYY-MM-DD').format(moment.HTML5_FMT.DATE) : null,
						notes: record.notes
					});

					setTimeout(() => {

						/**
						 * If it's time, activate the time input mask
						 */
						if (this.exercice?.measurement_unit?.slug === 'time') {
							Inputmask({ regex: '^([0-9]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$' }).mask(this.valueInput?.nativeElement);
						} else {
							Inputmask({ regex: '^[0-9]*\\.{0,1}[0-9]*$' }).mask(this.valueInput?.nativeElement);
						}

						this.loader.hide();
					}, 0);
				})
				.catch((error: HttpErrorResponse) => {
					this.loader.hide();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken: break;
						case Errors.LackGymCode: break;
						case Errors.GymNotFound: break;
						case Errors.UserNotInGym: break;
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							console.error(error);
							break;
					}
				});
		} else if(exerciceId) {

			/**
			 * Default date value is today
			 */
			this.form.patchValue({
				date: moment().format(moment.HTML5_FMT.DATE)
			});

			/**
			 * Get the exercice
			 */
			this.loader.show();
			this.recordsService.exercice(parseInt(exerciceId))
				.then((exercice: Exercice) => {

					this.exercice = exercice;

					setTimeout(() => {

						/**
						 * If it's time, activate the time input mask
						 */
						if (this.exercice?.measurement_unit?.slug === 'time') {
							Inputmask({ regex: '^([0-9]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$' }).mask(this.valueInput?.nativeElement);
						} else {
							Inputmask({ regex: '^[0-9]*\\.{0,1}[0-9]*$' }).mask(this.valueInput?.nativeElement);
						}

						this.loader.hide();
					}, 0);
				})
				.catch((error: HttpErrorResponse) => {
					this.loader.hide();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken: break;
						case Errors.LackGymCode: break;
						case Errors.GymNotFound: break;
						case Errors.UserNotInGym: break;
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							console.error(error);
							break;
					}
				});

		}
	}

	/**
	 * Click input
	 */
	clickInput(id: string): void {
		$('#' + id).trigger('click').trigger('focus');
	}


	/**
	 * On submit the form
	 */
	submit(): void {
		this.loader.show();

		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				switch (key) {
					case 'date':
						formData.append(key, moment(this.form.value[key], moment.HTML5_FMT.DATE).format('YYYY-MM-DD'));
						break;

					default:
						formData.append(key, this.form.value[key]);
						break;
				}
			}
		}

		/**
		 * Reset errors
		 */
		for (const key in this.form.value) {
			this.form.get(key)?.setErrors(null);
		}

		/**
		 * Get the request we need to use
		 */
		const promise = this.record?.id ? this.recordsService.update(formData, this.record.id) : this.recordsService.create(formData);

		/**
		 * Sign up
		 */
		promise.then(() => {
			this.loader.hide();
			this.toast.show(this.translate.instant('RECORDS.SUCCESS', {
				type: this.record ? this.translate.instant('GENERAL.MODIFIED') : this.translate.instant('GENERAL.INSERTED')
			}));

			this.routeService.back();
		})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					case Errors.Validation:
						this.toast.error(this.translate.instant('ERRORS.GENERAL_VALIDATION'));

						/**
						 * Loop through errors
						 */
						for (const key in error.error.validation) {
							for (const index in error.error.validation[key]) {

								/**
								 * Check for special validation (with message)
								 */
								switch (error.error.validation[key][index]) {

									default:
										this.form.get(key)?.setErrors({
											required: true
										});
										break;
								}
							}
						}
						break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
