<main class="workout" *ngIf="workout">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ 'WORKOUT'|translate }}
	</div>

	<div class="card" *ngIf="workout.warm_up && workout.warm_up?.description">
		<div class="card-title">{{ 'WARM_UP'|translate }}</div>
		<div class="card-body">
			<ul>
				<li *ngFor="let exercise of (workout.warm_up?.description || '').split('\n')">
					{{ exercise }}
				</li>
			</ul>
		</div>
	</div>
	<div class="card" *ngIf="workout.skills && workout.skills?.description">
		<div class="card-title">{{ 'STRENGTH_AND_SKILLS'|translate }}</div>
		<div class="card-body">
			<ul>
				<li *ngFor="let exercise of (workout.skills?.description || '').split('\n')">
					{{ exercise }}
				</li>
			</ul>
		</div>
	</div>
	<div class="card" *ngIf="workout.wod && workout.wod?.description">
		<div class="card-title">{{ 'WOD'|translate }}</div>
		<div class="card-body">
			<ul>
				<li *ngFor="let exercise of (workout.wod?.description || '').split('\n')">
					{{ exercise }}
				</li>
			</ul>
		</div>
	</div>
	<app-floating-button [routerLink]="['/', 'workout-details', lessonId]" *ngIf="lessonId">
		{{ 'I_MIEI_RISULTATI'|translate }}
	</app-floating-button>
</main>