<main class="thank-you">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "THANK_YOU.TITLE"|translate }}
	</div>

	<section class="card active">
		<div class="card-body">
			<span>
				{{ "THANK_YOU.THANKS_FIRST"|translate }}
			</span>
			<span>
				{{ "THANK_YOU.THANKS_SECOND"|translate }}
			</span>
			<br>
			<br>
			<span>
				{{ "THANK_YOU.MAIL_MESSAGE"|translate }}
			</span>
			<br>
			<br>
			<a href="mailto:info@cross-in.com" class="link">info@cross-in.com</a>
		</div>
	</section>

	<app-floating-button [routerLink]="['/', 'abbonamento']">
		{{ "GENERAL.CLOSE"|translate }}
	</app-floating-button>
</main>