/* eslint-disable guard-for-in */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Errors } from 'src/app/enums/errors.enum';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	/**
	 * Form
	 */
	form = this.fb.group({
		email: null
	});

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toast: ToastService,
		private loader: LoaderService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Redirect if authenticated
		 */
		if (this.userService.isAuthenticated()) {
			this.router.navigateByUrl('/bacheca');
		}
	}

	/**
	 * Recover Password
	 */
	recoverPassword(): void {
		this.loader.show();

		const formdata = new FormData();
		formdata.append('email', this.form.get('email')?.value);

		this.userService.requestPassword(formdata)
			.then(() => {
				this.loader.hide();

				/**
				 * Redirect dashboard
				 */
				this.router.navigateByUrl('/login');

				/**
				 *	Toast
				 */
				this.toast.show(this.translate.instant('FORGOT_PASSWORD.SUCCESS'));
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					case Errors.LackPermission:
						this.toast.error(this.translate.instant('ERRORS.NO_PERMISSIONS'));
						break;
					case Errors.ModelNotFound:
						this.toast.error(this.translate.instant('ERRORS.NO_USER_FOUND'));
						break;
					case Errors.Validation:
						this.toast.error(this.translate.instant('LOGIN.VALIDATION_ERROR'));
						for (const key in error.error.validation) {
							this.form.get(key)?.setErrors({
								required: true
							});
						}
						break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
