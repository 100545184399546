import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxGlobalEventsService } from 'ngx-global-events';
import { first } from 'rxjs';
import { Errors } from 'src/app/enums/errors.enum';
import { GlobalEvents } from 'src/app/enums/global-events.enum';
import { Gym } from 'src/app/interfaces/gym';
import { Notification } from 'src/app/interfaces/notification';
import { AppService } from 'src/app/services/app/app.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	gym?: Gym;

	/**
	 * Notifications
	 */
	interval: any;
	newNotifications = 0;

	constructor(
		public router: Router,
		private translator: TranslateService,
		private userService: UserService,
		private ngZone: NgZone,
		private notificationsService: NotificationsService,
		private appService: AppService,
		private globalEventsService: NgxGlobalEventsService,
		private store: StoreService
	) { }

	ngOnInit(): void {

		/**
		 * Get gym
		 */
		this.store.onChanges<Gym>(StoreKeys.Gym)?.pipe(first((next) => next !== null))
			.subscribe(next => this.gym = next!);

		/**
		 * Listen for refresh notifications event
		 */
		this.globalEventsService.get(GlobalEvents.RequestRefreshNewNotifications)
			.subscribe(() => {

				/**
				 * Refresh notifications
				 */
				this.refreshNewNotifications();
			});

		/**
		 * On push notification received
		 */
		this.appService.onPushNotificationReceived()
			.subscribe(() => {

				/**
				 * Refresh notifications
				 */
				this.refreshNewNotifications();
			});
	}

	/**
	 * Refresh unread notifications
	 */
	private refreshNewNotifications(): void {

		/**
		 * Get new notifications
		 */
		if (this.userService.isAuthenticated()) {
			this.notificationsService.new()
				.then((notifications: Notification[]) => {

					/**
					 * Set the new notifications
					 */
					this.ngZone.run(() => {
						this.newNotifications = notifications.length;
					});
				})
				.catch((error: HttpErrorResponse) => {

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.ExpiredToken: break;
						case Errors.LackGymCode: break;
						case Errors.GymNotFound: break;
						case Errors.UserNotInGym: break;
						default:
							console.error(error);
							break;
					}
				});
		}
	}
}
