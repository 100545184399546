import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgxGlobalEventsService } from 'ngx-global-events';
import { Subject } from 'rxjs';
import { GlobalEvents } from 'src/app/enums/global-events.enum';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { environment } from 'src/environments/environment';
import { HttpService, Requests } from '../http/http.service';
import { LoaderService } from '../loader/loader.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { RouteService } from '../route/route.service';
import { StoreKeys, StoreService } from '../store/store.service';
declare const cordova: any;
declare const window: any;

@Injectable({
	providedIn: 'root'
})
export class AppService {

	/**
	 * Notification received
	 */
	private messageReceivedSubject: Subject<void> = new Subject<void>();

	constructor(
		private routeService: RouteService,
		private ngZone: NgZone,
		private localStorage: LocalStorageService,
		private http: HttpService,
		private router: Router,
		private loader: LoaderService,
		private store: StoreService,
		private globalEventsService: NgxGlobalEventsService
	) { }

	/**
	 * Init app features
	 */
	init(): void {
		document.addEventListener('deviceready', (ev) => {
			if (!this.isApp()) {
				return;
			}

			/**
			 * Grant premissions notifications
			 */
			window.FirebasePlugin.grantPermission();

			/**
			 * Apply iOS Style
			 */
			window.StatusBar.overlaysWebView(true);

			/**
			 * Set notch style
			 */
			switch (cordova.platform) {
				case 'android':
					window.StatusBar.backgroundColorByHexString('#33000000');
					break;
				case 'ios':
					window.StatusBar.styleLightContent();
					break;
			}

			/**
			 * Handle custom schema app init
			 */
			window.handleOpenURL = (url: string) => {
				console.log('received url: ' + url);
			};

			/**
			 * Listen to push notifications
			 */
			window.FirebasePlugin.onMessageReceived(
				(message: any) => {
					console.log(message);

					/**
					 * Redirect if needed
					 */
					if (message.tap) {

						/**
						 * Wait app for load (there's no other way)
						 */
						this.ngZone.run(() => {
							this.loader.show();
							setTimeout(() => {
								switch (message.redirect) {

									/**
									 * Go to notifications by default
									 */
									default:
										this.router.navigate(['/', 'inbox']);
										break;
								}
								this.loader.hide();
							}, message.tap === 'background' ? 3000 : 0);
						});
					}

					this.messageReceivedSubject.next();
				},
				(error: any) => { }
			);

			/**
			 * Set/Refresh FCM token
			 */
			window.FirebasePlugin.onTokenRefresh(
				(fcmToken: string) => {

					/**
					 * Set FCM Token
					 */
					this.setFCMToken(fcmToken);
				},
				(error: any) => {
					console.error(error);
				}
			);

			/**
			 * Detect insets and create CSS variables
			 */
			this.detectInsets();
		});

		/**
		 * On back button
		 */
		document.addEventListener('backbutton', (e) => {
			e.preventDefault();
			this.ngZone.run(() => {
				setTimeout(() => {

					/**
					 * Got to last URL
					 */
					this.routeService.back();
				}, 0);
			});
		}, false);

		/**
		 * On app resume
		 */
		document.addEventListener('resume', (ev) => {

			/**
			 * If logged in
			 */
			if (this.localStorage.getItem(LocalStorageTypes.GymCode)) {

				/**
				 * Set gym
				 */
				this.loader.show();
				this.store.refresh(StoreKeys.Gym).finally(() => this.loader.hide());
			}

			/**
			 * Update the notifications
			 */
			this.globalEventsService.emit(GlobalEvents.RequestRefreshNewNotifications);
		});
	}

	/**
	 * Is the environment an app
	 */
	isApp(): boolean {
		return typeof cordova !== 'undefined';
	}

	/**
	 * Get FCM token for the device
	 */
	getFCMToken(): Promise<string | null> {
		return new Promise((resolve: (token: string | null) => void, reject: (error?: any) => void) => {
			if (!this.isApp()) {
				return resolve(null);
			}

			/**
			 * Get FCM token
			 */
			window.FirebasePlugin.getToken((token: string) => {
				resolve(token);
			});
		});
	}

	/**
	 * Sign in with google
	 */
	signInWithGoogle(): Promise<string | null> {
		return new Promise((resolve: (idToken: string | null) => void, reject: () => void) => {
			window.FirebasePlugin.authenticateUserWithGoogle(environment.googleClientId, (credential: any) => {
				window.FirebasePlugin.signInWithCredential(credential, () => {
					window.FirebasePlugin.getCurrentUser((user: any) => {
						if (user) {
							resolve(user.idToken);
						} else {
							console.error('Firebase user not found!');
							reject();
						}
					});
				}, (error: string) => {
					console.warn(error);

					// * This is most probably a cancellation of the user
					// * Doesn't mean it's not a bug of the app if the behaviour is undesired
					resolve(null);
				});
			}, (error: string) => {

				// SHA-1 in firebase problem
				console.error(error);
				console.warn("Hey, Ian here, se l'errore è qualcosa come 'uknown server client ID' o 'response 12500 unknown' probabilmente non sei autorizato da firebase per fare il google sign in, per abilitarti vai su https://console.firebase.google.com/u/1/project/cross-in-cbb53/settings/general/android:it.camacrea.crossin e aggiungi il tuo SHA-1 fingerprint alla lista, per avere il fingerprint del tuo android studio fai 'keytool -list -v -keystore ~/.android/debug.keystore' con pass 'android', una volta aggiunto il SHA-1 alla lista, scarica il google-services.json e buttalo dentro il progetto cordova. Fai cordova build o prepare + play su android studio e dovrebbe funzionare");

				// * The error code 12051 is also used to describe when the user aborts the sign in, so for UX this error is not shown to the user
				// * This doesn't mean that in your code there's no error, it can be very much a bug if this error appears and the behaviour is not expected
				if (error.includes('12501')) {
					resolve(null);
				} else {
					console.error(error);
					reject();
				}
			});
		});
	}

	/**
	 * Sign in with apple
	 */
	signInWithApple(): Promise<string | null> {
		return new Promise((resolve: (idToken: string | null) => void, reject: () => void) => {
			window.FirebasePlugin.authenticateUserWithApple((credential: any) => {
				window.FirebasePlugin.signInWithCredential(credential, () => {
					window.FirebasePlugin.getCurrentUser((user: any) => {
						if (user) {
							resolve(user.idToken);
						} else {
							console.error('Firebase user not found!');
							reject();
						}
					});
				}, (error: string) => {
					console.warn(error);

					// * This is most probably a cancellation of the user
					// * Doesn't mean it's not a bug of the app if the behaviour is undesired
					resolve(null);
				});
			}, (error: any) => {

				// SHA-1 in firebase problem
				console.error(error);
				console.warn("Hey, Ian here, se l'errore è qualcosa come 'uknown server client ID' o 'response 12500 unknown' probabilmente non sei autorizato da firebase per fare il google sign in, per abilitarti vai su https://console.firebase.google.com/u/1/project/cross-in-cbb53/settings/general/android:it.camacrea.crossin e aggiungi il tuo SHA-1 fingerprint alla lista, per avere il fingerprint del tuo android studio fai 'keytool -list -v -keystore ~/.android/debug.keystore' con pass 'android', una volta aggiunto il SHA-1 alla lista, scarica il google-services.json e buttalo dentro il progetto cordova. Fai cordova build o prepare + play su android studio e dovrebbe funzionare");

				// * The error code 12051 is also used to describe when the user aborts the sign in, so for UX this error is not shown to the user
				// * This doesn't mean that in your code there's no error, it can be very much a bug if this error appears and the behaviour is not expected
				if (error.includes('12501')) {
					resolve(null);
				} else {
					console.error(error);
					reject();
				}
			}, 'en_GB');
		});
	}

	/**
	 * Sign out form firebase
	 */
	signOut(): Promise<void> {
		return new Promise((resolve: () => void, reject: () => void) => {
			window.FirebasePlugin.signOutUser(() => {
				resolve();
			}, (error: any) => {
				reject();
			});
		});
	}

	/**
	 * On push notification received
	 */
	onPushNotificationReceived(): Subject<void> {
		return this.messageReceivedSubject;
	}

	/**
	 * Show confirm dialog
	 */
	confirm(title: string, message: string): Promise<void> {
		return new Promise((resolve: () => void, reject: () => void) => {

			/**
			 * Is app
			 */
			if (this.isApp()) {
				window.navigator.notification.confirm(
					message,
					(index: number) => {
						if (index === 1) {
							resolve();
						} else {
							reject();
						}
					},
					title,
					['Accetta', 'Cancella']
				);
			} else {
				if (window.confirm(message)) {
					resolve();
				} else {
					reject();
				}
			}
		});
	}

	/**
	 * Download the requested document
	 */
	download(file: { url: string; name: string }): void {

		/**
		 * Open document in browser
		 */
		if (this.isApp()) {
			cordova.InAppBrowser.open(file.url, '_system');
		} else {
			window.open(file.url);
		}
	}

	/**
	 * Cast Uri to BLOB
	 */
	uriToBlob(uri: string): Promise<Blob> {
		return new Promise((resolve: (blob: Blob) => void, reject: (error: FileError) => void) => {
			window.resolveLocalFileSystemURL(uri,
				(fileEntry: FileEntry) => {
					fileEntry.file((file: File) => {
						const reader = new FileReader();
						reader.onloadend = function (ev) {
							const blob = new Blob([new Uint8Array(this.result as ArrayBuffer)]);
							resolve(blob);
						};
						reader.readAsArrayBuffer(file);
					});
				},
				(error: FileError) => {
					reject(error);
				});
		});
	}

	/**
	 * Bind FCM token to the user
	 */
	private setFCMToken(fcmToken: string): void {

		/**
		 * Get old token from local storage
		 */
		const oldToken = JSON.parse(JSON.stringify(this.localStorage.getItem(LocalStorageTypes.FCMToken)));

		/**
		 * Set new token on local storage
		 * This need to be here so at app start the fcm interceptor can send the token to the server
		 */
		this.localStorage.setItem(LocalStorageTypes.FCMToken, fcmToken);

		/**
		 * Send refresh token request
		 */
		if (this.localStorage.getItem(LocalStorageTypes.AuthToken)) {
			this.http.send(Requests.setFCMToken, {
				body: {
					old: oldToken,
					new: fcmToken
				}
			}).then(() => { });
		}
	}

	/**
	 * Detect insets (notch) and add CSS variables to style
	 */
	private detectInsets(): void {
		if (window.AndroidNotch) {
			const style = document.documentElement.style;

			// Apply insets as css variables

			window.AndroidNotch.getInsetTop((px: any) => {
				style.setProperty('--notch-inset-top', px + 'px');
			}, (err: any) => console.error('Failed to get insets top:', err));

			window.AndroidNotch.getInsetRight((px: any) => {
				style.setProperty('--notch-inset-right', px + 'px');
			}, (err: any) => console.error('Failed to get insets right:', err));

			window.AndroidNotch.getInsetBottom((px: any) => {
				style.setProperty('--notch-inset-bottom', px + 'px');
			}, (err: any) => console.error('Failed to get insets bottom:', err));

			window.AndroidNotch.getInsetLeft((px: any) => {
				style.setProperty('--notch-inset-left', px + 'px');
			}, (err: any) => console.error('Failed to get insets left:', err));
		}
	}
}
