<main class="my-profile">

	<div class="row-title">{{ "MY_PROFILE.TITLE"|translate }}</div>

	<div class="profile" *ngIf='user'>

		<div class="row">
			<figure class='photo avatar' (click)='updateProfilePicture()'>
				<img [src]='user.avatar_url || "assets/img/png/upload.png"' alt="" #profilePicture>
			</figure>
			<div class="col">
				<h4 class="name">{{ user.name }} <br>{{ user.surname }}</h4>
				<div class="public" (click)="togglePublic()">
					<figure class="icon">
						<i class="bi bi-eye{{ user.public ? '' : '-slash' }}"></i>
					</figure>
					<span class="text">
						{{ "GENERAL.PROFILE"|translate }} {{ user.public ? ("GENERAL.PUBLIC"|translate) :
						("GENERAL.PRIVATE"|translate) }}
					</span>
				</div>
			</div>
		</div>

		<div class="head">
			<div class="row">
				<div class="text" [routerLink]="['/', 'checkins']">
					<span class="big">
						{{ user.checkins }}
					</span>
					<span class="little">
						{{ "GENERAL.CHECK-IN"|translate }}
					</span>
				</div>
				<div class="text">
					<span class="big">
						{{ user.created_at?.year() }}
					</span>
					<span class="little">
						{{ "GENERAL.SUBSCRIBED_FROM"|translate }}
					</span>
				</div>
				<div class="text" [routerLink]="['/', 'records']">
					<span class="big">
						<figure class="icon">
							<i class="icon-medal"></i>
						</figure>
					</span>
					<span class="little">
						{{ 'RECORDS_TITLE'|translate }}
					</span>
				</div>
			</div>
			<div class="row">
				<button type="button" class="button"
					[ngClass]="{'red': !isValid(user.medic_certificate), 'green': isValid(user.medic_certificate)}"
					(click)="openMultimedia('medic-certificate')">
					{{ "MY_PROFILE.MEDIC_CERTIFICATE"|translate }}<br>
					{{ user.medic_certificate?.valid_to }}
				</button>

				<!-- See membership fee -->
				<button type="button" class="button green" [routerLink]="['/', 'abbonamento', user.membership_fee?.id]"
					*ngIf="user.membership_fee?.sale?.paid_at && isValid(user.membership_fee)">
					{{ gym?.membership_fee?.name }}
					<br>
					{{ user.membership_fee?.valid_to }}
				</button>

				<!-- Pay membership fee -->
				<button type="button" class="button yellow" [routerLink]="['/', 'buy', 'membership-fee']"
					[queryParams]="{ sale: user.membership_fee?.sale?.id }"
					*ngIf="!user.membership_fee?.sale?.paid_at && isValid(user.membership_fee)">
					{{ gym?.membership_fee?.name }}
					<br>
					{{ 'PAGARE_ENTRO'|translate }} {{ user.membership_fee?.sale?.payment_deadline }}
				</button>

				<!-- Buy membership fee -->
				<button type="button" class="button red" [routerLink]="['/', 'buy', 'membership-fee']"
					*ngIf="!isValid(user.membership_fee)">
					{{ gym?.membership_fee?.name }}
					<br *ngIf="user.membership_fee?.valid_to">
					{{ user.membership_fee?.valid_to }}
				</button>
			</div>
			<div class="row">
				<button type="button" class="button"
					[ngClass]="{'red': !isValid(user.privacy), 'green': isValid(user.privacy)}"
					[routerLink]="['/', 'gym-privacy']">
					{{ 'PRIVACY'|translate }}<br>
					{{ user.privacy?.valid_to }}
				</button>
				<button type="button" class="button"
					[ngClass]="{'red': !isValid(user.greenpass), 'green': isValid(user.greenpass)}"
					(click)="openMultimedia('greenpass')">
					{{ 'GREEN_PASS'|translate }}<br>
					{{ user.greenpass?.valid_to }}
				</button>
			</div>
		</div>

		<form class="form" action="" [formGroup]="form" (submit)='update()'>
			<div class="card">
				<div class="card-title">
					<h4>{{ "GENERAL.PERSONAL_DATA"|translate }}</h4>
					<figure [ngClass]="{active: formEnabled}" class="icon" (click)="toggleEnabler()">
						<i class="bi bi-pencil"></i>
					</figure>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="form-group">
							<input [attr.disabled]="!formEnabled ? 'disabled' : null" type="text"
								[ngClass]="{'is-invalid': form.get('name')?.errors}" formControlName='name'
								placeholder='{{ "GENERAL.NAME"|translate }}'>
						</div>
						<div class="form-group">
							<input [attr.disabled]="!formEnabled ? 'disabled' : null" type="text"
								[ngClass]="{'is-invalid': form.get('surname')?.errors}" formControlName='surname'
								placeholder='{{ "GENERAL.SURNAME"|translate }}'>
						</div>
					</div>
					<div class="row">
						<div class="form-group">
							<input [attr.disabled]="!formEnabled ? 'disabled' : null" type="text"
								[ngClass]="{'is-invalid': form.get('tax_code')?.errors}" formControlName='tax_code'
								placeholder='{{ "MY_PROFILE.TAX_CODE"|translate }}'>
						</div>
						<div class="form-group">
							<input id="birthdate" [attr.disabled]="!formEnabled ? 'disabled' : null" type="date"
								[ngClass]="{'is-invalid': form.get('birthdate')?.errors}" formControlName='birthdate'>

							<!-- Workaround for input date controls -->
							<span *ngIf="!form.get('birthdate')?.value" (click)="clickInput('birthdate')"
								class="placeholder">{{ "GENERAL.BIRTH_DATE"|translate }}</span>
						</div>
					</div>
					<div class="row">
						<div class="form-group" *ngIf='translated.gender && translated.man && translated.woman'>
							<app-select id="gender"
								[attributes]="{disabled: !formEnabled, placeholder: translated.gender, value: form.get('gender')?.value}"
								[options]="{man: translated.man!, woman: translated.woman!}"
								(valueChange)="setGender($event)">
							</app-select>
						</div>
						<div class="form-group">
							<input [attr.disabled]="!formEnabled ? 'disabled' : null" type="text"
								[ngClass]="{'is-invalid': form.get('height')?.errors}" formControlName='height'
								placeholder='{{ "MY_PROFILE.HEIGHT"|translate }}'>
							<span class="unit" [attr.disabled]="!formEnabled ? 'disabled' : null">
								{{ gym?.measurement_system?.length }}
							</span>
						</div>
						<div class="form-group">
							<input [attr.disabled]="!formEnabled ? 'disabled' : null" type="text"
								[ngClass]="{'is-invalid': form.get('weight')?.errors}" formControlName='weight'
								placeholder='{{ "MY_PROFILE.WEIGHT"|translate }}'>
							<span class="unit" [attr.disabled]="!formEnabled ? 'disabled' : null">
								{{ gym?.measurement_system?.weight }}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-title">
					<h4>{{ "MY_PROFILE.CONTACTS"|translate }}</h4>
					<figure [ngClass]="{active: formEnabled}" class="icon" (click)="toggleEnabler()">
						<i class="bi bi-pencil"></i>
					</figure>
				</div>
				<div class="card-body">
					<div class="row phone">
						<div class="form-group">
							<input [ngClass]="{'is-invalid': form.get('phone')?.errors}"
								[attr.disabled]="!formEnabled ? 'disabled' : null" type="text" #phone
								formControlName='phone' placeholder='{{ "GENERAL.PHONE"|translate }}'>
						</div>
						<div class="form-group">
							<input [ngClass]="{'is-invalid': form.get('address')?.errors}"
								[attr.disabled]="!formEnabled ? 'disabled' : null" type="text" formControlName='address'
								placeholder='{{ "GENERAL.ADDRESS"|translate }}'>
						</div>
					</div>
					<div class="row">
						<div class="form-group">
							<input [ngClass]="{'is-invalid': form.get('city')?.errors}"
								[attr.disabled]="!formEnabled ? 'disabled' : null" type="text" formControlName='city'
								placeholder='{{ "GENERAL.CITY"|translate }}'>
						</div>
						<div class="form-group">
							<input [ngClass]="{'is-invalid': form.get('postal_code')?.errors}"
								[attr.disabled]="!formEnabled ? 'disabled' : null" type="text"
								formControlName='postal_code' placeholder='{{ "GENERAL.POSTAL_CODE"|translate }}'>
						</div>
						<div class="form-group">
							<input [ngClass]="{'is-invalid': form.get('country')?.errors}"
								[attr.disabled]="!formEnabled ? 'disabled' : null" type="text" formControlName='country'
								placeholder='{{ "GENERAL.COUNTRY"|translate }}'>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-title">
					<h4>{{ 'LOG_IN'|translate }}</h4>
					<figure [ngClass]="{active: formEnabled}" class="icon" (click)="toggleEnabler()">
						<i class="bi bi-pencil"></i>
					</figure>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="form-group">
							<input type="email" [attr.disabled]="!formEnabled ? 'disabled' : null"
								[ngClass]="{'is-invalid': form.get('email')?.errors}" formControlName='email'
								placeholder="Email" autocomplete="email">
							<span class="error">{{ form.get('email')?.hasError('email-taken') ?
								("FORM_ERRORS.EMAIL_TAKEN"|translate) : '' }}</span>
						</div>
					</div>
					<div class="row">
						<div class="form-group">
							<input type="password" [attr.disabled]="!formEnabled ? 'disabled' : null"
								[ngClass]="{'is-invalid': form.get('password')?.errors}" formControlName='password'
								placeholder="Password">
							<span class="error">{{ form.get('password')?.hasError('password-length') ?
								("FORM_ERRORS.PASSWORD_LENGTH"|translate) : '' }}</span>
						</div>
						<div class="form-group">
							<input type="password" [attr.disabled]="!formEnabled ? 'disabled' : null"
								[ngClass]="{'is-invalid': form.get('password_confirmation')?.errors}"
								formControlName='password_confirmation'
								placeholder='{{ "GENERAL.CONFIRM_PASSWORD"|translate }}'>
							<span class="error">{{ form.get('password_confirmation')?.hasError('password-mismatch') ?
								("FORM_ERRORS.PASSWORD_MISMATCH"|translate) : '' }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-title">
					<h4>{{ 'GENERAL.LANGUAGE'|translate }}</h4>
					<figure [ngClass]="{active: formEnabled}" class="icon" (click)="toggleEnabler()">
						<i class="bi bi-pencil"></i>
					</figure>
				</div>
				<div class="card-body">
					<div class="row" *ngIf='translated.language && languages_available'>
						<app-select id="language" class="form-group"
							[attributes]="{disabled: !formEnabled, placeholder: translated.language, value: form.get('language')?.value}"
							[options]="languages_available" (valueChange)="setLanguage($event)"></app-select>
					</div>
				</div>
			</div>

			<!-- ! Doesn't work with google when pubblished -->
			<!-- <div class="card">
				<div class="card-title">
					<h4>Account esterni</h4>
				</div>
				<div class="card-body">
					<button type="button" class="button" (click)="toggleGoogleAssociation()">
						<img src="assets/img/png/logo-google.png">
						{{ user.is_google_associated ? 'Disassocia' : 'Associa'}}
					</button>
					<button type="button" class="button" (click)="toggleAppleAssociation()">
						<img src="assets/img/png/logo-apple.png">
						{{ user.is_apple_associated ? 'Disassocia' : 'Associa'}}
					</button>
				</div>
			</div> -->

			<app-floating-button *ngIf="formEnabled" type="submit">
				{{ 'GENERAL.SAVE'|translate }}
			</app-floating-button>

			<app-floating-button *ngIf="!formEnabled" (click)="logout()">
				{{ 'LOGOUT'|translate }}
			</app-floating-button>
		</form>
	</div>

	<!-- Date dialog for the medic certificate upload -->
	<app-date-dialog #dateDialog></app-date-dialog>

</main>