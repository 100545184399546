import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Checkin } from 'src/app/interfaces/checkin';
import { Errors } from 'src/app/enums/errors.enum';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-checkins',
	templateUrl: './checkins.component.html',
	styleUrls: ['./checkins.component.scss']
})
export class CheckinsComponent implements OnInit {

	checkins: Checkin[] = [];

	constructor(
		private checkinService: CheckinService,
		public routeService: RouteService,
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Get the main workout of the day (crossfit only)
		 */
		this.loader.show();
		this.checkinService.list()
			.then((checkins: Checkin[]) => {
				this.loader.hide();

				/**
				 * Set the checkins
				 */
				this.checkins = checkins.map((checkin: Checkin) => {
					checkin.lesson!.date = moment(checkin.lesson!.date, 'YYYY-MM-DD');
					return checkin;
				});
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ModelNotFound: break;
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

}
