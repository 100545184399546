import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimPipe } from 'src/app/pipes/trim.pipe';
import { ObjectToArrayPipe } from 'src/app/pipes/object-to-array.pipe';
import { DecimalPipe } from 'src/app/pipes/decimal.pipe';

const pipes = [
	TrimPipe,
	ObjectToArrayPipe,
	DecimalPipe,
]

@NgModule({
	declarations: pipes,
	imports: [
		CommonModule
	],
	exports: pipes
})
export class PipesModule { }
