<main class="gym" *ngIf="gym">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		Dettagli Box
	</div>

	<div class="list-group intro">
		<div class="list-group-item">
			<div class="item-left">
				<div class="avatar">
					<img [src]="gym?.logo_url" alt="">
				</div>
			</div>
			<div class="item-main">
				<h3 class="name">{{ gym.name }}</h3>
				<p class="address">{{ gym.city }} - {{ gym.address }}</p>
			</div>
		</div>
	</div>

	<div class="card">
		<div class="card-title">
			<h4>Dati del box</h4>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="form-group">
					<input [attr.disabled]="true" type="text" [value]="gym.name"
						placeholder='{{ "GENERAL.NAME"|translate }}'>
				</div>
				<div class="form-group">
					<input [attr.disabled]="true" type="text" [value]="gym.city" placeholder='Città'>
				</div>
			</div>
			<div class="row">
				<div class="form-group">
					<input [attr.disabled]="true" type="text" [value]="gym.address" placeholder='Address'>
				</div>
				<div class="form-group">
					<input [attr.disabled]="true" type="text" [value]="gym.country" placeholder='Nazione'>
				</div>
			</div>
			<div class="row">
				<div class="form-group">
					<input [attr.disabled]="true" type="text" [value]="gym.code" placeholder='Codice'>
				</div>
				<div class="form-group">
					<input [attr.disabled]="true" type="text" [value]="gym.email" placeholder='Email'>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<div class="card-title">
			<h4>I nostri corsi</h4>
		</div>
		<div class="card-body">
			<ul class="tags">
				<li class="tag" *ngFor="let discipline of gym.disciplines">
					{{ discipline.name }}
				</li>
			</ul>
		</div>
	</div>

	<div class="card">
		<div class="card-title">
			<h4>I nostri coach</h4>
		</div>
		<div class="card-body">
			<ul class="coaches">
				<li class="coach" *ngFor="let coach of gym.coaches">
					<div class="avatar">
						<img [src]="coach.avatar_url" alt="">
					</div>
					<h4 class="name">{{ coach.name }} {{ coach.surname }}</h4>
				</li>
			</ul>
		</div>
	</div>
</main>