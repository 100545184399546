import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { HttpService, Requests } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-code',
	templateUrl: './code.component.html',
	styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {

	codeControl = new FormControl('');

	constructor(
		private http: HttpService,
		private loader: LoaderService,
		private toast: ToastService,
		private router: Router,
		private translate: TranslateService,
		private localStorageService: LocalStorageService
	) { }

	ngOnInit(): void { }

	submit() {
		this.loader.show();
		this.http.send(Requests.joinGym, {
			body: {
				code: this.codeControl.value
			}
		})
			.then((result: {lang: string, gym_code: string}) => {
				this.loader.hide();

				/**
				 * Do what the login couldn't
				 */
				this.localStorageService.setItems([

					/**
					 * Save the lang
					 */
					{ key: LocalStorageTypes.Language, value: result.lang },

					/**
					 * Save the gym code
					 */
					{ key: LocalStorageTypes.GymCode, value: result.gym_code }
				]);

				/**
				 * Redirect dashboard
				 */
				this.router.navigate(['/', 'dashboard']);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.UserNotInGym: break;
					case Errors.GymNotFound:
						this.toast.error('Box non trovato, riprova con un codice diverso');
						break;
					case Errors.Validation:
						this.toast.error('Errore di validazione, compila i campi nel form corretamente');
						this.codeControl.setErrors({ required: true });
						break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
