import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxGlobalEventsService } from 'ngx-global-events';
import { Errors } from 'src/app/enums/errors.enum';
import { GlobalEvents } from 'src/app/enums/global-events.enum';
import { Gym } from 'src/app/interfaces/gym';
import { Notification } from 'src/app/interfaces/notification';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-inbox',
	templateUrl: './inbox.component.html',
	styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {

	notifications: Notification[] = [];

	gym?: Gym;

	page = 1;

	hasMore = false;

	constructor(
		private notificationsService: NotificationsService,
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService,
		private globalEventsService: NgxGlobalEventsService
	) { }

	ngOnInit(): void {
		this.fetchPaginatedNotifications();
	}

	/**
	 * Get paginated notifications
	 */
	fetchPaginatedNotifications() {
		this.loader.show();
		this.notificationsService.getPaginatedList(this.page)
			.then(paginator => {
				this.loader.hide();

				/**
				 * Append the notifications
				 */
				this.notifications.push(...paginator.data);

				/**
				 * Set has more to show or hide the load more button
				 */
				this.hasMore = paginator.has_more;

				/**
				 * Request a refresh for the notifications in the header
				 */
				this.globalEventsService.get(GlobalEvents.RequestRefreshNewNotifications).emit();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Load next page of notifications
	 */
	loadMore() {
		this.page++;
		this.fetchPaginatedNotifications();
	}
}
