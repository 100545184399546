import { Component, OnInit } from '@angular/core';
import { GeneralTexts } from 'src/app/enums/general-texts.enum';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { TextsService } from 'src/app/services/texts/texts.service';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.component.html',
	styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

	/**
	 * Privacy text
	 */
	pageText?: string;

	constructor(
		private textsService: TextsService,
		private loaderService: LoaderService,
		private languageService: LanguageService
	) { }

	ngOnInit(): void {

		/**
		 * Get text
		 */
		this.loaderService.show();
		this.textsService.get(GeneralTexts.PrivacyPolicy, this.languageService.getLanguage()).then((val) => {
			this.pageText = val.text;
		}).finally(() => this.loaderService.hide());
	}
}
