<main class="forgot-password" appBackgroundImage="assets/img/png/background.png">
	<section class="content">
		<figure class="logo-container">
			<img src="assets/img/png/logo.png" alt="">
		</figure>

		<div class="forms-container">
			<div class="form">
				<form action="" [formGroup]="form" (submit)='recoverPassword()'>
					<div class="form-row">
						<p>
							{{ "FORGOT_PASSWORD.FIRST_PHRASE"|translate }}<br>
							{{ "FORGOT_PASSWORD.SECOND_PHRASE"|translate }}
						</p>
					</div>
					<div class="form-row">
						<input type="email" [ngClass]="{'is-invalid': form.get('email')?.errors}" formControlName='email' placeholder="Email">
					</div>
					<div class="form-row">
						<input type="submit" value='{{ "GENERAL.REQUEST_LINK"|translate }}' class="button">
					</div>
					<div class="form-row">
						<p [routerLink]="[ '/login' ]">{{ "GENERAL.GO_BACK"|translate }}</p>
					</div>
				</form>
			</div>
		</div>
	</section>
</main>
