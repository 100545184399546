import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Errors } from 'src/app/enums/errors.enum';
import { Checkin } from 'src/app/interfaces/checkin';
import { Subscription } from 'src/app/interfaces/subscription';
import { UserSubscription } from 'src/app/interfaces/user-subscription';
import { HttpService, Requests } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swiper from 'swiper';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	/**
	 * Upcoming checkins
	 */
	checkins?: Checkin[];

	/**
	 * Next checkin
	 */
	checkin?: Checkin;

	/**
	 * Active user subscription
	 */
	userSubscription?: UserSubscription;

	constructor(
		private http: HttpService,
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Get checkins
		 */
		this.loader.show();
		this.http.send(Requests.upcomingCheckins)
			.then((checkins: Checkin[]) => {

				/**
				 * Set the checkins
				 */
				this.checkins = checkins.map((checkin: Checkin) => {
					checkin.lesson!.date = moment(checkin.lesson!.date, 'YYYY-MM-DD');
					return checkin;
				});

				setTimeout(() => {

					/**
					 * Init swiper
					 */
					new Swiper('.checkins-swiper', {
						slidesPerView: 'auto'
					});

					this.loader.hide();

				}, 500);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

		/**
		 * Get next checkin
		 */
		this.loader.show();
		this.http.send(Requests.upcomingCheckin)
			.then((checkin: Checkin) => {

				/**
				 * Set the checkin
				 */
				this.checkin = checkin;
				this.checkin.lesson!.date = moment(checkin.lesson!.date, 'YYYY-MM-DD');

				this.loader.hide();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ModelNotFound: break;
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

		/**
		 * Get active user subscription
		 */
		this.loader.show();
		this.http.send(Requests.activeSubscription)
			.then((userSubscription: UserSubscription) => {

				/**
				 * Set the user subscription
				 */
				this.userSubscription = userSubscription;

				this.loader.hide();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
