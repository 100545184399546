import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Gym } from 'src/app/interfaces/gym';
import { HttpService, Requests } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-gym',
	templateUrl: './gym.component.html',
	styleUrls: ['./gym.component.scss']
})
export class GymComponent implements OnInit {

	gym?: Gym;

	constructor(
		private http: HttpService,
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService,
		public routeService: RouteService
	) { }

	ngOnInit(): void {

		/**
		 * Get gym
		 */
		this.loader.show();
		this.http.send(Requests.getGymPreview)
			.then((gym: Gym) => {
				this.loader.hide();
				this.gym = gym;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
