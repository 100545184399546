<main class="inbox">

	<div class="row-title">
		{{ 'NOTIFICHE'|translate }}
	</div>

	<div class="card" *ngFor='let notification of notifications'>
		<div class="card-body">
			<span class="status">
				{{ notification.seen ? '' : 'NEW!' }}
			</span>
			<div class="head">
				<figure class="logo">
					<img [src]="notification.gym?.logo_url" alt="">
				</figure>
				<div class="info">
					<h2 class="name">
						{{ notification.gym?.name }}
					</h2>
					<div class="date">
						<span class="text">
							{{ notification.date }}
						</span>
					</div>
				</div>
			</div>

			<div class="description">
				<strong class="title">
					{{ notification.title }}
				</strong>
				<p class="message">
					{{ notification.message }}
				</p>
			</div>
		</div>
	</div>

	<button class="button" type="button" *ngIf="hasMore" (click)="loadMore()">
		Carica più
	</button>
</main>