import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSubscription } from 'src/app/interfaces/user-subscription';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class UserSubscriptionsService {
	constructor(
		private http: HttpService
	) { }

	/**
	 * List of the user subscriptions
	 */
	list(): Promise<UserSubscription[]> {
		return new Promise((resolve: (userSubscriptions: UserSubscription[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getUserSubscriptions)
				.then((userSubscriptions: UserSubscription[]) => resolve(userSubscriptions))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get the user subscription
	 */
	get(id: string): Promise<UserSubscription> {
		return new Promise((resolve: (userSubscription: UserSubscription) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getUserSubscription, { urlParams: { id } })
				.then((userSubscription: UserSubscription) => resolve(userSubscription))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get the active user subscription
	 */
	active(): Promise<UserSubscription> {
		return new Promise((resolve: (userSubscription: UserSubscription) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.activeSubscription)
				.then((userSubscription: UserSubscription) => resolve(userSubscription))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Download the invoice of the sale
	 */
	downloadInvoice(id: string): Promise<{ url: string; name: string }> {
		return new Promise((resolve: (file: { url: string; name: string }) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.downloadInvoiceFromUserSubscription, { urlParams: { id } })
				.then((file: { url: string; name: string }) => resolve(file))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
