import { HttpErrorResponse } from '@angular/common/http';
import { identifierModuleUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Lesson } from 'src/app/interfaces/lesson';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class LessonsService {
	constructor(
		private http: HttpService
	) { }

	/**
	 * List of the lessons given a date
	 */
	list(date: string): Promise<Lesson[]> {
		return new Promise((resolve: (lessons: Lesson[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getLessons, {
				urlParams: {
					date
				}
			})
				.then((lessons: Lesson[]) => resolve(lessons))
				.catch((err: HttpErrorResponse) => reject(err));
		});
	}

	/**
	 * Details of a lesson given the ID
	 */
	get(id: number): Promise<Lesson> {
		return new Promise((resolve: (lesson: Lesson) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getLesson, {
				urlParams: {
					id: id.toString()
				}
			})
				.then((lesson: Lesson) => {

					/**
					 * Set the date in moment
					 */
					lesson.date = moment(lesson.date, 'YYYY-MM-DD');

					/**
					 * Call resolve callback
					 */
					resolve(lesson);
				})
				.catch((err: HttpErrorResponse) => reject(err));
		});
	}

	/**
	 * Get lesson with workout results
	 */
	getWithWorkoutResults(id: number | string): Promise<Lesson> {
		return new Promise((resolve: (lesson: Lesson) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getLessonWithWorkoutResult, { urlParams: { id: id.toString() } })
				.then((lesson: Lesson) => resolve(lesson))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Do the checkin for a given lesson
	 */
	checkin(id: number): Promise<{ was_queued: boolean }> {
		return new Promise((resolve: (result: { was_queued: boolean }) => void, reject: (error: HttpErrorResponse) => void): void => {
			this.http.send(Requests.checkin, {
				urlParams: {
					id: id.toString()
				}
			})
				.then((result: { was_queued: boolean }) => resolve(result))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Do the checkout for a given lesson
	 */
	checkout(id: number): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.checkout, {
				urlParams: {
					id: id.toString()
				}
			})
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
