import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Post } from 'src/app/interfaces/post';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-bacheca',
	templateUrl: './bacheca.component.html',
	styleUrls: ['./bacheca.component.scss']
})
export class BachecaComponent implements OnInit {

	posts: Post[] = [];

	page = 1;

	hasMore = false;

	constructor(
		private loader: LoaderService,
		private toast: ToastService,
		private postService: PostsService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.fetchPagaintedPosts();
	}

	/**
	 * Get paginted posts
	 */
	fetchPagaintedPosts() {
		this.loader.show();
		this.postService.getPaginatedList(this.page)
			.then(paginator => {
				this.loader.hide();

				/**
				 * Append the posts
				 */
				this.posts.push(...paginator.data);

				/**
				 * Set has more to show or hide the load more button
				 */
				this.hasMore = paginator.has_more;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Load next page of posts
	 */
	loadMore() {
		this.page++;
		this.fetchPagaintedPosts();
	}
}
