import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxStripeModule } from 'ngx-stripe';
import MyCustomHttpLoaderFactory from 'src/app/classes/custom-translate-loader';
import { AppComponent } from 'src/app/components/layouts/app/app.component';
import { AuthenticatedComponent } from 'src/app/components/layouts/authenticated/authenticated.component';
import { AbbonamentoDettaglioComponent } from 'src/app/components/pages/abbonamento-dettaglio/abbonamento-dettaglio.component';
import { AbbonamentoComponent } from 'src/app/components/pages/abbonamento/abbonamento.component';
import { BachecaDettaglioComponent } from 'src/app/components/pages/bacheca-dettaglio/bacheca-dettaglio.component';
import { BachecaComponent } from 'src/app/components/pages/bacheca/bacheca.component';
import { BuyComponent } from 'src/app/components/pages/buy/buy.component';
import { CheckInComponent } from 'src/app/components/pages/check-in/check-in.component';
import { CheckinsComponent } from 'src/app/components/pages/checkins/checkins.component';
import { ComingSoonComponent } from 'src/app/components/pages/coming-soon/coming-soon.component';
import { DashboardComponent } from 'src/app/components/pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from 'src/app/components/pages/forget-password/forgot-password.component';
import { GymPrivacyComponent } from 'src/app/components/pages/gym-privacy/gym-privacy.component';
import { GymComponent } from 'src/app/components/pages/gym/gym.component';
import { InboxComponent } from 'src/app/components/pages/inbox/inbox.component';
import { LessonsComponent } from 'src/app/components/pages/lessons/lessons.component';
import { LoginComponent } from 'src/app/components/pages/login/login.component';
import { MyProfileComponent } from 'src/app/components/pages/my-profile/my-profile.component';
import { PrivacyComponent } from 'src/app/components/pages/privacy/privacy.component';
import { RecordsDettaglioComponent } from 'src/app/components/pages/records-dettaglio/records-dettaglio.component';
import { RecordsEditComponent } from 'src/app/components/pages/records-edit/records-edit.component';
import { RecordsComponent } from 'src/app/components/pages/records/records.component';
import { RinnovoDetailComponent } from 'src/app/components/pages/rinnovo-detail/rinnovo-detail.component';
import { RinnovoComponent } from 'src/app/components/pages/rinnovo/rinnovo.component';
import { SignUpComponent } from 'src/app/components/pages/sign-up/sign-up.component';
import { ThankYouComponent } from 'src/app/components/pages/thank-you/thank-you.component';
import { WorkoutDetailsComponent } from 'src/app/components/pages/workout-details/workout-details.component';
import { WorkoutComponent } from 'src/app/components/pages/workout/workout.component';
import { HeaderComponent } from 'src/app/components/shared/header/header.component';
import { LoaderComponent } from 'src/app/components/shared/loader/loader.component';
import { MenuComponent } from 'src/app/components/shared/menu/menu.component';
import { MultimediaDialogComponent } from 'src/app/components/shared/multimedia-dialog/multimedia-dialog.component';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';
import { FCMInterceptor } from 'src/app/interceptors/fcm.interceptor';
import { GymInterceptor } from 'src/app/interceptors/gym.interceptor';
import { SessionInterceptor } from 'src/app/interceptors/session.interceptor';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { AppRoutingModule } from './app-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { CodeComponent } from 'src/app/components/pages/code/code.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		SignUpComponent,
		ToastComponent,
		ComingSoonComponent,
		PrivacyComponent,
		LoaderComponent,
		ForgotPasswordComponent,
		MultimediaDialogComponent,
		AuthenticatedComponent,
		MenuComponent,
		HeaderComponent,
		DashboardComponent,
		BachecaComponent,
		BachecaDettaglioComponent,
		LessonsComponent,
		LessonsComponent,
		CheckInComponent,
		WorkoutComponent,
		InboxComponent,
		MyProfileComponent,
		AbbonamentoComponent,
		AbbonamentoDettaglioComponent,
		RinnovoComponent,
		RinnovoDetailComponent,
		BuyComponent,
		BuyComponent,
		ThankYouComponent,
		RecordsComponent,
		RecordsDettaglioComponent,
		RecordsEditComponent,
		RecordsEditComponent,
		CheckinsComponent,
		WorkoutDetailsComponent,
		GymComponent,
		GymPrivacyComponent,
		ComingSoonComponent,
		CodeComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		RouterModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: MyCustomHttpLoaderFactory,
				deps: [HttpService],
			},
			defaultLanguage: 'it',
		}),
		NgxStripeModule.forRoot(environment.stripeApiKey),
		DirectivesModule,
		PipesModule,
		WidgetsModule,
		MatTabsModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GymInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: FCMInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
