import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'src/app/interfaces/subscription';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionsService {
	constructor(
		private http: HttpService,
		private translateService: TranslateService
	) { }

	/**
	 * List of the subscriptions
	 */
	list(): Promise<Subscription[]> {
		return new Promise((resolve: (subscriptions: Subscription[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getSubscriptions)
				.then((subscriptions: Subscription[]) => resolve(subscriptions))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get the subscription
	 */
	get(id: number | string): Promise<Subscription> {
		return new Promise((resolve: (subscription: Subscription) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getSubscription, {
				urlParams: {
					id: id.toString()
				}
			})
				.then((subscription: Subscription) => {

					/**
					 * Some wierd translation method i guess
					 */
					if (subscription.type && subscription.type_slug) {
						this.translateService.get('GENERAL.' + subscription.type_slug.toUpperCase()).subscribe((val) => {
							subscription.type = val;
						});
					}

					/**
					 * Call resolve callback
					 */
					resolve(subscription);
				})
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
