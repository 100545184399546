<main class="workout-details" *ngIf="lesson">
	<div class="workout-group" *ngIf="lesson.workout?.wod?.description">
		<div class="row-title">
			<figure class="back" (click)="routeService.back()">
				<i class="bi bi-chevron-left"></i>
			</figure>
			{{ lesson.discipline?.name }}
			<br>
			<small>{{ lesson.date }}</small>
		</div>

		<div class="card">
			<h4 class="card-title">
				WOD ({{ lesson.workout?.wod?.type }})
			</h4>
			<ul class="card-body">
				<li *ngFor="let exercise of (lesson.workout?.wod?.description || '').split('\n')">
					{{ exercise }}
				</li>
			</ul>
		</div>
	</div>
	<form class="form" action="" [formGroup]="form" (submit)='submit()'>

		<div class="card">
			<h4 class="card-title">
				{{ 'SCORE'|translate }}
			</h4>
			<div class="card-body">
				<div class="form-row">
					<app-select [attributes]="{placeholder: 'RX_O_SCALED'|translate, value: form.get('rx')?.value}"
						[options]="{rx: 'Rx', scaled: 'Scaled', personal: 'Personal'}"
						(valueChange)="form.get('rx')?.setValue($event)">
					</app-select>
				</div>

				<div class="form-row">
					<input type="number" [ngClass]="{'is-invalid': form.get('rounds')?.errors}" formControlName='rounds'
						placeholder="{{ 'ROUNDS'|translate }}">
					<span class="unit">
						({{ 'ROUNDS'|translate }})
					</span>
				</div>

				<div class="form-row">
					<input type="number" [ngClass]="{'is-invalid': form.get('reps')?.errors}" formControlName='reps'
						placeholder="{{ 'REPS'|translate }}">
					<span class="unit">
						({{ 'REPS'|translate }})
					</span>
				</div>

				<div class="form-row">
					<input type="text" [ngClass]="{'is-invalid': form.get('time')?.errors}" formControlName='time'
						placeholder="{{ 'GENERAL.TIME'|translate }}" #timeInput>
				</div>

				<div class="form-row">
					<input type="number" [ngClass]="{'is-invalid': form.get('distance')?.errors}"
						formControlName='distance' placeholder="{{ 'DISTANZA'|translate }}">
					<span class="unit">
						(m)
					</span>
				</div>

				<div class="form-row">
					<input type="number" [ngClass]="{'is-invalid': form.get('weight')?.errors}" formControlName='weight'
						placeholder="{{ 'MY_PROFILE.WEIGHT'|translate }}">
					<span class="unit">
						(Kg)
					</span>
				</div>

				<div class="form-row">
					<input type="number" [ngClass]="{'is-invalid': form.get('heartrate')?.errors}"
						formControlName='heartrate' placeholder="{{ 'BATTITO_CARDIACO_MEDIO'|translate }}">
					<span class="unit">
						(BPM)
					</span>
				</div>

				<div class="form-row">
					<input type="number" [ngClass]="{'is-invalid': form.get('calories')?.errors}"
						formControlName='calories' placeholder="{{ 'CALORIE_ATTIVE_TOTALI'|translate }}">
					<span class="unit">
						(KCAL)
					</span>
				</div>

				<div class="form-row">
					<textarea rows="5" [ngClass]="{'is-invalid': form.get('notes')?.errors}" formControlName='notes' placeholder="{{ 'NOTE'|translate }}"></textarea>
				</div>
			</div>
		</div>

		<app-floating-button type="submit">
			{{ "GENERAL.SAVE"|translate }}
		</app-floating-button>
	</form>
</main>