<main class="records-edit">

	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ record ? (("GENERAL.EDIT"|translate) + ' Record') : (("GENERAL.NEW"|translate)+ ' Record') }}
	</div>

	<form class="card" [formGroup]="form" (submit)='submit()'>
		<h4 class="card-title">
			{{ exercice?.name }}
		</h4>
		<div class="card-body">
			<div class="form-row">
				<input id="date" type="date" [ngClass]="{'is-invalid': form.get('date')?.errors}" formControlName='date'
					placeholder='{{ "GENERAL.DATE"|translate }}'>

				<!-- Workaround for input date controls -->
				<span *ngIf="!form.get('date')?.value" (click)="clickInput('date')" class="placeholder">{{
					"GENERAL.DATE"|translate }}</span>
			</div>
			<div class="form-row">
				<input type="text" [ngClass]="{'is-invalid': form.get('value')?.errors}" formControlName='value'
					placeholder="{{ 'RECORD'|translate }}" #valueInput>

				<span class="unit">
					{{ exercice?.measurement_unit?.abbreviation }}
				</span>
			</div>
			<div class="form-row">
				<textarea [ngClass]="{'is-invalid': form.get('notes')?.errors}" formControlName='notes'
					placeholder='{{ "GENERAL.NOTES"|translate }}' rows="10"></textarea>
			</div>
		</div>
	</form>

	<app-floating-button (click)='submit()'>
		{{ "GENERAL.SAVE"|translate }}
	</app-floating-button>
</main>