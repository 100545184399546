import { Component, OnInit, ElementRef } from '@angular/core';
import { EventEmitter } from 'events';
import { FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-date-dialog',
	templateUrl: './date-dialog.component.html',
	styleUrls: ['./date-dialog.component.scss']
})
export class DateDialogComponent implements OnInit {

	/**
	 * Value of the date input
	 */
	date = new FormControl(null, [Validators.required]);

	/**
	 * DOM controller
	 */
	dom = {
		show: false,
		zindex: false
	};

	/**
	 * Event emitter for the dialog events
	 */
	private event = new EventEmitter();

	constructor(
		private el: ElementRef,
		private toast: ToastService
	) { }

	ngOnInit(): void {
		this.el.nativeElement.addEventListener('click', (event: any) => {

			/**
			 * If it's not shown
			 * don't bother
			 */
			if (!this.dom.show) { return; }

			/**
			 * See if he clicked outside the content
			 */
			const $content = $(this.el.nativeElement).find('.content');
			if (!$content.is(event.target) && $content.has(event.target).length === 0) {

				/**
				 * Close the modal
				 */
				this.cancel();
			}
		});
	}

	/**
	 * Shows dialog and await for a response
	 */
	show(): Promise<string | null> {

		/**
		 * Show dialog
		 */
		this.dom.show = true;
		this.dom.zindex = true;

		return new Promise((resolve: (date: string | null) => void, reject: () => void) => {

			/**
			 * On form submit
			 */
			this.event.addListener('submit', (date: string) => {
				resolve(date);
			});

			/**
			 * On form exit
			 */
			this.event.addListener('cancel', () => {
				resolve(null);
			});
		});
	};

	/**
	 * On form submit
	 */
	submit(event: any): void {

		event.preventDefault();

		/**
		 * Don't submit
		 */
		if (this.date.invalid) { return; }

		/**
		 * Hide dialog
		 */
		this.close();

		/**
		 * Warn
		 */
		this.event.emit('submit', this.date.value);

		/**
		 * Reset form
		 */
		this.date.reset();
	};

	/**
	 * On form cancel
	 */
	cancel(): void {

		/**
		 * Hide dialog
		 */
		this.close();

		/**
		 * Warn
		 */
		this.event.emit('cancel');
	};

	/**
	 * Close the modal
	 */
	private close(): void {
		this.dom.show = false;
		setTimeout(() => {
			this.dom.zindex = false;
		}, 300);
	}
}
