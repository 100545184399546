<nav class="menu">
	<ul class="float">
		<li [routerLink]="['/', 'bacheca']" [ngClass]="{active: router.url.includes('bacheca') }">
			<figure class="icon">
				<i class="icon-news"></i>
			</figure>
			<h2 class="name">
				{{ 'BACHECA'|translate }}
			</h2>
		</li>
		<li [routerLink]="['/', 'profile']" [ngClass]="{active: router.url.includes('profile') }">
			<figure class="icon">
				<i class="icon-user"></i>
			</figure>
			<h2 class="name">
				{{ "GENERAL.PROFILE"|translate }}
			</h2>
		</li>
		<li [routerLink]="['/', 'lessons']" [ngClass]="{active: router.url.includes('lessons') || router.url.includes('checkin') }">
			<figure class="icon">
				<i class="icon-dumbell"></i>
			</figure>
			<h2 class="name">
				{{ 'LEZIONI'|translate }}
			</h2>
		</li>
		<li [routerLink]="['/', 'records']" [ngClass]="{active: router.url.includes('records') }">
			<figure class="icon">
				<i class="icon-medal"></i>
			</figure>
			<h2 class="name">
				{{ 'RECORDS_TITLE'|translate }}
			</h2>
		</li>
		<li [routerLink]="['/', 'abbonamento']" [ngClass]="{active: router.url.includes('abbonamento') || router.url.includes('rinnovo') || router.url.includes('thank-you') || router.url.includes('buy') }">
			<figure class="icon">
				<i class="icon-chart"></i>
			</figure>
			<h2 class="name">
				{{ "GENERAL.SUBSCRIPTION"|translate }}
			</h2>
		</li>
	</ul>
</nav>