import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'objectToArray',
	pure: false
})
export class ObjectToArrayPipe implements PipeTransform {
	transform(object: any): { key: string; value: any }[] {
		return Object.keys(object).map((key: string) => ({
			key,
			value: object[key]
		}));
	}
}
