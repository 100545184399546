import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class WorkoutResultsService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * Create workout result
	 */
	create(formData: FormData): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.createWorkoutResult, { body: formData })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Update workout result
	 */
	update(formData: FormData): Promise<void> {
		return new Promise<void>((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.updateWorkoutResult, { body: formData, urlParams: { id: formData.get('id')?.toString() || '' } })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
