<main class="bacheca">
	<div class="posts">
		<div class="card" *ngFor="let post of posts;" [routerLink]="['/', 'bacheca', post.id]">
			<div class="card-body">
				<div class="head">
					<figure class="logo">
						<img [src]="post?.gym?.logo_url" alt="">
					</figure>
					<div class="info">
						<h2 class="name">
							{{ post?.gym?.name }}
						</h2>
						<div class="date">
							<span class="text">
								{{ post.date }}
							</span>
						</div>
					</div>
				</div>

				<figure class="image" *ngIf="post.image_url">
					<img [src]="post.image_url" alt="">
				</figure>

				<h4 class="title">
					{{ post.title }}
				</h4>
				<p class="description">
					{{ post.description }}
				</p>

				<span class="comments">
					<i class="bi bi-chat"></i>
					{{ post.comments_count }}
				</span>
			</div>
		</div>

		<button class="button" type="button" *ngIf="hasMore" (click)="loadMore()">
			Carica più
		</button>
	</div>
</main>