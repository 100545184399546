import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackgroundImageDirective } from 'src/app/directives/background-image.directive';

const directives = [
	BackgroundImageDirective
]

@NgModule({
	declarations: directives,
	imports: [
		CommonModule
	],
	exports: directives
})
export class DirectivesModule { }
