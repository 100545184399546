import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppService } from 'src/app/services/app/app.service';
import { Interceptors, InterceptorsService } from 'src/app/services/interceptors/interceptors.service';
declare const window: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	constructor(
		private router: Router,
		private appService: AppService,
		private interceptorsService: InterceptorsService
	) { }

	ngOnInit(): void {

		/**
		 * Init App features
		 */
		this.appService.init();

		/**
		 * On page change
		 */
		this.router.events.pipe(filter(e => e instanceof NavigationStart))
			.subscribe((event: any) => {

				/**
				 * Scroll up
				 */
				window.scrollTo(0, 0);
			});

		/**
		 * Enable interceptors
		 */
		this.interceptorsService.enable([
			Interceptors.Auth
		]);
	}
}
