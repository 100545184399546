import { Injectable, isDevMode } from '@angular/core';
import { CryptingService } from '../crypting/crypting.service';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
declare const window: any;

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	/**
	 * Key Prefiexs
	 */
	private localStorageKeyPrefix = 'STORAGE-';

	/**
	 * Key length
	 */
	private localStorageKeylength = 500;

	/**
	 * Crypting Key
	 */
	// private cryptingKey = '1234567890QWERTYUIOASDHJKLZXCVBNMasdjklqwiozxcvbnm.-+è.òèòò._:;§°çP*éP';
	private cryptingKey = '24680WRYIADJLXVNadkqizcbm-èòò.:§ç*P';

	/**
	 * If we are in development mode
	 */
	private isDev = isDevMode();

	/**
	 * Our data
	 */
	private data: { [key: string]: any } = {};

	constructor(
		private cryptingService: CryptingService,
	) {

		/**
		 * Extract local storage data
		 */
		this.extract();

		/**
		 * Set function in DOM for showing real list
		 * [Only in DEV MODE]
		 */
		if (this.isDev) {
			window.listLocalStorage = () => this.data;
		}
	}

	/**
	 * Set Item in LocalStorage
	 */
	setItem(key: string, value: any): void {

		/**
		 * Save internally
		 */
		this.data[key] = value;

		/**
		 * Save
		 */
		this.encryptAndSave();
	}

	/**
	 * Set Items in LocalStorage
	 */
	setItems(data: { key: string; value: any }[]): void {

		/**
		 * Save internally
		 */
		data.forEach((el: { key: string; value: any }) => {
			this.data[el.key] = el.value;
		});

		/**
		 * Save
		 */
		this.encryptAndSave();
	}

	/**
	 * Get Item from LocalStorage
	 */
	getItem(key: LocalStorageTypes): any | null {
		return typeof this.data[key] !== 'undefined' ? this.data[key] : null;
	}

	/**
	 * Delete item from LocalStorage
	 */
	removeItem(key: string): void {

		/**
		 * Remove item internally
		 */
		if (typeof this.data[key] !== 'undefined') {
			delete this.data[key];

			/**
			 * Re-write Storage
			 */
			this.encryptAndSave();
		}
	}

	/**
	 * Delete items from LocalStorage
	 */
	removeItems(keys: LocalStorageTypes[]): void {

		/**
		 * Remove items internally
		 */
		keys.forEach((key: LocalStorageTypes) => {
			if (typeof this.data[key] !== 'undefined') {
				delete this.data[key];
			}
		});

		/**
		 * Re-write Storage
		 */
		this.encryptAndSave();
	}

	/**
	 * Clear Local Service
	 */
	clear(): void {

		/**
		 * Empty data
		 */
		this.data = {};

		/**
		 * Save
		 */
		this.encryptAndSave();
	}

	/**
	 * Save data in LocalStorage
	 */
	private encryptAndSave(): void {

		/**
		 * Remove old keys
		 */
		Object.keys(window.localStorage).forEach((key) => {

			/**
			 * Check if index is valid
			 * [if we have PREFIX in key name]
			 */
			if (key.indexOf(this.localStorageKeyPrefix) === -1) { return; }

			/**
			 * Remove key
			 */
			window.localStorage.removeItem(key);
		});

		/**
		 * Encrypt JSON
		 */
		const encrypted = this.cryptingService.crypt(JSON.stringify(this.data), this.cryptingKey);

		/**
		 * Divide in slices
		 */
		const chunks = [];
		for (let i = 0, length = encrypted.length; i < length; i += this.localStorageKeylength) {
			chunks.push(encrypted.substring(i, i + this.localStorageKeylength));
		}

		/**
		 * Clear LocalStorage
		 */
		window.localStorage.clear();

		/**
		 * Save
		 */
		chunks.forEach((value, index) => {
			window.localStorage.setItem(this.localStorageKeyPrefix + (index + 1), value);
		});
	}

	/**
	 * Extract data, decrypt and save in localService
	 */
	private extract(): void {
		const chunks: { [key: string]: any } = {};

		/**
		 * Extract only valid indexes
		 */
		Object.keys(window.localStorage).forEach((key) => {

			/**
			 * Check if index is valid
			 * [if we have PREFIX in key name]
			 */
			if (key.indexOf(this.localStorageKeyPrefix) === -1) { return; }

			/**
			 * Get index
			 */
			const index = key.substring(key.indexOf('-') + 1);

			/**
			 * Save chunk
			 */
			chunks[index] = window.localStorage.getItem(key);
		});

		/**
		 * Reorder chunks by key and re-create initial encrypted string
		 */
		let encrypted = '';
		Object.keys(chunks).sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1).forEach((key) => encrypted += chunks[key]);

		/**
		 * Decrypt String
		 */
		const decrypted = this.cryptingService.decrypt(encrypted, this.cryptingKey);

		/**
		 * Re-create JSON
		 */
		if (decrypted) {
			this.data = JSON.parse(decrypted);
		}
	}
}
