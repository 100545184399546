/* eslint-disable guard-for-in */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { AppService } from 'src/app/services/app/app.service';
import { HttpService, Requests } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	/**
	 * Form
	 */
	form = this.fb.group({
		email: null,
		password: null
	});

	isBranding = !!environment.gymCode;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toast: ToastService,
		private translate: TranslateService,
		private loader: LoaderService,
		private appService: AppService,
		private http: HttpService
	) { }

	ngOnInit(): void {

		/**
		 * Redirect if authenticated
		 */
		if (this.userService.isAuthenticated()) {
			this.router.navigateByUrl('/dashboard');
		}
	}

	/**
	 * Sign in with google
	 */
	signInWithGoogle(): void {
		this.appService.signInWithGoogle()
			.then((idToken: string | null) => {

				/**
				 * If user didn't abort the sign in
				 */
				if (idToken !== null) {
					this.login({ idToken });
				}
				if (idToken !== null) {
					this.login({ idToken });
				}
			})
			.catch((error: HttpErrorResponse) => {

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken:
					case Errors.LackGymCode:
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						break;
				}
			});
	}

	/**
	 * Sign in with apple
	 */
	signInWithApple(): void {
		this.appService.signInWithApple()
			.then((idToken: string | null) => {

				/**
				 * If user didn't abort the sign in
				 */
				if (idToken !== null) {
					this.login({ idToken });
				}
			})
			.catch((error: HttpErrorResponse) => {

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken:
					case Errors.LackGymCode:
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						break;
				}
			});
	}

	/**
	 * Login
	 */
	login(data: { [key: string]: string }): void {

		/**
		 * If it's a "bradizzata"
		 */
		if (this.isBranding) {
			data = { ...data, gym_code: environment.gymCode! }
		}

		this.loader.show();
		this.userService.login(data)
			.then(() => {
				this.loader.hide();
				this.router.navigateByUrl('/dashboard');
			}).catch((error: HttpErrorResponse) => {
				this.loader.hide();
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.UserNotInGym: break;
					case Errors.LackPermission:
						this.toast.error(this.translate.instant('ERRORS.NO_PERMISSIONS'));
						break;
					case Errors.AccountNotAssociated:
						this.toast.error(this.translate.instant('QUESTO_ACCOUNT_NON_ASSOCIATO_ENTRA_NEL_TUO_ACCOUNT_E_ASSOCIA_IL_TUO_PROFILO'));
						this.appService.signOut();
						break;
					case Errors.ModelNotFound:
						this.toast.error(this.translate.instant('ERRORS.LOGIN'));
						break;
					case Errors.GymNotFound:

						/**
						 * Redirect to code page if it's not a branding
						 */
						if (this.isBranding) {
							this.toast.error(this.translate.instant('ERRORS.LOGIN'));
						} else {
							this.router.navigate(['/', 'code']);
						}
						break;
					case Errors.Validation:
						this.toast.error(this.translate.instant('FORGOT_PASSWORD.VALIDATION_ERROR'));
						for (const key in error.error.validation) {
							this.form.get(key)?.setErrors({
								required: true
							});
						}
						break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
