<main class="login" [appBackgroundImage]="'assets/img/png/background.png'">
	<section class="content">
		<figure class="logo-container">
			<img src="assets/img/png/logo.png" alt="">
		</figure>

		<div class="forms-container">
			<div class="tabs">
				<ul>
					<li class='active'>{{ 'LOGIN_TITLE'|translate }}</li>
					<li [routerLink]="[ '/sign-up' ]">{{ "GENERAL.SIGN-UP"|translate }}</li>
				</ul>
			</div>

			<div class="form">
				<form action="" [formGroup]="form" (submit)='login(form.value)'>
					<div class="form-row">
						<input type="email" [ngClass]="{'is-invalid': form.get('email')?.errors}"
							formControlName='email' placeholder="{{ 'EMAIL'|translate }}">
					</div>
					<div class="form-row">
						<input type="password" [ngClass]="{'is-invalid': form.get('password')?.errors}"
							formControlName='password' placeholder="{{ 'PASSWORD'|translate }}">
					</div>
					<div class="form-row">
						<input type="submit" value="{{ 'SIGN_IN'|translate }}" class="button">
					</div>
					<div class="form-row">
						<p [routerLink]="[ '/forgot-password' ]">{{ "LOGIN.FORGOT_PASSWORD"|translate }}?</p>
					</div>
				</form>
			</div>
		</div>

		<!-- ! Doesn't work with google when pubblished -->
		<!-- <div class="firebase-login">
			<button type="button" class="button" (click)="signInWithGoogle()">
				<img src="assets/img/png/logo-google.png">
				Entra con google
			</button>
			<button type="button" class="button" (click)="signInWithApple()">
				<img src="assets/img/png/logo-apple.png">
				Entra con apple
			</button>
		</div> -->
	</section>
</main>