<!-- Page -->
<router-outlet></router-outlet>

<!-- Toast -->
<app-toast></app-toast>

<!-- Multimedia chooser -->
<app-multimedia-dialog></app-multimedia-dialog>

<!-- Loader -->
<app-loader></app-loader>