<main class="coming-soon">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ title }}
	</div>
	<h1>
		{{ 'COMING_SOON'|translate }}
	</h1>
</main>
