import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/services/route/route.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-coming-soon',
	templateUrl: './coming-soon.component.html',
	styleUrls: [ './coming-soon.component.scss' ]
})
export class ComingSoonComponent implements OnInit {

	title?: string;

	constructor(
		public routeService: RouteService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.title = this.route.snapshot.paramMap.get('title') || '';
	}

}
