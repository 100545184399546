<main class="abbonamento">

	<ng-container *ngIf="userSubscription || (userSubscriptions?.length || 0) > 0">
		<div class="row-title">{{ "SUBSCRIPTION.TITLE"|translate }}</div>
	
		<section class="intro" *ngIf="userSubscription">
			<figure class="background">
				<img [attr.src]="userSubscription.subscription?.image_url || 'assets/img/png/abbonamento.png'" alt="" />
			</figure>
			<div class="text-container">
				<ng-container *ngIf="userSubscription.subscription?.type === 'entries'">
					<h3>
						{{ userSubscription.subscription?.name }}
					</h3>
					<p>
						{{ userSubscription.remaining_entries }} {{ "SUBSCRIPTION.REMAINING_ENTRIES"|translate }}
					</p>
					<p>
						{{ "SUBSCRIPTION.VALID_UNTIL"|translate }} {{ userSubscription.valid_to }}
					</p>
				</ng-container>
	
				<ng-container *ngIf="userSubscription.subscription?.type === 'time'">
					<h3>
						{{ userSubscription.subscription?.name }}
					</h3>
					<p>
						{{ "SUBSCRIPTION.VALID_UNTIL"|translate }} {{ userSubscription.valid_to }}
					</p>
				</ng-container>
			</div>
		</section>
	
		<div class="card" *ngIf="!userSubscription">
			<div class="card-body">
				<span class="no-results">
					{{ 'NON_HAI_UN_ABBONAMENTO_ATTIVO'|translate }}
				</span>
			</div>
		</div>
	</ng-container>

	<section class="storico" *ngIf="((userSubscriptions?.length || 0) || 0) > 0">
		<div class="row-title">
			{{ "SUBSCRIPTION.HISTORY"|translate }}
		</div>
		<ul class="list-group" *ngIf='userSubscriptions'>
			<li class="list-group-item" *ngFor="let userSubscription of userSubscriptions"
				[routerLink]="['/','abbonamento', userSubscription.id]">
				<div class="item-left">
					<figure class="image">
						<img [attr.src]="userSubscription.subscription?.image_url || userSubscription.membership_fee?.image_url || 'assets/img/png/abbonamento.png'" alt="" />
					</figure>
				</div>
				<div class="item-main">
					<h3 class="name">
						{{ userSubscription.subscription?.name || userSubscription.membership_fee?.name || '???' }}
					</h3>
					<h4 class="date" *ngIf="userSubscription.sale?.paid_at">
						{{ "SUBSCRIPTION.BUYED"|translate }} {{ userSubscription.sale?.paid_at }}
					</h4>
					<h4 class="date" *ngIf="!userSubscription.sale?.paid_at" [ngStyle]="{color: '#ff0000'}">
						{{ 'NON_PAGATO'|translate }}
					</h4>
					<h4 class="date">
						<ng-container *ngIf="!userSubscription.sale?.paid_at">
							{{ 'PAGA_ENTRO_IL'|translate }}
						</ng-container>
						<ng-container *ngIf="userSubscription.sale?.paid_at">
							{{ "GENERAL.DEADLINE"|translate }}
						</ng-container>
						{{ userSubscription.valid_to }}
					</h4>
				</div>
				<div class="item-right">
					<i class="bi bi-search search-icon"></i>
					<h2 class="prezzo">
						{{ (userSubscription.sale?.total || 0) | decimal }} {{ gym?.currency?.symbol }}
					</h2>
				</div>
			</li>
		</ul>
	</section>

	<app-floating-button *ngIf="(userSubscriptions?.length || 0) > 0" [routerLink]="['/', 'rinnovo']">
		{{ "SUBSCRIPTION.RENEW_SUB"|translate }}
	</app-floating-button>

	<app-floating-button *ngIf="(userSubscriptions?.length || 0) <= 0 && !gym?.membership_fee?.required" [routerLink]="['/', 'rinnovo']">
		{{ "SUBSCRIPTION.BUY_FIRST_SUB"|translate }}
	</app-floating-button>

	<app-floating-button *ngIf="(userSubscriptions?.length || 0) <= 0 && gym?.membership_fee?.required" [routerLink]="['/', 'buy', 'membership-fee']">
		{{ 'GENERAL.BUY'|translate }} {{ gym?.membership_fee?.name }}
	</app-floating-button>
</main>