import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Workout } from 'src/app/interfaces/workout';
import { HttpService, Requests } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-workout',
	templateUrl: './workout.component.html',
	styleUrls: ['./workout.component.scss']
})
export class WorkoutComponent implements OnInit {

	workout?: Workout;

	/**
	 * Lesson ID
	 */
	lessonId?: string;

	constructor(
		private http: HttpService,
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService,
		private route: ActivatedRoute,
		public routeService: RouteService,
		private router: Router
	) { }

	ngOnInit(): void {

		/**
		 * Get lesson ID from params
		 */
		this.lessonId = this.route.snapshot.queryParamMap.get('lessonId') || undefined;

		const workoutId = this.route.snapshot.paramMap.get('id');
		if (!workoutId || isNaN(workoutId as unknown as number)) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
			this.router.navigate(['/']);
			return;
		}

		/**
		 * Get the main workout of the day (crossfit only)
		 */
		this.loader.show();
		this.http.send(Requests.getWorkout, {
			urlParams: {
				id: workoutId
			}
		})
			.then((workout: Workout) => {
				this.loader.hide();

				/**
				 * Set the workout
				 */
				this.workout = workout;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

}
