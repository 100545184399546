import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageTypes } from '../enums/local-storage-types.enum';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { Interceptors, InterceptorsService } from '../services/interceptors/interceptors.service';

@Injectable()
export class FCMInterceptor implements HttpInterceptor {

	constructor(
		private localStorage: LocalStorageService,
		private interceptorsService: InterceptorsService,
	) { }

	/**
	 * Update header HTTP call
	 *
	 */
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		/**
		 * If interceptor is not enabled, skip it
		 */
		if (!this.interceptorsService.isEnabled(Interceptors.FCM)) {
			return next.handle(request);
		}

		/**
		 * HttpHeader object immutable - copy values
		 */
		const headerSettings: {
			[name: string]: string | string[];
		} = {};

		/**
		 * Set keys
		 */
		for (const key of request.headers.keys()) {
			headerSettings[key] = request.headers.getAll(key) || [];
		}

		/**
		 * Set FcmToken if exist
		 */
		if (!request.url.includes('https://weblate.camacrea.it')) {
			const fcmToken = this.localStorage.getItem(LocalStorageTypes.FCMToken);
			if (fcmToken) {
				headerSettings['FCM-Token'] = fcmToken;
			}
		}

		/**
		 * Add token code to header
		 */
		const newHeader = new HttpHeaders(headerSettings);
		const req = request.clone({
			headers: newHeader
		});

		return next.handle(req);
	}
}
