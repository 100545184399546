import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

	/**
	 * Dom control variables
	 */
	show = false;
	message?: string;
	type?: string;

	constructor(private toast: ToastService) { }

	ngOnInit(): void {

		/**
		 * On body click
		 */
		$('html,body').on('click', () => {

			/**
			 * If toast is shown
			 */
			if (this.show) {

				/**
				 * Hide toast
				 */
				setTimeout(() => {
					this.show = false;
				}, 1000);
			}
		});

		/**
		 * Listen for toast service requestes
		 */
		this.toast.subscribe((next: { message: string; type: string } | null) => {

			/**
			 * IF Message exists -> set open and message
			 */
			if (next && next.message) {

				/**
				 * Show toast
				 */
				this.show = true;

				/**
				 * Set type of toast
				 */
				this.type = next.type;

				/**
				 * Set message
				 */
				this.message = next.message;

				/**
				 * Debug
				 */
				if (next.type === 'danger') {
					console.error('Toast error: ' + next.message);
				}
			} else {

				/**
				 * Hide toast
				 */
				this.show = false;
			}
		});
	}
}
