import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Comment } from 'src/app/interfaces/comment';
import { Post } from 'src/app/interfaces/post';
import { CommentService } from 'src/app/services/comment/comment.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-bacheca-dettaglio',
	templateUrl: './bacheca-dettaglio.component.html',
	styleUrls: ['./bacheca-dettaglio.component.scss']
})
export class BachecaDettaglioComponent implements OnInit {

	comment = new FormControl('', [Validators.required, Validators.maxLength(1000)]);

	post?: Post

	comments: Comment[] = [];

	constructor(
		private loader: LoaderService,
		private toast: ToastService,
		private postService: PostsService,
		private commentService: CommentService,
		private translate: TranslateService,
		private route: ActivatedRoute,
		public routeService: RouteService
	) { }

	ngOnInit(): void {

		/**
		 * Get post
		 */
		this.loader.show();
		this.postService.get(this.route.snapshot.paramMap.get('id')!)
			.then((post: Post) => {
				this.loader.hide();

				/**
				 * Set the post
				 */
				this.post = post;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

		/**
		 * Get post's comments
		 */
		this.fetchComments();
	}

	/**
	 * Attach comment to the post
	 */
	submit(): void {
		if (!this.comment.valid) { return; }
		this.loader.show();
		this.commentService.create(this.route.snapshot.paramMap.get('id')!, this.comment.value)
			.then(() => {
				this.loader.hide();
				this.comment.reset();

				/**
				 * Reload comments
				 */
				this.fetchComments();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Fetch comments
	 */
	private fetchComments(): void {
		this.loader.show();
		this.commentService.list(this.route.snapshot.paramMap.get('id')!)
			.then((comments: Comment[]) => {
				this.loader.hide();

				/**
				 * Set the comments
				 */
				this.comments = comments;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
