import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentIntentResponse } from 'src/app/interfaces/payment-intent-response';
import { HttpService, Requests } from '../http/http.service';
import { LanguageService } from '../language/language.service';

@Injectable({
	providedIn: 'root'
})
export class ProductService {
	constructor(
		private http: HttpService,
		private languageService: LanguageService
	) { }

	/**
	 * Create pre-sale
	 */
	pay(data: { [key: string]: string }): Promise<PaymentIntentResponse> {
		return new Promise((resolve: (obj: PaymentIntentResponse) => void, reject: (error: HttpErrorResponse) => void) => {

			/**
			 * Adding language
			 */
			data.lang = this.languageService.getLanguage();

			this.http.send(Requests.payProduct, { body: data })
				.then((presale: PaymentIntentResponse) => resolve(presale))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Confirm payment intent, used after 3ds confirmation
	 */
	confirmPaymentIntent(payment_intent_id: string): Promise<PaymentIntentResponse> {
		return new Promise((resolve: (obj: PaymentIntentResponse) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.confirmPaymentIntent, {
				body: {
					payment_intent_id
				}
			})
				.then((presale: PaymentIntentResponse) => resolve(presale))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
