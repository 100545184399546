<main class="rinnovo-detail" *ngIf="subscription && gym">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "GENERAL.DETAILS"|translate }} {{ "GENERAL.SUBSCRIPTION"|translate }}
	</div>

	<section class="intro">
		<figure class="background">
			<img [attr.src]="subscription?.image_url || 'assets/img/png/abbonamento.png'" alt="" />
		</figure>
		<div class="text-container">
			<h3>
				{{ subscription?.name }}
			</h3>
			<h2>
				{{ (subscription?.total || 0) | decimal }} {{ gym?.currency?.symbol }}
			</h2>
		</div>
	</section>

	<section class="details">
		<div class="card" *ngIf="subscription?.description">
			<h4 class="card-title">
				{{ "SUBSCRIPTION.DETAILS"|translate }}
			</h4>
			<div class="card-body">
				<span [innerHTML]="subscription?.description"></span>
			</div>
		</div>

		<div class="card">
			<h4 class="card-title">
				{{ "GENERAL.FEATURES"|translate }}
			</h4>
			<div class="card-body">
				{{ "SUBSCRIPTION.FEATURES_DESCRIPTION"|translate:subscription }}
				<br>
				<br>
				{{ "SUBSCRIPTION.CLASS_PARTICIPATION"|translate:{ class: subscription?.disciplines?.join(', ') } }}
			</div>
		</div>

		<div class="card" *ngIf="subscription?.terms">
			<h4 class="card-title">
				{{ "SUBSCRIPTION.CONDITIONS"|translate }}
			</h4>
			<div class="card-body">
				<span [innerHTML]="subscription?.terms"></span>
			</div>
		</div>

		<div class="card" *ngIf="subscription?.autorenewal">
			<h4 class="card-title">
				{{ 'AUTORINNOVO'|translate }}
			</h4>
			<div class="card-body">
				{{'QUESTO_ABBONAMENTO_VERR_AUTOMATICAMENTE_RINNOVATO_CON_UN_PAGAMENTO_OGNI_DURATION_GIORNI'|translate:{duration:subscription?.duration}
				}}
				<ng-container *ngIf="subscription?.installments">
					{{ 'PER_INSTALLMENTS_VOLTA_E'|translate:{installments: subscription?.installments} }}
				</ng-container>
			</div>
		</div>
	</section>

	<app-floating-button *ngIf='gym.payments_enabled' [routerLink]="['/', 'buy', 'subscription', subscription.id]">
		{{ "GENERAL.BUY"|translate }}
	</app-floating-button>
</main>