<main class="dashboard">
	<app-alert></app-alert>

	<!-- Upcoming checkin/lesson -->
	<div class="upcoming-checkin">
		<h4 class="row-title">
			{{ 'PROSSIMO_CHECK_IN'|translate }}
		</h4>
		<div class="body">
			<ng-container *ngIf="checkin">
				<ul class="list-group" [routerLink]="['/', 'lesson' , checkin.lesson?.id]">
					<li class="list-group-item">
						<div class="item-left">
							<div class="date">
								<div class="day">{{ checkin.lesson?.date?.format('DD') }}</div>
								<div class="month">{{ checkin.lesson?.date?.format('ddd') }}</div>
							</div>
						</div>
						<div class="item-main">
							<div class="info">
								<div class="activity">{{ checkin.lesson?.discipline?.name || '-' }}</div>
								<div class="name">{{ (checkin.lesson?.coach?.name && checkin.lesson?.coach?.surname) ? (checkin.lesson?.coach?.name + ' ' +
									checkin.lesson?.coach?.surname) : '-' }}</div>
								<div class="hour">
									<figure class="icon">
										<i class="bi bi-clock"></i>
									</figure>
									<span>
										{{ checkin.lesson?.start_time }} - {{ checkin.lesson?.end_time }}
									</span>
								</div>
							</div>
						</div>
						<div class="item-right">
							<div class="people">
								{{ checkin.lesson?.checkins_count }}/{{ checkin.lesson?.limit }}
							</div>
						</div>
					</li>
				</ul>
			</ng-container>
			<ng-container *ngIf="!checkin">
				<div class="card">
					<div class="card-body">
						<span
							class="no-results">{{ 'NON_TI_SEI_ANCORA_ISCRITTO_AD_UNA_LEZIONE_VAI_NELLA_SEZIONE_LEZIONI_E_INIZIA_AD_ALLENARTI'|translate }}</span>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<!-- Upcoming checkins -->
	<div class="upcoming-checkins">
		<h4 class="row-title">
			{{ 'CHECK_INS'|translate }}
		</h4>
		<div class="card">
			<ng-container *ngIf="(checkins?.length || 0) > 0">
				<div class="swiper-container card-body checkins-swiper">
					<div class="swiper-wrapper">
						<div *ngFor="let checkin of checkins" class="swiper-slide"
							[routerLink]="['/', 'lesson' , checkin.lesson?.id]">
							<div class="date">
								<div class="day">{{ checkin.lesson?.date?.format('DD') }}</div>
								<div class="month">{{ checkin.lesson?.date?.format('MMM')?.replace('.','') }}</div>
							</div>
							<div class="time">
								{{ checkin.lesson?.start_time }}
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="!checkins || checkins.length <= 0">
				<div class="card-body">
					<span
						class="no-results">{{ 'NON_TI_SEI_ANCORA_ISCRITTO_AD_UNA_LEZIONE_VAI_NELLA_SEZIONE_LEZIONI_E_INIZIA_AD_ALLENARTI'|translate }}</span>
				</div>
			</ng-container>
		</div>
	</div>

	<!-- Active subscription -->
	<div class="subscription">
		<h4 class="row-title">
			{{ 'GENERAL.SUBSCRIPTION'|translate }}
		</h4>
		<ng-container *ngIf="userSubscription">
			<div class="body" [routerLink]="['/', 'abbonamento' , userSubscription.id]">
				<figure class="background">
					<img [attr.src]="userSubscription.subscription?.image_url || 'assets/img/png/abbonamento.png'"
						alt="" />
				</figure>
				<div class="text-container">
					<h3>
						{{ userSubscription.subscription?.name }}
					</h3>
					<p *ngIf="userSubscription.subscription?.type === 'entries'">
						{{ userSubscription.remaining_entries }} {{ "SUBSCRIPTION.REMAINING_ENTRIES"|translate }}
					</p>
					<p *ngIf="userSubscription.sale?.paid_at">
						{{ "SUBSCRIPTION.VALID_UNTIL"|translate }} {{ userSubscription.valid_to }}
					</p>
					<p *ngIf="!userSubscription.sale?.paid_at">
						{{ 'PAGA_ENTRO_IL'|translate }} {{ userSubscription.sale?.payment_deadline }}
					</p>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="!userSubscription">
			<div class="card" [routerLink]="['/', 'abbonamento']">
				<div class="card-body">
					<span
						class="no-results">{{ 'NON_HAI_UN_ABBONAMENTO_ATTIVO_ACQUISTA_IL_TUO_PRIMO_ABBONAMENTO_NELLA_SEZIONE_ABBONAMENTO'|translate }}</span>
				</div>
			</div>
		</ng-container>
	</div>
</main>