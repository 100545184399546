import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from 'src/app/interfaces/notification';
import { Paginator } from 'src/app/interfaces/paginator';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {
	constructor(
		private http: HttpService
	) { }

	/**
	 * List of the notifications
	 */
	getPaginatedList(page: number) {
		return new Promise((resolve: (paginator: Paginator<Notification>) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getPaginatedNotifications, { urlParams: { page: page.toString() } })
				.then((paginator: Paginator<Notification>) => resolve(paginator))
				.catch((err: HttpErrorResponse) => reject(err));
		});
	}

	/**
	 * List of the new notifications
	 */
	new() {
		return new Promise((resolve: (notifications: Notification[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getNewNotifications)
				.then((notifications: Notification[]) => resolve(notifications))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
