import { Component, OnInit, ElementRef, EventEmitter } from '@angular/core';
import { MultimediaService } from 'src/app/services/multimedia/multimedia.service';
import { MultimediaTypes } from 'src/app/enums/multimedia-types.enum';
import { GlobalEvents } from 'src/app/enums/global-events.enum';
import { NgxGlobalEventsService } from 'ngx-global-events';

@Component({
	selector: 'app-multimedia-dialog',
	templateUrl: './multimedia-dialog.component.html',
	styleUrls: ['./multimedia-dialog.component.scss']
})
export class MultimediaDialogComponent implements OnInit {

	/**
	 * DOM controller
	 */
	dom = {
		show: false,
		zindex: false
	};

	MultimediaTypes = MultimediaTypes;

	/**
	 * Boxes shown in the dialog
	 */
	types: MultimediaTypes[] = [];

	/**
	 * Event emitter for the dialog events
	 */
	private events = {
		submit: new EventEmitter<MultimediaTypes>(),
		cancel: new EventEmitter()
	};

	constructor(
		private el: ElementRef,
		private globalEventsService: NgxGlobalEventsService
	) { }

	ngOnInit(): void {
		this.el.nativeElement.addEventListener('click', (event: any) => {

			/**
			 * If it's not shown
			 * don't bother
			 */
			if (!this.dom.show) { return; }

			/**
			 * See if he clicked outside the content
			 */
			const $content = $(this.el.nativeElement).find('.content');
			if (!$content.is(event.target) && $content.has(event.target).length === 0) {

				/**
				 * Close the modal
				 */
				this.cancel();
			}
		});

		/**
		 * Listen for media choose type requests
		 */
		this.globalEventsService.get(GlobalEvents.ChoseMediaType).subscribe((types: MultimediaTypes[]) => this.show(types));
	}

	/**
	 * Show the dialog
	 */
	show(types: MultimediaTypes[]): void {

		if (types.length <= 1) {
			this.globalEventsService.emit(GlobalEvents.MediaTypeChosen, types[0]);
		}

		/**
		 * Set the requested options
		 */
		this.types = types;

		/**
		 * Show the modal
		 */
		this.open();

		/**
		 * Submit listener
		 */
		this.events.submit.subscribe((type: MultimediaTypes) => {
			this.globalEventsService.emit(GlobalEvents.MediaTypeChosen, type);
		});

		/**
		 * Cancel listener
		 */
		this.events.cancel.subscribe(() => {
			this.globalEventsService.emit(GlobalEvents.MediaTypeChosen, null);
		});
	};

	/**
	 * On button click
	 */
	click(type: MultimediaTypes): void {

		/**
		 * Hide dialog
		 */
		this.close();

		/**
		 * Decision has been made
		 */
		this.events.submit.emit(type);
	};

	/**
	 * On cancel
	 */
	cancel(): void {

		/**
		 * Hide dialog
		 */
		this.close();

		/**
		 * Warn the user has cancelled
		 */
		this.events.cancel.emit();
	};

	/**
	 * Close the modal
	 */
	private close(): void {
		this.dom.show = false;
		setTimeout(() => {
			this.dom.zindex = false;
		}, 300);
	}

	/**
	 * Show the modal
	 */
	private open(): void {
		this.dom.show = true;
		this.dom.zindex = true;
	}
}
