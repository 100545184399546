import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedComponent } from 'src/app/components/layouts/authenticated/authenticated.component';
import { AbbonamentoDettaglioComponent } from 'src/app/components/pages/abbonamento-dettaglio/abbonamento-dettaglio.component';
import { AbbonamentoComponent } from 'src/app/components/pages/abbonamento/abbonamento.component';
import { BachecaDettaglioComponent } from 'src/app/components/pages/bacheca-dettaglio/bacheca-dettaglio.component';
import { BachecaComponent } from 'src/app/components/pages/bacheca/bacheca.component';
import { BuyComponent } from 'src/app/components/pages/buy/buy.component';
import { CheckInComponent } from 'src/app/components/pages/check-in/check-in.component';
import { CheckinsComponent } from 'src/app/components/pages/checkins/checkins.component';
import { ComingSoonComponent } from 'src/app/components/pages/coming-soon/coming-soon.component';
import { DashboardComponent } from 'src/app/components/pages/dashboard/dashboard.component';
import { GymPrivacyComponent } from 'src/app/components/pages/gym-privacy/gym-privacy.component';
import { GymComponent } from 'src/app/components/pages/gym/gym.component';
import { InboxComponent } from 'src/app/components/pages/inbox/inbox.component';
import { LessonsComponent } from 'src/app/components/pages/lessons/lessons.component';
import { MyProfileComponent } from 'src/app/components/pages/my-profile/my-profile.component';
import { RecordsDettaglioComponent } from 'src/app/components/pages/records-dettaglio/records-dettaglio.component';
import { RecordsEditComponent } from 'src/app/components/pages/records-edit/records-edit.component';
import { RecordsComponent } from 'src/app/components/pages/records/records.component';
import { RinnovoDetailComponent } from 'src/app/components/pages/rinnovo-detail/rinnovo-detail.component';
import { RinnovoComponent } from 'src/app/components/pages/rinnovo/rinnovo.component';
import { ThankYouComponent } from 'src/app/components/pages/thank-you/thank-you.component';
import { WorkoutDetailsComponent } from 'src/app/components/pages/workout-details/workout-details.component';
import { WorkoutComponent } from 'src/app/components/pages/workout/workout.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { CodeComponent } from '../../components/pages/code/code.component';
import { ForgotPasswordComponent } from '../../components/pages/forget-password/forgot-password.component';
import { LoginComponent } from '../../components/pages/login/login.component';
import { PrivacyComponent } from '../../components/pages/privacy/privacy.component';
import { SignUpComponent } from '../../components/pages/sign-up/sign-up.component';

const routes: Routes = [
	{
		/** Login */
		path: 'login',
		component: LoginComponent,
	},
	{
		/** Code */
		path: 'code',
		component: CodeComponent,
	},
	{
		/** Signup */
		path: 'sign-up',
		component: SignUpComponent,
	},
	{
		/** Forgot Password */
		path: 'forgot-password',
		component: ForgotPasswordComponent,
	},
	{
		/** Privacy */
		path: 'privacy',
		component: PrivacyComponent,
	},
	{
		path: '',
		component: AuthenticatedComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				component: DashboardComponent,
			},
			{
				path: 'bacheca',
				component: BachecaComponent,
			},
			{
				path: 'bacheca/:id',
				component: BachecaDettaglioComponent,
			},
			{
				path: 'lessons',
				component: LessonsComponent,
			},
			{
				path: 'lessons/:date',
				component: LessonsComponent,
			},
			{
				path: 'lesson/:id',
				component: CheckInComponent,
			},
			{
				path: 'workout/:id',
				component: WorkoutComponent,
			},
			{
				path: 'inbox',
				component: InboxComponent,
			},
			{
				path: 'profile',
				component: MyProfileComponent,
			},
			{
				path: 'abbonamento',
				component: AbbonamentoComponent,
			},
			{
				path: 'abbonamento/:id',
				component: AbbonamentoDettaglioComponent,
			},
			{
				path: 'rinnovo',
				component: RinnovoComponent,
			},
			{
				path: 'rinnovo/:id',
				component: RinnovoDetailComponent,
			},
			{
				path: 'buy/membership-fee',
				component: BuyComponent,
			},
			{
				path: 'buy/:type/:id',
				component: BuyComponent,
			},
			{
				path: 'thank-you',
				component: ThankYouComponent,
			},
			{
				path: 'records',
				component: RecordsComponent,
			},
			{
				path: 'records/:id',
				component: RecordsDettaglioComponent,
			},
			{
				path: 'records/:exercice/new',
				component: RecordsEditComponent,
			},
			{
				path: 'records/:exercice/update/:id',
				component: RecordsEditComponent,
			},
			{
				path: 'checkins',
				component: CheckinsComponent,
			},
			{
				path: 'workout-details/:id',
				component: WorkoutDetailsComponent,
			},
			{
				path: 'gym',
				component: GymComponent,
			},
			{
				path: 'gym-privacy',
				component: GymPrivacyComponent,
			},
			{
				path: 'coming-soon/:title',
				component: ComingSoonComponent
			},
			{
				path: '',
				pathMatch: 'full',
				redirectTo: '/dashboard'
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
