import { Component, NgZone, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

	/**
	 * Status
	 */
	show = false;

	/**
	 * Request counter
	 */
	private counter = 0;

	constructor(
		private loaderService: LoaderService,
		private ngZone: NgZone
	) {
		this.loaderService.subscribe((show: boolean) => {

			if (show) {
				this.counter++;
			} else if (!show && this.counter > 0) {
				this.counter--;
			}

			/**
			 * Show hide the loader
			 */
			this.ngZone.run(() => {
				this.show = this.counter > 0;
			});
		});
	}
}
