import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Errors } from 'src/app/enums/errors.enum';
import { Gym } from 'src/app/interfaces/gym';
import { UserSubscription } from 'src/app/interfaces/user-subscription';
import { AppService } from 'src/app/services/app/app.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserSubscriptionsService } from 'src/app/services/user-subscriptions/user-subscriptions.service';

@Component({
	selector: 'app-abbonamento-dettaglio',
	templateUrl: './abbonamento-dettaglio.component.html',
	styleUrls: ['./abbonamento-dettaglio.component.scss']
})
export class AbbonamentoDettaglioComponent implements OnInit {

	/**
	 * Subscription
	 */
	userSubscription?: UserSubscription;

	/**
	 * Expose moment js
	 */
	moment = moment;

	/**
	 * Gym
	 */
	gym?: Gym;

	constructor(
		private userSubscriptionsService: UserSubscriptionsService,
		private loader: LoaderService,
		private toast: ToastService,
		public routeService: RouteService,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private appService: AppService,
		private store: StoreService,
		private router: Router
	) { }

	ngOnInit(): void {

		const id = this.route.snapshot.paramMap.get('id');
		if (!id || isNaN(id as unknown as number)) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
			this.router.navigate(['/']);
			return;
		}

		/**
		 * Fetch gym
		 */
		this.loader.show();
		this.store.refresh(StoreKeys.Gym)
			.then((next: Gym) => {
				this.gym = next;
				this.loader.hide();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

		/**
		 * Get the user subscription
		 */
		this.loader.show();
		this.userSubscriptionsService.get(id)
			.then((userSubscription: UserSubscription) => {
				this.loader.hide();

				/**
				 * Set the user subscription
				 */
				this.userSubscription = userSubscription;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Download invoice of the sale
	 */
	doownloadInvoice(): void {
		this.loader.show();
		this.userSubscriptionsService.downloadInvoice(this.userSubscription?.id!)
			.then((file: { url: string; name: string }) => {
				this.loader.hide();

				/**
				 * Download file localy
				 */
				this.appService.download(file);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
