/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Component,
	OnInit
} from '@angular/core';
import Swiper from 'swiper';
import { HttpErrorResponse } from '@angular/common/http';
import { Expiry } from 'src/app/interfaces/expiry';
import { UserService } from 'src/app/services/user/user.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Errors } from 'src/app/enums/errors.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

	/**
	 * Output array
	 */
	expiries?: ({
		text: string;
		link: {
			page: string;
			text: string;
		};
	} | null)[];

	constructor(
		private userService: UserService,
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Get the expiries to warn the user
		 */
		this.loader.show();
		this.userService.getExpiries()
			.then((expiries: Expiry[]) => {

				/**
				 * Set the expiries
				 */
				this.expiries = expiries.map((expiry: Expiry) => {

					/**
					 * Result set
					 */
					let text: string;
					let link: {
						text: string;
						page: string;
					};

					switch (expiry.type) {

						/**
						 * Subscriptions
						 */
						case 'subscription':
							text = this.translate.instant('ALERT.SUBSCRIPTION_EXPIRING', {
								name: expiry.name,
								date: expiry.date
							});
							link = {
								text: this.translate.instant('GENERAL.RENEW_NOW'),
								page: 'abbonamento'
							};
							break;

						case 'subscription-entry':
							text = this.translate.instant('ALERT.SUBSCRIPTION_ENTRIES', {
								name: expiry.name,
								entries: expiry.entries
							});
							link = {
								text: this.translate.instant('GENERAL.RENEW_NOW'),
								page: 'abbonamento'
							};
							break;

						case 'no-subscription':
							text = this.translate.instant('ALERT.NO_SUBSCRIPTION');
							link = {
								text: this.translate.instant('GENERAL.BUY_NOW'),
								page: 'abbonamento'
							};
							break;

						/**
						 * Membership fee
						 */
						case 'no-membership-fee':
							text = this.translate.instant('ALERT.NO_MEMBERSHIP', {
								name: expiry.name,
							});
							break;

						case 'membership-fee':
							text = this.translate.instant('ALERT.MEMBERSHIP_EXPIRING', {
								name: expiry.name,
								date: expiry.date
							});
							break;

						case 'membership-fee-expired':
							text = this.translate.instant('ALERT.MEMBERSHIP_EXPIRED', {
								name: expiry.name,
								date: expiry.date
							});
							break;

						/**
						 * Medic certificate
						 */
						case 'no-medic-certificate':
							text = this.translate.instant('ALERT.NO_CERT');
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						case 'medic-certificate':
							text = this.translate.instant('ALERT.CERT_EXPIRING', {
								date: expiry.date
							});
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						case 'medic-certificate-expired':
							text = this.translate.instant('ALERT.CERT_EXPIRED', {
								date: expiry.date
							});
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						/**
						 * Privacy policy acceptance
						 */
						case 'no-privacy':
							text = this.translate.instant('ALERT.NO_PRIVACY');
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						case 'privacy':
							text = this.translate.instant('ALERT.PRIVACY_EXPIRING', {
								date: expiry.date
							});
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						case 'privacy-expired':
							text = this.translate.instant('ALERT.PRIVACY_EXPIRED', {
								date: expiry.date
							});
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						/**
						 * Green pass acceptance
						 */
						case 'no-greenpass':
							text = this.translate.instant('NON_HAI_ANCORA_CARICATO_IL_TUO_GREEN_PASS');
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						case 'greenpass':
							text = this.translate.instant('IL_TUO_GREENPASS_SCADE_IL_DATE', {
								date: expiry.date
							});
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						case 'greenpass-expired':
							text = this.translate.instant('IL_TUO_GREENPASS_SCADUTO_IL_DATE', {
								date: expiry.date
							});
							link = {
								text: this.translate.instant('GENERAL.UPLOAD_NEW'),
								page: 'profile'
							};
							break;

						default:
							return null;
					}

					return {
						text,
						link: link!
					};
				});

				setTimeout(() => {

					/**
					 * Init swiper
					 */
					new Swiper('section.alert .swiper-container', {
						direction: 'horizontal',
						slidesPerView: 1,
						pagination: {
							el: '.swiper-pagination',
						},
						scrollbar: {
							el: '.swiper-scrollbar',
						},
					});

					this.loader.hide();

				}, 500);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

}
