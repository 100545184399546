import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Text } from 'src/app/interfaces/texts';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class TextsService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * Get the subscription
	 */
	get(slug: string, lang: string): Promise<Text> {
		return new Promise((resolve: (text: Text) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getTexts, {
				urlParams: {
					slug,
					lang
				}
			})
				.then((text: Text) => resolve(text))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
