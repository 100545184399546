<main class="records-dettaglio">

	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ exercice?.name }}
	</div>

	<div class="description" *ngIf="exercice?.description">
		{{ exercice?.description }}
	</div>

	<div class="no-result" *ngIf="(exercice?.records?.length || 0) <= 0">
		<h3>
			{{ "RECORDS.NO_RECORDS"|translate }}
		</h3>
	</div>

	<ng-container *ngIf="(exercice?.records?.length || 0) > 0">

		<div class="list-group chart-container">
			<div class="list-group-item active">
				<div class="item-main">
					<canvas class="chart" id="myChart"></canvas>
				</div>
			</div>
		</div>

		<div class="list-group percentage" *ngIf="exercice?.measurement_unit?.abbreviation === 'kg'">
			<div class="list-group-item">
				<div class="item-main">
					<span class="name">
						PR %
					</span>
					<input type="number" name="" id="" [(ngModel)]="percentage">
				</div>
				<div class="item-right">
					<span class="value">{{ (max * (percentage / 100)) | number:'1.0-0' }} KG</span>
				</div>
			</div>
		</div>

		<ul class="list-group record-list">
			<li class="list-group-item" *ngFor="let record of exercice?.records"
				[routerLink]="['/', 'records', exercice?.id, 'update', record.id]">
				<div class="item-main">
					<span class="date">
						{{ record.date.format('DD MMMM YYYY') }}
					</span>
				</div>
				<div class="item-right">
					<span class="value">{{ record.value }} {{ exercice?.measurement_unit?.abbreviation }}</span>
				</div>
			</li>
		</ul>
	</ng-container>

	<app-floating-button [routerLink]="['/', 'records', exercice?.id, 'new']">
		{{ "RECORDS.ADD"|translate }}
	</app-floating-button>
</main>