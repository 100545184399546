<main class="check-in" *ngIf="lesson">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "GENERAL.CHECK-IN"|translate }}
	</div>

	<ul class="list-group lesson">
		<li class="list-group-item">
			<div class="item-left">
				<div class="date">
					<div class="day">{{ lesson?.date?.format('DD') }}</div>
					<div class="month">{{ lesson?.date?.format('ddd') }}</div>
				</div>
			</div>
			<div class="item-main">
				<div class="info">
					<div class="activity">{{ lesson?.discipline?.name || '-' }}</div>
					<div class="name">{{ (lesson?.coach?.name && lesson?.coach?.surname) ? (lesson?.coach?.name + ' ' +
						lesson?.coach?.surname) : '-' }}</div>
					<div class="hour">
						<figure class="icon">
							<i class="bi bi-clock"></i>
						</figure>
						<span>
							{{ lesson?.start_time }} - {{ lesson?.end_time }}
						</span>
					</div>
				</div>
			</div>
			<div class="item-right">
				<div class="people">
					{{ lesson?.checkins_count }}/{{ lesson?.limit }}
				</div>
			</div>
		</li>
	</ul>

	<ng-container *ngIf="lesson.workout">
		<ul class="list-group workout" [routerLink]="['/', 'workout', lesson.workout.id]" [queryParams]="{lessonId: lesson.id}">
			<li class="list-group-item">
				<div class="item-left">
					<figure class="icon">
						<i class="icon-dumbell"></i>
					</figure>
				</div>
				<div class="item-main">
					<h2>{{ 'WORKOUT'|translate }}</h2>
				</div>
				<div class="item-right">
					<figure class="icon" [routerLink]="['/','workout']">
						<i class="bi bi-chevron-right"></i>
					</figure>
				</div>
			</li>
		</ul>
	</ng-container>

	<ng-container *ngIf="(lesson?.athletes?.length || 0) > 0">
		<h4 class="row-title">
			{{ 'PARTECIPANTI'|translate }}
		</h4>
		<ul class="list-group athletes">
			<li class="list-group-item" [ngClass]="{'active': athlete.is_me}" *ngFor='let athlete of lesson?.athletes'>
				<div class="item-left">
					<div class="avatar">
						<img [src]="athlete?.avatar_url" alt="">
					</div>
				</div>
				<div class="item-main">
					<div class="text" *ngIf="!athlete.name && !athlete.surname">
						<h2 class="name">
							{{ 'ATLETA'|translate }}
						</h2>
					</div>
					<div class="text" *ngIf="athlete.name || athlete.surname">
						<h2 class="name">
							{{ athlete.name + ' ' + athlete.surname }}
						</h2>
						<h2 class="professione">
							{{ 'ATLETA'|translate }}
						</h2>
					</div>
				</div>
			</li>
		</ul>
	</ng-container>

	<ng-container *ngIf="(lesson?.queued_athletes?.length || 0) > 0">
		<h4 class="row-title">
			{{ 'IN_CODA'|translate }} ({{ lesson?.queued_athletes?.length }})
		</h4>
		<ul class="list-group athletes">
			<li class="list-group-item" [ngClass]="{'active': athlete.is_me}" *ngFor='let athlete of lesson?.queued_athletes'>
				<div class="item-left">
					<div class="avatar">
						<img [src]="athlete?.avatar_url" alt="">
					</div>
				</div>
				<div class="item-main">
					<div class="text" *ngIf="!athlete.name && !athlete.surname">
						<h2 class="name">
							{{ 'ATLETA'|translate }}
						</h2>
					</div>
					<div class="text" *ngIf="athlete.name || athlete.surname">
						<h2 class="name">
							{{ athlete.name + ' ' + athlete.surname }}
						</h2>
						<h2 class="professione">
							{{ 'ATLETA'|translate }}
						</h2>
					</div>
				</div>
			</li>
		</ul>
	</ng-container>

	<app-floating-button *ngIf="lesson?.checked_in" [disabled]="!lesson?.can_checkout" (click)="checkout()">
		{{ "GENERAL.CHECK-OUT"|translate }}
	</app-floating-button>

	<app-floating-button *ngIf="!lesson?.checked_in" [disabled]="!lesson?.can_checkin" (click)="checkin()">
		{{ lesson?.is_lesson_full ? 'Mettimi in coda' : "GENERAL.CHECK-IN"|translate }}
	</app-floating-button>
</main>