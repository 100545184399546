import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Errors } from 'src/app/enums/errors.enum';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { Gym } from 'src/app/interfaces/gym';
import { Subscription } from 'src/app/interfaces/subscription';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { RouteService } from 'src/app/services/route/route.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';
import { SubscriptionsService } from 'src/app/services/subscriptions/subscriptions.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-rinnovo',
	templateUrl: './rinnovo.component.html',
	styleUrls: ['./rinnovo.component.scss']
})
export class RinnovoComponent implements OnInit {

	/**
	 * List of the subscriptions
	 */
	subscriptions?: Subscription[];

	/**
	 * Gym
	 */
	gym?: Gym;

	constructor(
		private subscriptionsService: SubscriptionsService,
		private loader: LoaderService,
		private toast: ToastService,
		public routeService: RouteService,
		private store: StoreService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Get gym
		 */
		this.store.onChanges<Gym>(StoreKeys.Gym)?.pipe(first((next) => next !== null))
			.subscribe(next => this.gym = next!);

		/**
		 * Get subscriptions
		 */
		this.loader.show();
		this.subscriptionsService.list()
			.then((subscriptions: Subscription[]) => {
				this.loader.hide();

				/**
				 * Set the subscriptions
				 */
				this.subscriptions = subscriptions;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

	}
}
