export const environment = {
	production: false,
	apiUrl: 'https://testapi.cross-in.com', // TEST
	// apiUrl: 'http://192.168.3.59:8000', // LOCAL APP
	stripeApiKey: 'pk_test_51Hn1qqIF86VSxOAurYsC47PADuLQvdBLMhmo9c37mdIhjeNmJNfIda09YebdfZnUcHGWutXj5ftk8G1OnzbXH6Jr00zynHIWHr',
	stripeAccountId: 'acct_1Hn1qqIF86VSxOAu',
	weblateApiKey: 'SUdR4W56WNoWE5F29wQ2npaaLkgGQpG608itGbSh',
	gymCode: null,
	googleClientId: '169756400986-c7a6l59d0a7e54pehlss0nknel5an2jb.apps.googleusercontent.com'
};
