/* eslint-disable guard-for-in */
import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

	/**
	 * Form
	 */
	form = this.fb.group({
		name: null,
		surname: null,
		email: null,
		password: null,
		password_confirmation: null,
		privacy: null,
		gym_code: null,
	});

	/**
	 * Is branding on
	 */
	isBranding = false;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toast: ToastService,
		private translate: TranslateService,
		private loader: LoaderService
	) { }

	ngOnInit(): void {

		/**
		 * If gym code is present => BRANDING ON
		 * Hide the gym code prompt and set the gym code on the form
		 */
		if (environment.gymCode) {
			this.form.get('gym_code')?.setValue(environment.gymCode);
			this.isBranding = true;
		}
	}

	/**
	 * Sign up
	 */
	signup(): void {
		this.loader.show();

		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		/**
		 * Reset errors
		 */
		for (const key in this.form.value) {
			this.form.get(key)?.setErrors(null);
		}

		/**
		 * Sign up
		 */
		this.userService.signup(formData)
			.then(() => {
				this.loader.hide();
				this.toast.show(this.translate.instant('SIGNUP.SUCCESS'));

				/**
				 * Redirect dashboard
				 */
				this.router.navigateByUrl('/login');

			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					case Errors.ModelNotFound:
						this.toast.error(this.translate.instant('SIGNUP.GYM_CODE_ERROR'));
						this.form.get('gym_code')?.setErrors({
							required: true
						});
						break;
					case Errors.Validation:
						this.toast.error(this.translate.instant('ERRORS.GENERAL_VALIDATION'));

						/**
						 * Loop through errors
						 */
						for (const key in error.error.validation) {
							for (const index in error.error.validation[key]) {

								/**
								 * Check for special validation (with message)
								 */
								switch (error.error.validation[key][index]) {

									/**
									 * Passwords don't match
									 */
									case 'password-mismatch':
										this.form.get('password_confirmation')?.setErrors({
											'password-mismatch': true
										});
										this.form.get('password')?.setErrors({
											required: true
										});
										break;

									/**
									 * Email already taken
									 */
									case 'email-taken':
										this.form.get('email')?.setErrors({
											'email-taken': true
										});
										break;

									/**
									 * Password's lenght
									 */
									case 'password-length':
										this.form.get('password')?.setErrors({
											'password-length': true
										});
										break;

									default:
										this.form.get(key)?.setErrors({
											required: true
										});
										break;
								}
							}
						}
						break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
