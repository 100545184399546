import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { StripeService } from 'ngx-stripe';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	constructor(
		private localStorageService: LocalStorageService,
		private translate: TranslateService,
		private stripeService: StripeService
	) { }

	/**
	 * Set the new language
	 */
	setLanguage(lang: string): void {

		/**
		 * Save it locally
		 */
		this.localStorageService.setItem(LocalStorageTypes.Language, lang);

		/**
		 * 3rd party plugins
		 */
		this.translate.use(lang);
		moment.locale(lang);
		this.stripeService.changeKey(environment.stripeApiKey, {
			locale: lang as any,
			stripeAccount: environment.stripeAccountId
		});
	}

	/**
	 * Ritorna le varie lingue disponibili
	 */
	getAvailableLanguages(): { [key: string]: string } {
		return {
			de: 'Deutsche',
			en: 'English',
			es: 'Español',
			fr: 'French',
			it: 'Italiano',
			pt: 'Português',
		};
	}

	/**
	 * Get current language
	 */
	getLanguage(): string {
		return this.localStorageService.getItem(LocalStorageTypes.Language);
	}

	/**
	 * Init app language
	 */
	initLanguage(): void {

		/**
		 * Get the language from local storage or the browser
		 */
		const lang = this.localStorageService.getItem(LocalStorageTypes.Language) || this.translate.getBrowserLang();

		/**
		 * Set the default fallback language
		 */
		this.translate.setDefaultLang('it');

		/**
		 * Set the new default language
		 */
		this.setLanguage(lang);
	}
}
