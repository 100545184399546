import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { Errors } from 'src/app/enums/errors.enum';
import { Exercice, Record } from 'src/app/interfaces/record';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RecordsService } from 'src/app/services/records/records.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-records-dettaglio',
	templateUrl: './records-dettaglio.component.html',
	styleUrls: ['./records-dettaglio.component.scss']
})
export class RecordsDettaglioComponent implements OnInit {

	/**
	 * % of the calculator
	 */
	percentage = 30;

	/**
	 * Max value of the personal records
	 */
	max = 140;

	/**
	 * Requested exercice
	 */
	exercice?: Exercice;

	/**
	 * Reversed records for the html
	 */
	reversedRecords?: Record[];

	constructor(
		private loader: LoaderService,
		public routeService: RouteService,
		private toast: ToastService,
		private recordsService: RecordsService,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private router: Router
	) { }

	ngOnInit(): void {

		/**
		 * Get the ID from the url
		 */
		const exerciceId = this.route.snapshot.paramMap.get('id');
		if (!exerciceId || isNaN(exerciceId as unknown as number)) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
			this.router.navigate(['/']);
			return;
		}

		/**
		 * Get the exercice
		 */
		this.loader.show();
		this.recordsService.exercice(parseInt(exerciceId))
			.then((exercice: Exercice) => {

				this.exercice = exercice;

				/**
				 * If we don't have records we won't set up the chart
				 */
				if ((this.exercice.records?.length || 0) <= 0) {
					this.loader.hide();
					return;
				}

				/**
				 * Cast date into moment
				 */
				this.exercice.records = this.exercice.records?.map((record: Record) => {
					record.date = moment(record.date);
					return record;
				});

				/**
				 * We will work with the first 5 records for the chart
				 */
				const records = exercice?.records?.slice(0, 5).reverse();

				/**
				 * Data for the chart
				 */
				let data: any[] | undefined;

				/**
				 * If the abreviation is T it's because we're dealing with time
				 */
				if (this.exercice.measurement_unit?.slug === 'time') {
					data = records?.map((record: Record) => moment.duration(record.value).asSeconds());
				} else {
					data = records?.map((record: Record) => record.value);
				}

				/**
				 * Get max and min for the chart limits
				 */
				const min = Math.min.apply(Math, data || []);
				const max = Math.max.apply(Math, data || []);

				/**
				 * Get the max for the calculator
				 */
				this.max = max;

				// Disattivo questo si pexela tutto, da testare su APP
				// Chart.defaults.global.defaultFontStyle = '100';

				setTimeout(() => {
					const ctx = (document.getElementById('myChart') as any).getContext('2d');
					const myChart = new Chart(ctx, {
						type: 'bar',
						data: {
							labels: records?.map((record: Record) => (record.date as moment.Moment).format('DD/MM/YY')),
							datasets: [{
								data,
								backgroundColor: records?.map(_ => 'rgba(255,255,255, 1)'),
								borderColor: records?.map(_ => 'rgba(255,255,255, 1)'),
								borderWidth: 1
							}]
						},
						options: {
							legend: {
								display: false,
							},
							tooltips: {
								enabled: false,
							},
							scales: {
								yAxes: [{
									ticks: {
										fontFamily: 'Bebas Neue, 200',
										fontSize: 16,
										fontColor: 'rgba(255,255,255,.5)',
										suggestedMin: min > 2 ? min - 2 : 0,
										suggestedMax: max + 2,
										maxTicksLimit: 5,
										callback: (value, index, values) => {

											/**
											 * If the abreviation is T it's because we're dealing with time
											 */
											if (this.exercice?.measurement_unit?.slug === 'time') {
												const momentObj = moment.duration(value, 'seconds');

												const hours = Math.floor(momentObj.asHours());
												momentObj.subtract(hours, 'hours');

												const minutes = Math.floor(momentObj.asMinutes());
												momentObj.subtract(minutes, 'minutes');

												// const seconds = Math.floor(momentObj.asSeconds());

												return this.pad(hours, 2) + ':' + this.pad(minutes, 2);

											} else {
												return value + (this.exercice?.measurement_unit?.abbreviation || '');
											}
										}
									}
								}],
								xAxes: [{
									ticks: {
										fontFamily: 'Bebas Neue',
										fontSize: 16,
										fontColor: 'rgba(255,255,255,.5)',
									}
								}]
							}
						}
					});
				}, 0);

				setTimeout(() => {
					this.loader.hide();
				}, 0);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * If add leading zeros as many as needed to get to size
	 */
	private pad(num: number | string, size: number): any {
		num = num.toString();
		while (num.length < size) {num = '0' + num;}
		return num;
	}
}
