import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/services/route/route.service';

@Component({
	selector: 'app-thank-you',
	templateUrl: './thank-you.component.html',
	styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

	constructor(
		public routeService: RouteService,
	) { }

	ngOnInit(): void { }
}
