<main class="abbonamento-detail" *ngIf="userSubscription">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "GENERAL.DETAILS"|translate }} {{ "GENERAL.SUBSCRIPTION"|translate }}
	</div>

	<section class="intro">
		<figure class="background">
			<img [attr.src]="userSubscription.subscription?.image_url || userSubscription.membership_fee?.image_url || 'assets/img/png/abbonamento.png'"
				alt="" />
		</figure>
		<h3>
			{{ userSubscription.subscription?.name || userSubscription.membership_fee?.name || '???' }}
		</h3>
	</section>

	<section class="details">
		<div class="card active">
			<div class="card-body">
				<ng-container *ngIf="userSubscription?.sale?.paid_at">
					{{ 'DATA_DI_ACQUISTO'|translate }}: {{ userSubscription?.sale?.paid_at }} <br>
				</ng-container>
				{{ 'DECORRENZA'|translate }}: {{ userSubscription?.valid_from }} <br>
				{{ 'GENERAL.DEADLINE'|translate }}: {{ userSubscription?.valid_to }} <br>
				<ng-container *ngIf="!userSubscription?.sale?.paid_at">
					{{ 'PAGA_ENTRO_IL'|translate }}: {{ userSubscription?.sale?.payment_deadline }} <br>
				</ng-container>
				{{ 'IMPORTO'|translate }}: {{ (userSubscription?.sale?.total || 0) | decimal }} {{ gym?.currency?.symbol
				}} <br>
				<ng-container *ngIf="userSubscription?.subscription?.type === 'entries'">
					{{ 'SUBSCRIPTION.REMAINING_ENTRIES'|translate }}: {{ userSubscription?.remaining_entries }}
				</ng-container>
			</div>
		</div>

		<div class="card" *ngIf="userSubscription?.subscription?.description">
			<h4 class="card-title">
				{{ "SUBSCRIPTION.DETAILS"|translate }}
			</h4>
			<div class="card-body">
				<span [innerHTML]="userSubscription?.subscription?.description"></span>
			</div>
		</div>

		<div class="card"
			*ngIf="userSubscription?.subscription?.cadence && userSubscription?.subscription?.type && userSubscription?.subscription?.duration && userSubscription?.subscription?.disciplines">
			<h4 class="card-title">
				{{ "GENERAL.FEATURES"|translate }}
			</h4>
			<div class="card-body">
				{{ "SUBSCRIPTION.FEATURES_DESCRIPTION"|translate:{ cadence: userSubscription?.subscription?.cadence,
				type: 'GENERAL.' +
				userSubscription?.subscription?.type|uppercase|translate, duration:
				userSubscription?.subscription?.duration} }}
				<br>
				<br>
				{{ "SUBSCRIPTION.CLASS_PARTICIPATION"|translate:{ class:
				userSubscription?.subscription?.disciplines?.join(', ') } }}
			</div>
		</div>

		<div class="card" *ngIf="userSubscription?.subscription?.autorenewal">
			<h4 class="card-title">
				{{ 'AUTORINNOVO'|translate }}
			</h4>
			<div class="card-body">
				{{'QUESTO_ABBONAMENTO_VERR_AUTOMATICAMENTE_RINNOVATO_CON_UN_PAGAMENTO_OGNI_DURATION_GIORNI'|translate:{duration:userSubscription?.subscription?.duration}
				}}
				<ng-container *ngIf="userSubscription?.subscription?.installments">
					{{ 'PER_INSTALLMENTS_VOLTA_E'|translate:{installments: userSubscription?.subscription?.installments}
					}}
				</ng-container>
			</div>
		</div>

		<div class="card" *ngIf="userSubscription?.subscription?.terms">
			<h4 class="card-title">
				{{ "SUBSCRIPTION.CONDITIONS"|translate }}
			</h4>
			<div class="card-body">
				<span [innerHTML]="userSubscription?.subscription?.terms"></span>
			</div>
		</div>
	</section>

	<app-floating-button *ngIf='gym?.payments_enabled && !userSubscription?.sale?.paid_at'
		[routerLink]="['/', 'buy', 'sale', userSubscription?.sale?.id]">
		{{ 'PAGA'|translate }}
	</app-floating-button>

	<app-floating-button *ngIf='userSubscription?.sale?.paid_at' (click)="doownloadInvoice()">
		{{ 'SCARICA_FATTURA'|translate }}
	</app-floating-button>
</main>