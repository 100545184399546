import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { Errors } from 'src/app/enums/errors.enum';
import { Gym } from 'src/app/interfaces/gym';
import { UserSubscription } from 'src/app/interfaces/user-subscription';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserSubscriptionsService } from 'src/app/services/user-subscriptions/user-subscriptions.service';

@Component({
	selector: 'app-abbonamento',
	templateUrl: './abbonamento.component.html',
	styleUrls: ['./abbonamento.component.scss']
})
export class AbbonamentoComponent implements OnInit {

	/**
	 * List of the user subscriptions
	 */
	userSubscriptions?: UserSubscription[];

	/**
	 * Active user subscription
	 */
	userSubscription?: UserSubscription;

	/**
	 * Gym
	 */
	gym?: Gym;

	constructor(
		private userSubscriptionsService: UserSubscriptionsService,
		private loader: LoaderService,
		private toast: ToastService,
		private store: StoreService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Get gym
		 */
		this.store.onChanges<Gym>(StoreKeys.Gym)?.pipe(first((next) => next !== null))
			.subscribe(next => this.gym = next!);

		/**
		 * Get user subscriptions
		 */
		this.loader.show();
		this.userSubscriptionsService.list()
			.then((userSubscriptions: UserSubscription[]) => {
				this.loader.hide();

				/**
				 * Set the user subscriptions
				 */
				this.userSubscriptions = userSubscriptions;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

		/**
		 * Get user subscription
		 */
		this.loader.show();
		this.userSubscriptionsService.active()
			.then((userSubscription: UserSubscription) => {
				this.loader.hide();

				/**
				 * Set the userSubscription
				 */
				this.userSubscription = userSubscription;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
