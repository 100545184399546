import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginator } from 'src/app/interfaces/paginator';
import { Post } from 'src/app/interfaces/post';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class PostsService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * List of the posts
	 */
	 getPaginatedList(page: number) {
		return new Promise((resolve: (paginator: Paginator<Post>) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getPaginatedPosts, { urlParams: { page: page.toString() } })
				.then((paginator: Paginator<Post>) => resolve(paginator))
				.catch((err: HttpErrorResponse) => reject(err));
		});
	}

	/**
	 * Get post by ID
	 */
	get(id: string): Promise<Post> {
		return new Promise((resolve: (post: Post) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getPost, { urlParams: { id } })
				.then((post: Post) => resolve(post))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
