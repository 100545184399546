<section class="date-dialog" [ngClass]="{show: dom.show, zindex: dom.zindex}">
	<div class="content">
		<figure class="close" (click)="cancel()">
			<i class="bi bi-x"></i>
		</figure>
		<form class="form" action="" (submit)="submit($event)">
			<div class="form-group">
				<label for="" class="label">
					{{ "SUBSCRIPTION.VALID_UNTIL"|translate }}
				</label>
				<input class="form-control" [ngClass]="{'is-invalid': date.errors}" type="date" name="date" [formControl]="date">
			</div>
			<button type="submit" class="button">
				{{ "GENERAL.UPLOAD"|translate }}
			</button>
		</form>
	</div>
</section>
