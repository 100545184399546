<main class="checkins">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "CHECK-INS.TITLE"|translate }}
	</div>

	<ul class="list-group">
		<li class="list-group-item" *ngFor="let checkin of checkins">
			<div class="item-left">
				<div class="date">
					<div class="day">{{ checkin.lesson?.date?.format('DD') }}</div>
					<div class="month">{{ checkin.lesson?.date?.format('MMM') }}</div>
				</div>
			</div>
			<div class="item-main">
				<div class="info">
					<div class="name">{{ checkin.lesson?.coach?.name + ' ' + checkin.lesson?.coach?.surname }}</div>
					<div class="activity">{{ checkin.lesson?.discipline?.name }}</div>
					<div class="hour">
						<figure class="icon">
							<i class="bi bi-clock"></i>
						</figure>
						<span>
							{{ checkin.lesson?.start_time}} - {{ checkin.lesson?.end_time}}
						</span>
					</div>
				</div>
			</div>
			<div class="item-right">
				<figure class="icon" *ngIf="checkin.lesson?.has_workout" [routerLink]="['/', 'workout-details', checkin.lesson?.id]">
					<i class="icon-dumbell"></i>
				</figure>
			</div>
		</li>
	</ul>
</main>