import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'trim'
})
export class TrimPipe implements PipeTransform {
	transform(value: any, limit?: any): any {
		return value ? value.toString().substring(0, limit || 50) + '...' : '';
	}
}
