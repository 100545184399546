/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Lesson } from 'src/app/interfaces/lesson';
import { LessonsService } from 'src/app/services/lessons/lessons.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-check-in',
	templateUrl: './check-in.component.html',
	styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent implements OnInit {

	/**
	 * Lesson
	 */
	lesson?: Lesson;

	constructor(
		private route: ActivatedRoute,
		private lessonsService: LessonsService,
		private loader: LoaderService,
		private toast: ToastService,
		public routeService: RouteService,
		private translate: TranslateService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.set();
	}

	/**
	 * Set the lesson details
	 */
	set(): void {

		const id = this.route.snapshot.paramMap.get('id');
		if (!id || isNaN(id as unknown as number)) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
			this.router.navigate(['/']);
			return;
		}

		/**
		 * Get lesson
		 */
		this.loader.show();
		this.lessonsService.get(parseInt(id))
			.then((lesson: Lesson) => {
				this.loader.hide();

				/**
				 * Set the lesson
				 */
				this.lesson = lesson;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Check in the lesson
	 */
	checkin(): void {
		this.loader.show();
		this.lessonsService.checkin(this.lesson!.id!)
			.then((result: { was_queued: boolean }) => {
				this.loader.hide();

				/**
				 * If the checkin was queued and we didn't inform the user beforehand
				 */
				if (!this.lesson!.is_lesson_full && result.was_queued) {
					this.toast.error(this.translate.instant('QUESTA_LEZIONE_PIENA_AL_MOMENTO_TI_ABBIAMO_MESSO_IN_CODA_ALLA_LEZIONE_SE_DESIRERI_USCIRNE_BASTA_FARE_CHECK_OUT'), 10000);
				}

				/**
				 * Reset info
				 */
				this.set();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					case Errors.NotAdmited:
						this.toast.error(this.translate.instant('QUESTA_LEZIONE_STATA_SOSPESA'));
						this.routeService.back();
						break;
					case Errors.GymPaused:
						this.toast.error(this.translate.instant('LA_PALESTRA_ATTUALMENTE_CHIUSA'));
						break;
					// ! Removed with queued checkin
					// case Errors.FullLesson:
					// 	this.toast.error(this.translate.instant('ERRORS.FULL_LESSON'));
					// 	break;
					case Errors.NoSubscriptionsAvailable:
						this.toast.error(this.translate.instant('ERRORS.NO_SUBSCRIPTIONS_AVAILABLE'));
						break;
					case Errors.MembershipFeeNotPaid:
						this.toast.error(this.translate.instant('ERRORS.MEMBERSHIP_FEE_NOT_PAYED'));
						break;
					case Errors.InvalidDocument:
						this.toast.error(this.translate.instant('ERRORS.DOCUMENT_ERROR', {
							document: error.error.document
						}));
						break;
					case Errors.TooLateToCheckin:
						this.toast.error(this.translate.instant('ERRORS.CHECKIN_TOO_LATE'));
						break;
					case Errors.TooLateToCheckout:
						this.toast.error(this.translate.instant('NON_PIU_POSSIBILE_USCIRE_DA_QUESTA_LEZIONE_PER_PI_INFORMAZIONE_CONTATTA_DIRETTAMENTE_IL_TUO_BOX'));
						break;
					case Errors.TooSoonToCheckin:
						this.toast.error(this.translate.instant('I_CHECK_IN_PER_QUESTA_LEZIONE_NON_SONO_ANCORA_APERTI_SI_PREGA_DI_CONTROLLARE_DI_NUOVO_IN_FUTURO'));
						break;
					case Errors.AlreadyCheckedIn:
						this.toast.error(this.translate.instant('SEI_GI_ISCRITTO_ALLA_LEZIONE'));
						break;
					case Errors.NotCheckedIn:
						this.toast.error(this.translate.instant('NON_HAI_FATTO_CHECK_IN_PER_QUESTA_LEZIONE'));
						break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Checkout from the lesson
	 */
	checkout(): void {
		this.loader.show();
		this.lessonsService.checkout(this.lesson!.id!)
			.then(() => {
				this.loader.hide();

				/**
				 * Reset info
				 */
				this.set();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					case Errors.NotCheckedIn:
						this.toast.error(this.translate.instant('NON_HAI_FATTO_CHECKIN_IN_QUESTA_LEZIONE_O_LA_LAZIONE_STATA_SOSPESA'));
						this.routeService.back();
						break;
					case Errors.TooLateToCheckout:
						this.toast.error(this.translate.instant('ERRORS.CHECKOUT_TOO_LATE'));
						break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	};
}
