<main class="sign-up" [appBackgroundImage]="'assets/img/png/background.png'">
	<section class="content">
		<figure class="logo-container">
			<img src="assets/img/png/logo.png" alt="">
		</figure>

		<form class="form" action="" [formGroup]="form" (submit)='signup()'>
			<h4>{{ "GENERAL.PERSONAL_DATA"|translate }}</h4>
			<div class="form-row">
				<input type="text" [ngClass]="{'is-invalid': form.get('name')?.errors}" formControlName='name'
					placeholder='{{ "GENERAL.NAME"|translate }}'>
			</div>
			<div class="form-row">
				<input type="text" [ngClass]="{'is-invalid': form.get('surname')?.errors}" formControlName='surname'
					placeholder='{{ "GENERAL.SURNAME"|translate }}'>
			</div>
			<div class="form-row">
				<input type="email" [ngClass]="{'is-invalid': form.get('email')?.errors}" formControlName='email'
					placeholder="{{ 'EMAIL'|translate }}" autocomplete="email">
				<span
					class="error">{{ form.get('email')?.hasError('email-taken') ? ("FORM_ERRORS.EMAIL_TAKEN"|translate) : '' }}</span>
			</div>
			<div class="form-row">
				<input type="password" [ngClass]="{'is-invalid': form.get('password')?.errors}"
					formControlName='password' placeholder="{{ 'PASSWORD'|translate }}">
				<small>{{ "FORM_ERRORS.PASSWORD_LENGTH"|translate }}</small>
				<span
					class="error">{{ form.get('password')?.hasError('password-length') ? ("FORM_ERRORS.PASSWORD_LENGTH"|translate) : '' }}</span>
			</div>
			<div class="form-row">
				<input type="password" [ngClass]="{'is-invalid': form.get('password_confirmation')?.errors}"
					formControlName='password_confirmation' placeholder='{{ "GENERAL.CONFIRM_PASSWORD"|translate }}'>
				<span
					class="error">{{ form.get('password_confirmation')?.hasError('password-mismatch') ? ("FORM_ERRORS.PASSWORD_MISMATCH"|translate) : '' }}</span>
			</div>
			<div class="form-row">
				<a [routerLink]="['/privacy']">{{ 'PRIVACY'|translate }}</a>
				<br>
				<br>
				<a [routerLink]="['/login']">{{ "SIGNUP.LOGIN_BUTTON"|translate }}</a>
			</div>
			<div class="form-row">
				<label class="row checkbox" [ngClass]="{'is-invalid': form.get('privacy')?.errors}">
					<input formControlName='privacy' type="checkbox">
					<span>{{ "SIGNUP.PRIVACY_DESCRIPTION"|translate }}</span>
				</label>
			</div>
			<h4 *ngIf="!isBranding">{{ "SIGNUP.GYM_TITLE"|translate }}</h4>
			<div class="form-row" *ngIf="!isBranding">
				<input type="text" [ngClass]="{'is-invalid': form.get('gym_code')?.errors }" formControlName='gym_code'
					placeholder="{{ 'BOX_CODE'|translate }}">
				<span
					class="error">{{ form.get('gym_code')?.hasError('invalid') ? ("FORM_ERRORS.GYM_CODE_INVALID"|translate) : null }}</span>
			</div>
			<div class="form-row">
				<input type="submit" value="SIGN UP" class="button">
			</div>
		</form>
	</section>
</main>