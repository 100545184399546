import {
	HttpErrorResponse, HttpEvent, HttpHandler,
	HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Errors } from '../enums/errors.enum';
import { Interceptors, InterceptorsService } from '../services/interceptors/interceptors.service';
import { ToastService } from '../services/toast/toast.service';
import { UserService } from '../services/user/user.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

	constructor(
		private userService: UserService,
		private toast: ToastService,
		private injector: Injector,
		private interceptorsService: InterceptorsService
	) { }

	/**
	 * Bail out when session timeout
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			retry(0),
			catchError((error: HttpErrorResponse) => {

				/**
				 * If interceptor is not enabled, skip it
				 */
				if (!this.interceptorsService.isEnabled(Interceptors.Session)) {
					return next.handle(request);
				}

				/**
				 * Token expired
				 */
				if (error?.error?.code === Errors.ExpiredToken) {
					const translate = this.injector.get(TranslateService);
					this.toast.error(translate.instant('ERRORS.SESSION_EXPIRED'));

					/**
					 * Logout
					 */
					this.userService.logout();
				}

				return throwError(error);
			})
		);
	}
}
