<main class="privacy">
	<div class="row-title">
		<figure class="back" [routerLink]="['/sign-up']">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ 'PRIVACY_POLICY'|translate }}
	</div>

	<section class="content">
		<div class="text-container" [innerHTML]='pageText'>
		</div>
	</section>
</main>
