<header>
	<section class="float">
		<figure [routerLink]="[ '/dashboard' ]" class="home" [ngClass]="{active: router.url.includes('dashboard') }">
			<i class="icon-home"></i>
		</figure>

		<figure class="logo-header" [routerLink]="[ '/gym' ]">
			<img [attr.src]="gym?.logo_url" alt="" title="">
		</figure>

		<figure class="background">
			<img src="assets/img/png/logo-background.png" alt="" title="">
		</figure>

		<figure [routerLink]="[ '/inbox' ]" class="inbox" [ngClass]="{active: router.url.includes('inbox') }">
			<span class="badge" *ngIf="newNotifications > 0">
				{{ newNotifications }}
			</span>
			<i class="icon-bell"></i>
		</figure>
	</section>
</header>
