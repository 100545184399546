import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Gym } from 'src/app/interfaces/gym';
import { Subscription } from 'src/app/interfaces/subscription';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';
import { SubscriptionsService } from 'src/app/services/subscriptions/subscriptions.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-rinnovo-detail',
	templateUrl: './rinnovo-detail.component.html',
	styleUrls: ['./rinnovo-detail.component.scss']
})
export class RinnovoDetailComponent implements OnInit {

	/**
	 * Subscription plan
	 */
	subscription?: Subscription;

	/**
	 * Gym
	 */
	gym?: Gym;

	constructor(
		private subscriptionsService: SubscriptionsService,
		private loader: LoaderService,
		private toast: ToastService,
		public routeService: RouteService,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private store: StoreService,
		private router: Router
	) { }

	ngOnInit(): void {

		const subscriptionId = this.route.snapshot.paramMap.get('id');
		if (!subscriptionId || isNaN(subscriptionId as unknown as number)) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
			this.router.navigate(['/']);
			return;
		}

		/**
		 * Fetch gym
		 */
		this.loader.show();
		this.store.refresh(StoreKeys.Gym)
			.then((next: Gym | null) => {
				if (next) {
					this.gym = next;
					this.loader.hide();
				}
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

		/**
		 * Get the subscription
		 */
		this.loader.show();
		this.subscriptionsService.get(subscriptionId)
			.then((subscription: Subscription) => {
				this.loader.hide();

				/**
				 * Set the subscription plan
				 */
				this.subscription = subscription;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

	}
}
