import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService, Requests } from '../services/http/http.service';

@Injectable()
export class MyCustomHttpLoader implements TranslateLoader {

	private http: HttpService;

	constructor(http: HttpService) {
		this.http = http;
	}

	/**
	 * Get the translation json for the given lang
	 */
	getTranslation(lang: string): Observable<any> {
		return from(
			new Promise((resolve: (json: string) => void, reject: () => void) => {

				/**
				 * Get translation file from weblate.camacrea.it
				 */
				this.http.send(Requests.translate, {
					urlParams: { lang },
					headers: {
						Authorization: 'Token ' + environment.weblateApiKey
					}
				})
					.then((json: string) => {
						resolve(json);
					})
					.catch((error: HttpErrorResponse) => {
						console.error(error);
						reject();
					});
			})
		);
	}
}

const MyCustomHttpLoaderFactory = (http: HttpService): MyCustomHttpLoader => new MyCustomHttpLoader(http);
export default MyCustomHttpLoaderFactory;
