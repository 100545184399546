<main class="code" [appBackgroundImage]="'assets/img/png/background.png'">
	<section class="content">
		<figure class="logo-container">
			<img src="assets/img/png/logo.png" alt="">
		</figure>

		<div class="forms-container">
			<h3 class="title">
				{{ 'INSERISCI_IL_CODICE_DELLA_TUA_PALESTRA_PER_PROCEDERE'|translate }}
			</h3>
			<div class="form">
				<form (submit)='submit()'>
					<div class="form-row">
						<input type="text" [ngClass]="{'is-invalid': codeControl?.errors}"
							[formControl]="codeControl" placeholder="{{ 'CODICE_PALESTRA'|translate }}">
					</div>
					<div class="form-row">
						<input type="submit" value="{{ 'INVIA'|translate }}" class="button">
					</div>
				</form>
			</div>
		</div>
	</section>
</main>