import { Injectable } from '@angular/core';
import { NgxGlobalEventsService } from 'ngx-global-events';
import { take } from 'rxjs/operators';
import { GlobalEvents } from 'src/app/enums/global-events.enum';
import { MultimediaTypes } from 'src/app/enums/multimedia-types.enum';

/**
 * Fle Chooser Plugin. Android & IOS
 * https://github.com/cyph/cordova-plugin-chooser
 */
declare let chooser: any;
declare let navigator: any;

@Injectable({
	providedIn: 'root'
})
export class MultimediaService {

	constructor(
		private globalEventsService: NgxGlobalEventsService
	) { }

	/**
	 * Show multimedia chooser
	 * Eventually if a "get file" Multimedia type is selected, it resolves it's URI (from filesystem, gallery, camera...)
	 */
	show(types: MultimediaTypes[], options?: { height: number; width: number }): Promise<{ uri: string; name: string } | MultimediaTypes | null> {
		return new Promise(async (resolve: (result: { uri: string; name: string } | MultimediaTypes | null) => void, reject: (reason: any) => void) => {

			/**
			 * We show the choose modal
			 * * We can't show the native choose modal cause android doesn't support it
			 * * https://github.com/apache/cordova-android/issues/816
			 */
			this.globalEventsService.emit(GlobalEvents.ChoseMediaType, types);

			/**
			 * Wait for the response
			 */
			this.globalEventsService.get(GlobalEvents.MediaTypeChosen).pipe(take(1)).subscribe((type: MultimediaTypes) => {
				switch (type) {
					case MultimediaTypes.Gallery:
					case MultimediaTypes.Camera:

						/**
						 * Get picture with camera plugin
						 */
						this.getPicture(MultimediaTypes.Gallery === type ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA, options)
							.then((result: { uri: string; name: string }) => {
								resolve(result);
							})
							.catch((error) => {
								console.error('Unable to obtain picture', error);
								reject(error);
							});
						break;

					case MultimediaTypes.File:

						/**
						 * Get file with filechooser pulgin
						 */
						this.getFile()
							.then((result: { uri: string; name: string }) => {
								resolve(result);
							})
							.catch((error: any) => {
								console.error('Unable to obtain file', error);
								reject(error);
							});
						break;

					case MultimediaTypes.Download:

						/**
						 * Return the choice since it's a custom implementation type
						 */
						resolve(MultimediaTypes.Download);
						break;

					/**
					 * Operation cancelled
					 */
					default:
						resolve(null);
						return;
				}
			});

		});
	}

	/**
	 * Get picture from camera plugin
	 */
	private getPicture(sourceType: number, options?: { height: number; width: number }): Promise<{ uri: string; name: string }> {
		return new Promise((resolve: (result: { uri: string; name: string }) => void, reject: (reason: any) => void) => {
			const cameraOptions: CameraOptions = {
				// Some common settings are 20, 50, and 100
				quality: 50,
				destinationType: Camera.DestinationType.FILE_URI,
				// In this app, dynamically set the picture source, Camera or photo gallery
				sourceType,
				encodingType: Camera.EncodingType.JPEG,
				mediaType: Camera.MediaType.PICTURE,
				// allowEdit: true, // ! It generates https://github.com/apache/cordova-plugin-camera/issues/411 on android
				correctOrientation: true,  // Corrects Android orientation quirks
			};

			/**
			 * Set external width and height if requested
			 */
			if (options?.height) { cameraOptions.targetHeight = options.height; }
			if (options?.width) { cameraOptions.targetWidth = options.width; }

			/**
			 * Take/Choose photo
			 */
			navigator.camera.getPicture((uri: string) => {
				resolve({
					uri,
					name: 'image.jpg'
				});
			}, (error: any) => {
				reject(error);
			}, cameraOptions);
		});
	}

	/**
	 * Get file with the chooser plguin
	 */
	private getFile(): Promise<{ uri: string; name: string }> {
		return new Promise((resolve: (result: { uri: string; name: string }) => void, reject: (reason?: any) => void) => {
			chooser.getFile()
				.then((file: {
					data: Uint8Array;
					dataURI: string;
					mediaType: string;
					name: string;
					uri: string;
				}) => {
					resolve({
						uri: file.uri,
						name: file.name
					});
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}
}
