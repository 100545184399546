import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Category, Record, Exercice } from 'src/app/interfaces/record';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { RecordsService } from 'src/app/services/records/records.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-records',
	templateUrl: './records.component.html',
	styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {

	categories?: Category[];
	exercices?: Exercice[];

	constructor(
		private loader: LoaderService,
		public routeService: RouteService,
		private toast: ToastService,
		private recordsService: RecordsService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Get the categories
		 */
		this.loader.show();
		this.recordsService.categories()
			.then((categories: Category[]) => {
				this.loader.hide();

				this.categories = categories;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});

	}

	/**
	 * On material tab (category) change
	 */
	onTabChanged(event: any): void {
		if (this.categories && this.categories[event.index].id) {
			this.getExercices(this.categories[event.index].id!);
		}
	}

	/**
	 * Get exercices given a category id to filter by
	 */
	private getExercices(categoryId: number): void {

		/**
		 * Get the exercices
		 */
		this.loader.show();
		this.recordsService.exercices(categoryId)
			.then((exercices: Exercice[]) => {

				this.exercices = exercices;

				setTimeout(() => {
					this.loader.hide();
				}, 0);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
