import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Comment } from 'src/app/interfaces/comment';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class CommentService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * List of the comments
	 */
	list(postId: number | string): Promise<Comment[]> {
		return new Promise((resolve: (comments: Comment[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getComments, { urlParams: { id: postId.toString() } })
				.then((comments: Comment[]) => resolve(comments))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Create a new comment
	 */
	create(postId: number | string, text: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.createComment, { urlParams: { id: postId.toString() }, body: { text } })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
