<main class="privacy">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ 'PRIVACY_POLICY'|translate }}
	</div>

	<section class="content">
		<span [innerHTML]='privacy?.text'></span>
	</section>

	<app-floating-button *ngIf="privacy && !privacy.accepted" (click)="accept()">
		{{ 'ACCETTA'|translate }}
	</app-floating-button>
</main>