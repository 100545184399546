import { Component, OnInit } from '@angular/core';
import { NgxGlobalEventsService } from 'ngx-global-events';
import { GlobalEvents } from 'src/app/enums/global-events.enum';
import { Interceptors, InterceptorsService } from 'src/app/services/interceptors/interceptors.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';

@Component({
	selector: 'app-authenticated',
	templateUrl: './authenticated.component.html',
	styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit {

	constructor(
		private loader: LoaderService,
		private store: StoreService,
		private languageService: LanguageService,
		// private userService: UserService,
		private globalEventsService: NgxGlobalEventsService,
		private interceptorsService: InterceptorsService
	) { }

	ngOnInit(): void {

		/**
		 * Enable interceptors
		 */
		this.interceptorsService.enable([
			Interceptors.FCM,
			Interceptors.Gym,
			Interceptors.Session
		]);

		/**
		 * Set gym
		 */
		this.loader.show();
		this.store.refresh(StoreKeys.Gym).finally(() => this.loader.hide());

		/**
		 * Init language
		 */
		this.languageService.initLanguage();

		/**
		 * Update the notifications
		 */
		this.globalEventsService.emit(GlobalEvents.RequestRefreshNewNotifications);
	}
}
