import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { Privacy } from 'src/app/interfaces/privacy';
import { HttpService, Requests } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RouteService } from 'src/app/services/route/route.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-gym-privacy',
	templateUrl: './gym-privacy.component.html',
	styleUrls: ['./gym-privacy.component.scss']
})
export class GymPrivacyComponent implements OnInit {

	/**
	 * Privacy
	 */
	privacy?: Privacy;

	constructor(
		private loader: LoaderService,
		public routeService: RouteService,
		private http: HttpService,
		private toast: ToastService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.loader.show();
		this.http.send(Requests.getPrivacy)
			.then((privacy: Privacy) => {
				this.privacy = privacy;
				this.loader.hide();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Accept the privacy policy
	 */
	accept(): void {
		this.loader.show();
		this.http.send(Requests.acceptPrivacy)
			.then(() => {
				this.privacy!.accepted = true;
				this.loader.hide();
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
