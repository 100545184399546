export enum Errors {

	/**
	 * Url non trovata
	 */
	UrlNotFound = 0,

	/**
	 * Modello inesistente
	 */
	ModelNotFound = 1,

	/**
	 * Token scaduto
	 */
	ExpiredToken = 2,

	/**
	 * Errore di validazione
	 */
	Validation = 3,

	/**
	 * Query exception (l’utente ha inviato per richiesta qualcosa che non doveva)
	 */
	QueryException = 4,

	/**
	 * Utente non è un admin
	 */
	NotAdmin = 5,

	/**
	 * L’Admin non è di questa palestra
	 */
	AdminNotGym = 6,

	/**
	 * Scenario non ammesso (es. Abilitare un servizio già abilitato)
	 */
	NotAdmited = 7,

	/**
	 * FIle presente ma senza le date di scadenza
	 */
	LackDates = 8,

	/**
	 * Non è stato possibile inviare la mail
	 */
	MailSend = 9,

	/**
	 * L’utente non ha il ruolo richiesto (errore di permessi)
	 */
	LackPermission = 10,

	/**
	 * Codice della palestra non provvisto
	 */
	LackGymCode = 11,

	/**
	 * Palestra sconosciuta
	 */
	GymNotFound = 12,

	/**
	 * L’atleta non è iscritto a questa palestra
	 */
	UserNotInGym = 13,

	/**
	 * La lezione è piena
	 */
	FullLesson = 14,

	/**
	 * Non ci sono abbonamenti disponibili
	 */
	NoSubscriptionsAvailable = 15,

	/**
	 * La quota associativa non è stata pagata
	 */
	MembershipFeeNotPaid = 16,

	/**
	 * Documento invalido (non presente, o scaduto)
	 */
	InvalidDocument = 17,

	/**
	 * Too late to checkin
	 */
	TooLateToCheckin = 18,

	/**
	 * Too late to checkout
	 */
	TooLateToCheckout = 19,

	/**
	 * Stripe ID not found
	 */
	StripeIDNotFound = 20,

	/**
	 * The box is in pause/delay
	 */
	GymPaused = 22,

	/**
	 * It's too soon to check in
	 */
	TooSoonToCheckin = 23,

	/**
	 * Already checked in the lesson
	 */
	AlreadyCheckedIn = 24,

	/**
	 * User not checkin in the lesson
	 */
	NotCheckedIn = 25,

	/**
	 * Payment needs redirection
	 */
	ThreeDSConfirmation = 26,

	/**
	 * Payment failed
	 */
	PaymentFailed = 27,

	/**
	 * Invalid payment method
	 */
	InvalidPaymentMethod = 28,

	/**
	 * Upload failed
	 */
	UploadFailed = 29,

	/**
	 * Account not associated with any user
	 */
	AccountNotAssociated = 30
}
