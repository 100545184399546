<section class="alert" *ngIf="expiries && expiries.length > 0">
	<div class="swiper-container">
		<div class="swiper-wrapper">
			<ng-container *ngFor="let expiry of expiries">
				<div class="swiper-slide" *ngIf="expiry">
					<div class="col">
						<div class="message">{{ expiry?.text }}</div>
					</div>
					<div class="col" *ngIf="expiry?.link">
						<div class="button-container">
							<a [routerLink]="['/', expiry?.link?.page]" class="button">
								{{ expiry?.link?.text }}
							</a>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="swiper-pagination"></div>
	</div>
</section>