import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Interceptors, InterceptorsService } from '../services/interceptors/interceptors.service';
import { UserService } from '../services/user/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private userService: UserService,
		private interceptorsService: InterceptorsService,
	) { }

	/**
	 * Update header HTTP call
	 *
	 */
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		/**
		 * If interceptor is not enabled, skip it
		 */
		if (!this.interceptorsService.isEnabled(Interceptors.Auth)) {
			return next.handle(request);
		}

		/**
		 * HttpHeader object immutable - copy values
		 */
		const headerSettings: {
			[name: string]: string | string[];
		} = {};

		/**
		 * Set keys
		 */
		for (const key of request.headers.keys()) {
			headerSettings[key] = request.headers.getAll(key) || [];
		}

		/**
		 * Set AuthToken if exist
		 */
		if (this.userService.getAuthToken() !== null && !request.url.includes('https://weblate.camacrea.it')) {
			headerSettings.Authorization = 'Bearer ' + this.userService.getAuthToken();
		}

		/**
		 * Add token code to header
		 */
		const newHeader = new HttpHeaders(headerSettings);
		const authReq = request.clone({
			headers: newHeader
		});

		return next.handle(authReq);
	}
}
