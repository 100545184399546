/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod, StripeCardElementOptions, StripeElementLocale, StripeElementsOptions, StripeError } from '@stripe/stripe-js';
import 'jquery';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { Gym } from 'src/app/interfaces/gym';
import { Product } from 'src/app/interfaces/product';
import { User } from 'src/app/interfaces/user';
import { Errors } from 'src/app/enums/errors.enum';
import { ProductTypes } from 'src/app/enums/product-types';
import { AppService } from 'src/app/services/app/app.service';
import { HttpService, Requests } from 'src/app/services/http/http.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductService } from 'src/app/services/product/product.service';
import { RouteService } from 'src/app/services/route/route.service';
import { StoreKeys, StoreService } from 'src/app/services/store/store.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-buy',
	templateUrl: './buy.component.html',
	styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements OnInit {

	/**
	 *	Stripe card element
	 */
	@ViewChild(StripeCardComponent, { static: false }) card?: StripeCardComponent;

	/**
	 * Product
	 */
	product?: Product;

	/**
	 * Gym
	 */
	gym?: Gym;

	/**
	 * Stripe options for styling, language and other things
	 */
	cardOptions: StripeCardElementOptions = {
		iconStyle: 'solid',
		hidePostalCode: true,
		style: {
			base: {
				iconColor: '#aaa',
				color: '#fff',
				fontWeight: '300',
				fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				'::placeholder': { color: '#aaa' },
				':-webkit-autofill': {
					color: '#fce883',
				},
				backgroundColor: '#323546',
			},
			invalid: {
				iconColor: '#ff0000',
				color: '#ff0000'
			}
		}
	};
	elementsOptions: StripeElementsOptions = {
		locale: (this.languageService.getLanguage() as unknown as any)
	};

	/**
	 * User's payments methods
	 */
	stripePaymentMethods?: PaymentMethod[];

	/**
	 * Form group
	 */
	form = this.fb.group({
		name: null,
		surname: null,
		email: null,
		address: null,
		city: null,
		country: null,
		postal_code: null,
		tax_code: null,
		product_id: null,
		product_type: null,
		payment_method_id: null
	});

	constructor(
		private loader: LoaderService,
		private toast: ToastService,
		public routeService: RouteService,
		private route: ActivatedRoute,
		private router: Router,
		private store: StoreService,
		private productService: ProductService,
		private stripeService: StripeService,
		private fb: FormBuilder,
		private userService: UserService,
		private translate: TranslateService,
		private languageService: LanguageService,
		private appService: AppService,
		private httpService: HttpService
	) { }

	ngOnInit(): void {

		/**
		 * Url param product ID and type
		 */
		const id = this.route.snapshot.paramMap.get('id')!;
		const type = (this.router.url.includes('membership-fee') ? ProductTypes.MembershipFee : this.route.snapshot.paramMap.get('type'))!;

		/**
		 * ID check
		 */
		if (type !== ProductTypes.MembershipFee && isNaN(id as unknown as number)) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
			this.routeService.back();
			return;
		}

		/**
		 * Type check
		 */
		if (!(Object.values(ProductTypes).includes(type as any))) {
			this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'))
			this.routeService.back();
			return;
		}

		/**
		 * Set product type
		 */
		this.form.patchValue({
			product_id: id || null,
			product_type: type
		});

		/**
		 * Fetch the product we want to pay
		 */
		this.fetchProduct(type as ProductTypes, id);

		/**
		 * Get gym
		 */
		this.store.onChanges<Gym>(StoreKeys.Gym)?.pipe(first((next) => next !== null))
			.subscribe(next => this.gym = next!);

		/**
		 * Fetch the current user
		 */
		this.fetchUser();

		/**
		 * Get and set stripe payment methods
		 */
		this.fetchStripePaymentMethods();
	}

	/**
	 * Form submit
	 */
	submit(): void {

		/**
		 * If it's a new payment method
		 */
		if (this.form.get('payment_method_id')?.value === null) {

			/**
			 * Create the payment method
			 */
			this.loader.show();
			firstValueFrom(this.stripeService.createPaymentMethod({
				type: 'card',
				card: this.card!.element,
				billing_details: {
					name: (this.form.get('surname')?.value + ' ' + this.form.get('name')?.value),
					email: this.form.get('email')?.value
				},
			}))
				.then((result?: { paymentMethod?: PaymentMethod; error?: StripeError }) => {
					this.loader.hide();

					/**
					 * If error
					 */
					if (result?.error) {
						this.toast.error(result.error.message || this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(result.error);
					} else {

						/**
						 * Set the payment method ID to the form
						 */
						this.form.patchValue({ payment_method_id: result?.paymentMethod?.id });

						/**
						 * Attempt the payment
						 */
						this.pay();
					}
				});
		} else {

			/**
			 * Attempt the payment
			 */
			this.pay();
		}
	}

	/**
	 * Get and set stripe paymetn methods
	 */
	fetchStripePaymentMethods(): void {

		/**
		 * We get the stripe payment methods of the user
		 */
		this.loader.show();
		this.userService.getStripePaymentMethods()
			.then((payment_methods: PaymentMethod[]) => {
				this.loader.hide();
				this.stripePaymentMethods = payment_methods;

				/**
				 * Set first payment method default
				 */
				this.form.get('payment_method_id')?.setValue(payment_methods[0]?.id || null);
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.StripeIDNotFound:
						this.toast.error(this.translate.instant('BUY.ERROR_INAPP_PAYMENTS'));
						console.error(error);
						break;
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Fetch the current user
	 */
	fetchUser() {
		this.loader.show();
		this.userService.get()
			.then((user: User) => {
				this.loader.hide();

				/**
				 * Fill the form with what we have of the user
				 */
				this.form.patchValue({
					name: user.name,
					surname: user.surname,
					email: user.email,
					address: user.address,
					city: user.city,
					country: user.country,
					postal_code: user.postal_code,
					tax_code: user.tax_code
				});
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.StripeIDNotFound:
						this.toast.error(this.translate.instant('BUY.ERROR_INAPP_PAYMENTS'));
						console.error(error);
						break;
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Fetch the product
	 */
	fetchProduct(type: ProductTypes, id?: number | string) {
		this.loader.show();
		this.httpService.send(Requests.getProduct, {
			urlParams: {
				type,
				id: id?.toString() || ''
			}
		})
			.then((product: Product) => {
				this.loader.hide();
				this.product = product;
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.StripeIDNotFound:
						this.toast.error(this.translate.instant('BUY.ERROR_INAPP_PAYMENTS'));
						console.error(error);
						break;
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Delete payment method
	 */
	deletePaymentMethod(paymentMethodId: string): void {
		this.appService.confirm(this.translate.instant('ELIMINA_CARTA'), this.translate.instant('SEI_SICURO_DI_VOLERE_ELIMINARE_QUESTA_CARTA_DAL_TUO_PROFILO'))
			.then(() => {

				/**
				 * Remove the stripe payment method
				 */
				this.loader.show();
				this.userService.removeStripePaymentMethod(paymentMethodId)
					.then(() => {

						/**
						 * Redirect
						 */
						this.loader.hide();
						this.toast.show(this.translate.instant('CARTA_RIMMOSSA_CON_SUCCESSO'));

						/**
						 * Refresh list
						 */
						this.fetchStripePaymentMethods();
					})
					.catch((error) => {
						this.loader.hide();

						/**
						 * Error catch
						 */
						switch (error?.error?.code) {
							case Errors.ExpiredToken: break;
							case Errors.LackGymCode: break;
							case Errors.GymNotFound: break;
							case Errors.UserNotInGym: break;

							default:
								this.toast.error(this.translate.instant('BUY.REQUEST_CANNOT_BE_PROCESSED'));
								console.error(error);
								break;
						}
					});

			})
			.catch(() => { });
	}

	/**
	 * Attempt payment or subscription
	 */
	private pay(): void {

		/**
		 * Attempt to pay the porduct
		 */
		this.loader.show();
		this.productService.pay(this.form.value)
			.then(() => {

				/**
				 * Redirect
				 */
				this.loader.hide();
				this.toast.show(this.translate.instant('BUY.SUCCESSFUL_PAYMENT'));
				this.router.navigate(['thank-you']);
			})
			.catch((error) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.StripeIDNotFound:
						this.toast.error(this.translate.instant('BUY.ERROR_INAPP_PAYMENTS'));
						console.error(error);
						break;
					case Errors.ModelNotFound:

						/**
						 * If sale not found, must be because it's not available anymore (cancelled or expired)
						 */
						if (this.route.snapshot.queryParamMap.has('sale')) {
							this.toast.error(this.translate.instant('IL_PAGAMENTO_IN_APP_NON_DISPONIBILE_PER_QUESTO_ABBONAMENTO_CONSULTARE_LA_PALESTRA_PER_PIU_INFORMAZIONI'));
						}
						break;
					case Errors.ExpiredToken: break;
					case Errors.LackGymCode: break;
					case Errors.GymNotFound: break;
					case Errors.UserNotInGym: break;
					case Errors.Validation:
						this.toast.error(this.translate.instant('BUY.VALIDATION_ERRORS'));

						/**
						 * Loop through errors
						 */
						for (const key in error.error.validation) {
							for (const index in error.error.validation[key]) {

								/**
								 * Check for special validation (with message)
								 */
								switch (error.error.validation[key][index]) {
									case 'payment_method_id':
										this.toast.error(this.translate.instant('SELEZIONA_UN_METODO_DI_PAGAMENTO_VALIDO_O_COMPILA_IL_FORM_PER_INSERIRNE_UNO'));
										break;

									default:
										this.form.get(key)?.setErrors({
											required: true
										});
										break;
								}
							}
						}
						break;

					/**
					 * Handle errors like insuficient funds and stuff like that
					 */
					case Errors.PaymentFailed:
						if (error.error.message) {
							this.toast.error(error.error.message);
						} else {
							this.toast.error(this.translate.instant('BUY.REQUEST_CANNOT_BE_PROCESSED'));
						}
						break;

					case Errors.ThreeDSConfirmation:

						/**
						 * Change the key to the gym stripe id
						 */
						this.stripeService.changeKey(environment.stripeApiKey, {
							locale: this.languageService.getLanguage() as StripeElementLocale,
							stripeAccount: error.error.stripe_account_id
						});

						/**
						 * Handle secure card action
						 */
						this.stripeService.handleCardAction(error.error.client_secret).toPromise()
							.then((result) => {
								if (result?.error) {

									/**
									 * Avoid Stripe same instance error
									 */
									this.toast.error(this.translate.instant('IL_PAGAMENTO_STATO_CANCELLATO'));
									this.routeService.back();
								} else if (result?.paymentIntent?.id) {

									/**
									 * Confirm the payment
									 */
									this.loader.show();
									this.productService.confirmPaymentIntent(result.paymentIntent.id)
										.then(() => {

											/**
											 * Redirect
											 */
											this.loader.hide();
											this.toast.show(this.translate.instant('BUY.SUCCESSFUL_PAYMENT'));
											this.router.navigate(['thank-you']);
										})
										.catch((error: HttpErrorResponse) => {
											this.loader.hide();

											/**
											 * Error catch
											 */
											switch (error?.error?.code) {
												case Errors.ExpiredToken: break;
												case Errors.LackGymCode: break;
												case Errors.GymNotFound: break;
												case Errors.UserNotInGym: break;

												default:
													this.toast.error(this.translate.instant('BUY.REQUEST_CANNOT_BE_PROCESSED'));
													console.error(error);
													break;
											}
										});
								}
							})
							.finally(() => {

								/**
								 * Change back to the crossin account id
								 */
								this.stripeService.changeKey(environment.stripeApiKey, {
									locale: this.languageService.getLanguage() as StripeElementLocale,
									stripeAccount: environment.stripeAccountId
								});
							});
						break;

					default:
						this.toast.error(this.translate.instant('BUY.REQUEST_CANNOT_BE_PROCESSED'));
						console.error(error);
						break;
				}
			});
	}
}
