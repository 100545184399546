import { Injectable } from '@angular/core';
import { Checkin } from 'src/app/interfaces/checkin';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpService, Requests } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class CheckinService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * List the user's checkin
	 */
	list(): Promise<Checkin[]> {
		return new Promise((resolve: (checkins: Checkin[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getChekins)
				.then((checkins: Checkin[]) => resolve(checkins))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
