import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentMethod } from '@stripe/stripe-js';
import { Errors } from 'src/app/enums/errors.enum';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { Expiry } from 'src/app/interfaces/expiry';
import { User } from 'src/app/interfaces/user';
import { AppService } from '../app/app.service';
import { HttpService, Requests } from '../http/http.service';
import { LanguageService } from '../language/language.service';
import { LoaderService } from '../loader/loader.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { StoreKeys, StoreService } from '../store/store.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(
		private http: HttpService,
		private localStorageService: LocalStorageService,
		private router: Router,
		private ngZone: NgZone,
		private store: StoreService,
		private appService: AppService,
		private loader: LoaderService,
		private injector: Injector
	) { }

	/**
	 * Log in user
	 */
	login(data: { email?: string, password?: string, idToken?: string }): Promise<void> {
		return new Promise<void>(async (resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.login, {
				body: data
			})
				.then((data: {
					auth_token: string;
					lang: string;
					gym_code: string;
				}) => {
					this.localStorageService.setItems([

						/**
						 * Save the AuthToken
						 */
						{ key: LocalStorageTypes.AuthToken, value: data.auth_token },

						/**
						 * Save the lang
						 */
						{ key: LocalStorageTypes.Language, value: data.lang },

						/**
						 * Save the gym code
						 */
						{ key: LocalStorageTypes.GymCode, value: data.gym_code }
					]);
					resolve();
				})
				.catch((error: HttpErrorResponse) => {
					switch (error.error?.code) {

						/**
						 * If gym is not found let's him a temporary authentication
						 * He will not be able to visit anything but the code page
						 * This is to know who we will be connecting to X gym
						 */
						case Errors.GymNotFound:
							this.localStorageService.setItem(LocalStorageTypes.AuthToken, error.error.token);
							break;
					}

					reject(error)
				});
		});
	}

	/**
	 * Singup call
	 */
	signup(formData: FormData): Promise<void> {
		return new Promise<void>((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			const lang = this.injector.get(LanguageService);

			/**
			 * Send phone's language
			 */
			formData.append('lang', lang.getLanguage());

			this.http.send(Requests.signup, {
				body: formData
			})
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Singup call
	 */
	requestPassword(formData: FormData): Promise<void> {
		return new Promise<void>((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.forgotPassword, {
				body: formData
			})
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Logout
	 */
	logout(): void {

		/**
		 * Get the FCM token
		 */
		this.loader.show();
		this.appService.getFCMToken()
			.then((fcmToken) => {

				/**
				 * Request remove token
				 */
				this.http.send(Requests.removeFCMToken, {
					body: {
						fcmToken
					}
				})
					.finally(() => {

						/**
						 * Delete data from LocalStorage
						 */
						this.localStorageService.removeItems([
							LocalStorageTypes.AuthToken,
							LocalStorageTypes.GymCode,
							LocalStorageTypes.Language
						]);

						/**
						 * Remove gym from the store
						 */
						this.store.delete(StoreKeys.Gym);

						/**
						 * Redirect to login
						 */
						this.ngZone.run(() => {
							this.router.navigateByUrl('/login');
						});

						/**
						 * Sign out firebase
						 */
						this.appService.signOut();

						/**
						 * Re-init language
						 */
						const lang = this.injector.get(LanguageService);
						lang.initLanguage();

						this.loader.hide();
					})
					.catch((error: HttpErrorResponse) => {
						console.log('Couldn\'t remove the FCM token', error);
						this.loader.hide();
					});
			});
	}

	/**
	 * Get User Data
	 */
	get(id?: string): Promise<User> {
		return new Promise((resolve: (user: User) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getUser, {
				urlParams: {
					id: id || ''
				}
			})
				.then((user: User) => resolve(user))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Update user
	 */
	update(formData: FormData): Promise<User> {
		return new Promise((resolve: (user: User) => void, reject: (error: HttpErrorResponse) => void) => {

			/**
			 * * Set the put method in the form data and fire a post instead
			 * * PHP put method can't process form data so we need to do this work around
			 */
			formData.append('_method', 'PUT');

			this.http.send(Requests.updateUser, {
				body: formData
			})
				.then((user: User) => resolve(user))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Upload files call
	 */
	upload(formData: FormData): Promise<{ url?: string | undefined; name: string } | undefined> {

		return new Promise((resolve: (file?: { url?: string; name: string }) => void, reject: (error: HttpErrorResponse) => void) => {

			/**
			 * Upload di documents
			 */
			if (formData.get('key')) {

				this.http.send(Requests.uploadDocuments, {
					body: formData
				})
					.then((file?: { url: string; name: string }) => resolve(file))
					.catch((error: HttpErrorResponse) => reject(error));
			}

			/**
			 * Upload foto profilo
			 */
			else {
				this.http.send(Requests.uploadAvatar, {
					body: formData
				})
					.then((file?: { url: string; name: string }) => resolve(file))
					.catch((error: HttpErrorResponse) => reject(error));
			}
		});
	}

	/**
	 * Get stripe payment methods
	 */
	getStripePaymentMethods(): Promise<PaymentMethod[]> {
		return new Promise((resolve: (payment_methods: PaymentMethod[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getStripePaymentMethods)
				.then((payment_methods: PaymentMethod[]) => resolve(payment_methods))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Delete payment method from user
	 */
	removeStripePaymentMethod(paymentMethodId: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.removeStripePaymentMethod, {
				urlParams: {
					paymentMethodId
				}
			})
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Toggle public property of the user
	 */
	togglePublic(): Promise<boolean> {
		return new Promise((resolve: (publicProfile: boolean) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.togglePublicProfile)
				.then((publicProfile: boolean) => resolve(publicProfile))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Create account association
	 */
	createAssociation(idToken: string, provider: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.createAssociation, { body: { idToken, provider } })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Remove account association
	 */
	removeAssociation(provider: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.removeAssociation, { body: { provider } })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get expiries that user should be aware of
	 */
	getExpiries(): Promise<Expiry[]> {
		return new Promise((resolve: (expiries: Expiry[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.expiries)
				.then((expiries: Expiry[]) => resolve(expiries))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 *	Check if authenticated
	 * 	If access_token is present -> is logged
	 */
	isAuthenticated(): boolean {
		return this.localStorageService.getItem(LocalStorageTypes.AuthToken) !== null;
	}

	/**
	 * Ritorno il token di accesso dell'utente loggato
	 */
	getAuthToken(): any {
		return this.localStorageService.getItem(LocalStorageTypes.AuthToken);
	}
}
