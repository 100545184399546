import {
	Injectable
} from '@angular/core';
import {
	Router,
	NavigationStart,
} from '@angular/router';
import {
	LoaderService
} from '../loader/loader.service';
import {
	EnvironmentService
} from '../environment/environment.service';
import {
	Location
} from '@angular/common';
import { log } from 'util';

@Injectable({
	providedIn: 'root'
})
export class RouteService {

	private app = {

		/**
		 * Array of urls
		 */
		history: [] as string[],

		/**
		 * Going back
		 */
		goingBack: false,

		/**
		 * Current URL
		 */
		current: null as string | null,

		/**
		 * Last visited route
		 */
		previous: null as string | null
	};

	private web = {

		/**
		 * Previous URL
		 */
		previous: null as string | null,

		/**
		 * Current URL
		 */
		current: null as string | null
	};

	constructor(
		private router: Router,
		private loader: LoaderService,
		private environment: EnvironmentService,
		private location: Location
	) {

		/**
		 * Call event change route
		 */
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {

				/**
				 * Flag for going back
				 * When returning back don't consider the last action
				 */
				if (this.app.goingBack) {
					this.app.goingBack = false;
					return;
				}

				/**
				 *	If the current page is just the refresh page, don't save it
				 */
				if (event.url.includes('refresh')) {
					return;
				}

				/**
				 * If it's not the first naviation
				 */
				if (this.app.current) {

					/**
					 *	Strip url of params
					 */
					const stripped = event.url.split('?')[0].split('#')[0].split('!')[0];
					const previous = this.app.current.split('?')[0].split('#')[0].split('!')[0];

					/**
					 *	If old page is the same as the new page exit
					 */
					if (stripped === previous) {
						return;
					}

					/**
					 * Save the previous one
					 */
					this.app.history.unshift(this.app.current);
				}

				/**
				 * Get the current one
				 */
				this.app.current = event.url;

				/**
				 * Save the previous
				 */
				this.web.previous = this.web.current;

				/**
				 * Get the current one
				 */
				this.web.current = event.url;

				/**
				 * Clean if more than 50 URL
				 */
				if (this.app.history.length > 50) {
					this.app.history.pop();
				}
			}
		});
	}

	/**
	 * Get last route in the navigation
	 */
	getLastRoute(): string {
		if (!this.environment.isApp()) {
			return this.web.previous || '/';
		} else {
			return this.app.previous || '/';
		}
	}

	/**
	 * Go Back with navigation
	 */
	back(): void {

		/**
		 * If it's from the browser
		 * use location
		 */
		if (!this.environment.isApp()) {
			this.location.back();
		} else {

			/**
			 * Going back
			 */
			this.app.goingBack = true;

			/**
			 * Get the previous (where we are coming from)
			 */
			this.app.previous = this.app.current;

			/**
			 * If now is 'bye-bye' we can't return
			 */
			if (this.app.previous === '/bye-bye') {
				console.log('Can not return back from logout page...');

				/**
				 * Reactivate routing history
				 */
				this.app.goingBack = false;
				return;
			}

			/**
			 * Get the previous URL
			 */
			const previous = (this.app.history.shift() || '/') as string;

			/**
			 * If now is 'home' and 'previous' is login/bye-bye exit
			 * OR
			 * If there are no previous routes and we are in the home
			 * OR
			 * If we are in the login
			 */
			if (
				(['/login', '/bye-bye'].indexOf(previous) !== -1 && this.app.previous === '/home') ||
				(this.app.history.length === 0 && ['/', '/home'].indexOf(this.app.previous || '') !== -1) ||
				this.app.previous === '/login'
			) {
				this.environment.closeApp();
			}

			/**
			 * If previous route is 'bye-bye' we can't return
			 */
			if (previous === '/bye-bye') {
				console.log('Can not return back to logout page. We must stay logged in...');

				/**
				 * Reactivate routing history
				 */
				this.app.goingBack = false;
				return;
			}

			/**
			 * Loader show
			 */
			this.loader.show();

			/**
			 * Navigate back
			 */
			this.router.navigateByUrl(previous).then(() => {

				/**
				 * Now er are in the previous
				 */
				this.app.current = previous;

				/**
				 * Loader hide
				 */
				this.loader.hide();
			});
		}
	}

	/**
	 * Get Actual route
	 */
	getCurrentRoute(): any {
		if (!this.environment.isApp()) {
			return this.web.current;
		} else {
			return this.app.current;
		}
	}

	/**
	 * Get the query param from the given url
	 */
	getQueryParam(name: string, url: string): string | null {
		if (!url) { url = window.location.href; };
		name = name.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
		const results = regex.exec(url);
		if (!results) { return null; };
		if (!results[2]) { return ''; };
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}
}
