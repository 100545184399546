import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs';

declare const cordova: any;
declare const document: any;
declare const navigator: any;
declare const Connection: any;
declare const device: any;

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService {

	/**
	 * If device is online
	 * [Default must be true]
	 */
	online = true;

	/**
	 *	Observe Status
	 */
	get onlineObserve(): Observable<boolean> {
		return this.status.asObservable();
	};

	/**
	 *	BehaviorSubject Object
	 */
	private status = new BehaviorSubject<boolean>(false);

	/**
	 *	Observable finish loading
	 */
	private finishLoadingObservable: Observable<boolean>;
	private finishLoadingObserver?: Observer<boolean>;
	private finishLoading = false;

	constructor(
		private ngZone: NgZone
	) {
		this.finishLoadingObservable = new Observable((obs: Observer<boolean>) => {
			this.finishLoadingObserver = obs;
		});
	}

	/**
	 * Start listeners
	 */
	startListeners(): void {

		/**
		 * If is an application
		 */
		if (this.isApp()) {

			/**
			 * Check connection initially
			 */
			if (navigator.connection.type === Connection.NONE) {
				this.online = false;
			}

			document.addEventListener('online', () => {

				/**
				 * Run in angular zone [IMPORTANT]
				 */
				this.ngZone.run(() => {
					this.online = true;
					this.status.next(true);
					console.log('Going online');
				});
			});

			document.addEventListener('offline', () => {

				/**
				 * Run in angular zone [IMPORTANT]
				 */
				this.ngZone.run(() => {
					this.online = false;
					this.status.next(false);
					console.log('Going offline');
				});

			});

		}

	}

	/**
	 * Check if is running on mobile application
	 */
	isApp(): boolean {
		return typeof cordova !== 'undefined';
	}

	/**
	 * Check if device is online
	 */
	isOnline(): boolean {

		/**
		 * Browser is always online
		 */
		if (!this.isApp()) {
			return true;
		}

		return this.online === true;
	}

	/**
	 * Check if device is offline
	 */
	isOffline(): boolean {

		/**
		 * Browser is always online
		 */
		if (!this.isApp()) {
			return false;
		}

		return this.online === false;
	}

	/**
	 * If browser is safari
	 */
	isSafari(): boolean {
		return /(iPhone|iPod|iPad|Macintosh).*AppleWebKit/i.test(navigator.userAgent);
	}

	/**
	 * If browser is iOS 13 or after
	 */
	isAfteriOS13(): any {
		return this.isApp() && this.isSafari() && device && parseInt(device.version, 10) >= 13;
	}

	/**
	 * If browser is Internet Explorer
	 */
	isIE(): boolean {
		const ua = window.navigator.userAgent;

		const msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			return true;
		}

		// var trident = ua.indexOf('Trident/');
		if (!!(window as any).MSInputMethodContext && !!document.documentMode) {
			return true;
		}

		// other browser
		return false;
	}

	/**
	 *	IS Edge?
	 */
	isEdge(): boolean {
		const ua = window.navigator.userAgent;
		const edge = ua.indexOf('Edge/');
		if (edge > 0) {
			return true;
		}
		return false;
	}

	/**
	 * Check if is Android
	 */
	isAndroid(): boolean {
		return typeof device !== 'undefined' && device.platform.toLowerCase() === 'android';
	}

	/**
	 * Close App
	 */
	closeApp(): void {
		console.log('Closing App...');
		if (
			this.isApp() &&
			typeof navigator !== 'undefined' &&
			typeof navigator.app !== 'undefined' &&
			typeof navigator.app.exitApp !== 'undefined'
		) {
			navigator.app.exitApp();
		}
	}

	/**
	 *	Notify that app has ended loading
	 */
	loadingFinished(): void {
		this.finishLoading = true;
		if (this.finishLoadingObserver) {
			this.finishLoadingObserver.complete();
		}
	}

	/**
	 *	If app loading is ended then call "callback"
	 */
	onFinishLoading(callback: () => void): Subscription {
		const subscriber = this.finishLoadingObservable.subscribe({
			complete: callback
		});

		/**
		 *	If it's already finished
		 */
		if (this.finishLoading) {
			this.finishLoadingObserver?.complete();
		}

		return subscriber;
	}
}
