<div class="bachecha-dettaglio" *ngIf="post">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ post.title }}
	</div>
	<div class="card post">
		<div class="card-body">
			<div class="head">
				<figure class="logo">
					<img [src]="post.gym?.logo_url" alt="">
				</figure>
				<div class="info">
					<h2 class="name">
						{{ post.gym?.name }}
					</h2>
					<div class="date">
						<span class="text">
							{{ post.date }}
						</span>
					</div>
				</div>
			</div>

			<figure class="image" *ngIf="post.image_url">
				<img [src]="post.image_url" alt="">
			</figure>

			<h4 class="title">
				{{ post.title }}
			</h4>

			<p class="description">
				{{ post.description }}
			</p>
		</div>
	</div>

	<div class="comments">
		<div class="row-title">
			Commenti ({{ comments?.length || 0 }})
		</div>
		<div class="card comment" *ngFor='let comment of comments'>
			<div class="card-body">
				<div class="head">
					<figure class="logo">
						<img [src]="comment.user?.avatar_url" alt="">
					</figure>
					<div class="info">
						<h2 class="name">
							{{ comment.user?.name }} {{ comment.user?.surname }}
						</h2>
						<div class="date">
							{{ comment.date }}
						</div>
					</div>
				</div>

				<p class="text">
					{{ comment.text }}
				</p>
			</div>
		</div>
	</div>

	<div class="card input">
		<div class="card-title">
			<h4>Lascia un commento</h4>
		</div>
		<div class="card-body">
			<textarea rows="5" class="form-control" [formControl]="comment"></textarea>
			<small class="hint" [ngClass]="{active: comment.hasError('maxlength')}">Massimo 1000 caratteri</small>
			<button class="button" type="button" (click)="submit()">
				Invia
			</button>
		</div>
	</div>
</div>