<div class="select">
	<input #inputRef type="text" [attr.disabled]="attributes?.disabled ? 'disabled' : null" [ngClass]="{'is-invalid': formControl.errors}">

	<!-- Workaround for placeholders -->
	<span [attr.disabled]="attributes?.disabled ? 'disabled' : null"
		*ngIf="attributes?.placeholder && !formControl.value" 
		class="placeholder"
	>
		{{ attributes?.placeholder }}
	</span>

	<select 
		[attr.disabled]="attributes?.disabled ? 'disabled' : null" 
		[formControl]="formControl"
	>
		<option selected [ngValue]="null">-</option>
		<option *ngFor="let option of (options || {})|objectToArray" [ngValue]="option.key">{{ option.value }}</option>
	</select>
</div>