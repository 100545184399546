<main class="records">
	<div class="row-title">
		<figure class="back" (click)="routeService.back()">
			<i class="bi bi-chevron-left"></i>
		</figure>
		{{ "RECORDS.TITLE"|translate }}
	</div>

	<section class="content">
		<mat-tab-group (selectedTabChange)="onTabChanged($event);">
			<mat-tab *ngFor="let category of categories">
				<ng-template mat-tab-label>{{category.name}}</ng-template>
				<ul class="list-group">
					<li class="list-group-item" *ngFor="let exercice of exercices; let i = index" [routerLink]="['/records', exercice.id]">
						<div class="item-main">
							<span class="name">{{ exercice.name }}</span>
						</div>
						<div class="item-right">
							<span class="value" *ngIf="exercice.record">{{ exercice.record?.value }}{{ exercice.measurement_unit?.abbreviation }}</span>
							<figure class="icon">
								<i class="bi bi-chevron-right"></i>
							</figure>
						</div>
					</li>
				</ul>
			</mat-tab>
		</mat-tab-group>
	</section>
</main>
